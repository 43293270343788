import React, { useEffect, useState, useRef, useCallback } from "react";
import { Button } from "@mui/material";
import { colourAccent as defaultColourAccent } from '../../css/styling.js'
import * as API2 from "../states.js";

export const DInput = ({ style, children, disabled, active, value, onChange = () => { }, type = 'text', }) => {
    const { colourAccent } = API2.globalStore()
    const [focus, setFocus] = useState(false)

    return <input
        // class="w3-input w3-border w3-hover-blue"
        type={type}
        // style={{ marginTop: '6px', padding: '6px' }}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        style={{
            opacity: disabled ? 0.5 : focus ? 1.0 : 0.8,
            color: active ? "rgba(31, 31, 31, 0.8)" : 'white',
            borderWidth: "revert",
            borderColor: active ? "rgba(31, 31, 31, 0.8)" : colourAccent || defaultColourAccent,
            borderStyle: "solid",
            background: active ? colourAccent || defaultColourAccent : "rgba(80,80,80,0.2)",
            width: "100%",
            height: "30px",
            WebkitTransition: '0.3s',
            transition: '0.3s',
            outline: 'none',
            // padding: 0,
            // margin: 5,
            // ...style,
            // marginTop: "5px",
            padding: "5px",
            margin: '5px',
            borderRadius: "3px",
            // background: backgroundStyle.backgroundColor,
            // color: "white",
            ...style,
        }}
        value={value}
        // defaultValue={'SAMPLE'}
        onChange={onChange}
    />
}
import { CompositeLayer, SimpleMeshLayer, TextLayer } from "deck.gl";
import { CubeGeometry, IcoSphereGeometry, SphereGeometry } from '@luma.gl/engine'

const icosphereMesh = new IcoSphereGeometry({});
const COLORS = { RTEM: [220, 20, 60], SCOOT: [255, 140, 0], AIRVIRO: [0, 189, 189], AIRQ: [30, 144, 255], DEFAULT: [242, 242, 242], NEXUS: [128, 0, 128], NEXUS2: [255, 87, 51], NEXUS3: [0, 153, 204], NEXUS4: [72, 61, 139], NEXUS5: [0, 255, 255], NEXUS6: [153, 50, 204], NEXUS7: [100, 149, 237], NEXUS8: [127, 0, 255], NEXUS9: [255, 85, 0], NEXUS10: [255, 255, 0] }
const ASSETOPACITY = 100

export class AssetsCompositeLayer extends CompositeLayer {
    static layerName = 'AssetCompositeLayer';

    static defaultProps = {
        data: [],
        darkMode: { type: 'boolean', value: false },
        labels: { type: 'boolean', value: true },
        sizeScale: { type: 'number', value: 100 },
        opacity: { type: 'number', value: 0.6 },
        focusNode: { type: 'object', value: null },
        collectionLabel: { type: 'boolean', value: true },
        colors: { type: 'object', value: { default: [COLORS.RTEM] } },
        darkColors: { type: 'object', value: { default: [COLORS.RTEM] } },
        elevation: { type: 'number', value: 0 }
    };

    initializeState() {
        this.state = {
            hoveredObject: null, // Track hovered object for interaction
            clickCount: 0        // Track number of clicks
        };
    }

    updateState({ props, oldProps, changeFlags }) {
        if (changeFlags.dataChanged) {
            // Update state based on new data if necessary
            this.setState({ clickCount: 0 });
        }
    }

    shouldUpdateState({ changeFlags }) {
        return changeFlags.somethingChanged || this.state.clickCount > 0;
    }

    onHover({ object }) {
        if (object) {
            this.setState({ hoveredObject: object });
        } else {
            this.setState({ hoveredObject: null });
        }
    }

    onClick({ object }) {
        this.props.onClick(object);
        this.setState(prevState => ({
            clickCount: prevState.clickCount + 1
        }));
    }

    renderLayers() {
        const { data, elevation,labels, darkMode, darkColors, colors, collectionLabel, sizeScale, opacity, focusNode, getSourcePosition, getTargetPosition, getSourceColor, getTargetColor, strokeWidth, onClick } = this.props;
        const { hoveredObject, clickCount } = this.state;

        return [
            new SimpleMeshLayer({
                id: `asset-mesh-layer`,
                data: data,
                mesh: icosphereMesh,
                getPosition: d => [d?.coordinates?.long, d?.coordinates?.lat, elevation],
                getColor: d => {
                    const color = (darkMode ? darkColors[d.collection.toUpperCase()] : colors[d.collection.toUpperCase()]) || COLORS.RTEM;
                    const highlight = (
                        hoveredObject
                        && hoveredObject?.asset_id?.toUpperCase() === d?.asset_id?.toUpperCase()
                        && hoveredObject?.collection?.toUpperCase() === d?.collection?.toUpperCase()
                    ) || (
                            focusNode
                            && focusNode?.node?.asset_id?.toUpperCase() === d?.asset_id?.toUpperCase()
                            && focusNode?.node?.collection?.toUpperCase() === d?.collection?.toUpperCase())
                        ;
                    return [...color, highlight ? 255 : ASSETOPACITY];
                },
                onHover: this.onHover.bind(this),
                onClick: this.onClick.bind(this),
                updateTriggers: {
                    getColor: [hoveredObject, focusNode]
                },
                sizeScale: sizeScale,
                pickable: true,
                autoHighlight: true,
                opacity: opacity,
            }),
            new SimpleMeshLayer({
                id: `asset-wireframe-layer`,
                data: data,
                mesh: icosphereMesh,
                getPosition: d => [d?.coordinates?.long, d?.coordinates?.lat, elevation],
                getColor: d => {
                    const color = (darkMode ? darkColors['WIREFRAME'] : colors['WIREFRAME']) || [30, 30, 30];
                    const highlight = (
                        hoveredObject
                        && hoveredObject?.asset_id?.toUpperCase() === d?.asset_id?.toUpperCase()
                        && hoveredObject?.collection?.toUpperCase() === d?.collection?.toUpperCase()
                    ) || (
                            focusNode
                            && focusNode?.node?.asset_id?.toUpperCase() === d?.asset_id?.toUpperCase()
                            && focusNode?.node?.collection?.toUpperCase() === d?.collection?.toUpperCase())
                        ;
                    return [...color, highlight ? 200 : ASSETOPACITY];
                },
                getOrientation: d => [0, 0, 90],
                updateTriggers: {
                    getColor: [hoveredObject, focusNode, darkMode]
                },
                wireframe: true,
                sizeScale: sizeScale,
                opacity: 0.1
            }),
            labels && new TextLayer({
                id: 'asset-text-layer',
                visible: labels,
                data: data,
                getPosition: d => [d?.coordinates?.long, d?.coordinates?.lat, elevation],
                getText: d => (collectionLabel ? d.collection + '\n' + d.asset_id : d.asset_id).toUpperCase(),
                updateTriggers: {
                    getText: [collectionLabel]
                },
                getPixelOffset: [0, 25],
                getColor: darkMode ? [230, 230, 230, 160] : [20, 20, 20, 240],
                getBackgroundColor: darkMode ? [230, 230, 230, 160] : [20, 20, 20, 240],
                getSize: 12,
                getAngle: 0,
                getTextAnchor: 'middle',
                getAlignmentBaseline: 'center',
                background: false,
                getBackgroundColor: [58, 60, 63, 100],
                outlineColor: darkMode ? [250, 250, 250, 220] : [0, 0, 0, 0],
                outlineWidth: darkMode ? 1 : 0,
                fontSettings: { sdf: true, radius: 12, cutoff: 0.18, smoothing: 0.4 },
                pickable: true
            }),
            // new SimpleMeshLayer({
            //     id: `asset-hovered-layer`,
            //     // visible: collections[collection].visible && page !== 'home' ? true : false,
            //     data: [hoveredObject, focusNode?.node],
            //     // texture: 'texture.png',
            //     mesh: icosphereMesh,
            //     // loaders: [OBJLoader],
            //     // material: NODE_MATERIAL,
            //     // // mesh: new CubeGeometry(),
            //     getPosition: d => [d?.coordinates?.long, d?.coordinates?.lat, elevation],
            //     // getColor: d => {
            //     //     console.log(d.collection.toUpperCase())
            //     //     return [...colors[d.collection.toUpperCase()],
            //     //     // focusNode?.name === d ? 150 : ASSETOPACITY
            //     //     hoveredObject?.asset_id === d.asset_id && hoveredObject.collection === d.collection ? 255 : ASSETOPACITY
            //     //     ] || COLORS.RTEM
            //     // },
            //     getColor: d => {
            //         return [255, 255, 255, 255];
            //     },
            //     // onHover: (object) => this.onHover.bind(this)(object),
            //     // onClick: (object) => { this.onClick.bind(this)(object) },
            //     onHover: this.onHover.bind(this), // Pass hover handler here
            //     onClick: this.onClick.bind(this), // Pass click handler here
            //     // getOrientation: d => [0, 0, 90], // middle heading value
            //     // wireframe: false,
            //     updateTriggers: {
            //         getColor: [hoveredObject, focusNode?.node],
            //         getPosition: [hoveredObject, focusNode?.node],
            //         getColor: [hoveredObject, focusNode?.node]
            //     },
            //     sizeScale: sizeScale * 1.1,
            //     pickable: false,
            //     // autoHighlight: true,
            //     opacity: 0.051,
            // }),
            // new SimpleMeshLayer({
            //     id: `asset-hovered-wireframe-layer`,
            //     data: [hoveredObject, focusNode?.node],
            //     mesh: icosphereMesh,
            //     getPosition: d => [d?.coordinates?.long, d?.coordinates?.lat, elevation + 10],
            //     getColor: d => {
            //         return [30, 30, 30, 255];
            //     },
            //     onHover: this.onHover.bind(this), // Pass hover handler here
            //     onClick: this.onClick.bind(this), // Pass click handler here
            //     wireframe: true,
            //     updateTriggers: {
            //         getColor: [hoveredObject, focusNode?.node],
            //         getPosition: [hoveredObject, focusNode?.node],
            //         getColor: [hoveredObject, focusNode?.node]
            //     },
            //     sizeScale: sizeScale * 0.5,
            //     pickable: true,
            //     autoHighlight: true,
            //     opacity: 0.1,
            // }),
        ];
    }
}
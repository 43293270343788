import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Button, Grid, IconButton, Input, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { headStyle, cardStyle, hrStyle, buttonStyle, headStyleInverted, backgroundStyle, lightColor, verticalCenter, colourAccent } from '../../css/styling';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Transvoider } from '../dElements/Animations';
import * as API2 from "../states";

const Item = styled(Paper)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    // ...theme.typography.body2,
    // padding: theme.spacing(1),
    // textAlign: 'center',
    // color: theme.palette.text.secondary,
    padding: '10px'
}));

const phases = [
    {
        "name": "Descriptive",
        "requirements": [
            "Data Collection Infrastructure",
            "Data Storage and Management",
            "Visualisation Tools"
        ],
        "objectives": [
            "Establish a solid foundation of data collection and management",
            "Gain a basic understanding of city operations through descriptive analytics",
            "Provide stakeholders with visual insights into current city conditions"
        ],
        "goals": [
            "Establish a data infrastrucutre for smart city datasets",
            "Visualisations for current state of the city",
            "Promote open data sharing"
        ]
    },
    {
        "name": "Informative",
        "requirements": [
            "Advanced Data Analytics",
            "Historical Data Analysis",
            "Stakeholder Engagement"
        ],
        "objectives": [
            "Transform raw data into informative insights for decision-making",
            "Identify and understand key trends and patterns in city operations",
            "Enhance stakeholder awareness and involvement in city management"
        ],
        "goals": [
            "Advanced visualisations for analysis and insights",
            "Advanced data processing for finding trend similarities and clustering"
        ]
    },
    {
        "name": "Predictive",
        "requirements": [
            "Predictive Analytics Tools",
            "Scenario Planning",
            "Data Integration"
        ],
        "objectives": [
            "Proactively address potential issues before they become critical",
            "Optimise resource utilisation and city planning based on predictive insights",
            "Improve city resilience to future challenges"
        ],
        "goals": [
            "AI Canvas to accelerate ML developments with city datasets",
            "Establish mainstream models as foundation for community-led engagements",
            "Scenario planning using models in digital twin contexts",
            "Promote model improvements through community contributions"
        ]
    },
    {
        "name": "Comprehensive",
        "requirements": [
            "Integrated City Management Systems",
            "Holistic Decision-Making",
            "Citizen-Centric Services"
        ],
        "objectives": [
            "Achieve a holistic view of city operations and services",
            "Enhance overall city efficiency and effectiveness through integrated management",
            "Foster a more responsive and citizen-centric approach to city governance"
        ],
        "goals": [
            "Data-fusion reports generation for all datasets",
            "Establish Merlin, an AI agent for smart city"
        ]
    },
    {
        "name": "Connected City",
        "requirements": [
            "Interconnected Systems",
            "Smart Infrastructure",
            "Collaborative Platforms"
        ],
        "objectives": [
            "Achieve a fully connected smart city ecosystem",
            "Enhance real-time responsiveness and adaptability of city services",
            "Promote innovation and collaboration to drive continuous improvement and sustainability"
        ],
        "goals": [
            "Platform forums for sharing data and models",
            "SDKs and APIs for direct IoT integrations",
            "Synchronised digital twins sytems",

        ]
    }
]

const hleftpad = '50px'


function Roadmap() {
    const { colourAccent } = API2.globalStore();
    
    return <div style={{ fontSize: '14px', textAlign: 'justify', maxWidth: '1200px' }}>

        <Typography
            variant='h5'
            style={{
                // fontSize: '14px',
                // color: colourAccent, 
                textAlign: 'center',
                paddingBottom: '30px', paddingTop: '20px',
                // background: 'linear-gradient(to right, rgba(255,255,255,0), rgba(0,0,0,0.2) , rgba(255,255,255,0))'
            }}>SMART CITY MATURITY LEVELS</Typography>

        {/* <div style={{maxWidth: '600px'}}>This roadmap charts our inspiring journey toward achieving Smart City status for Birmingham, where innovation, collaboration, and community spirit propel us towards a brighter, more connected future.
        </div>
        <br />
        <br /> */}

        <Transvoider open={true} behaviour={{ y: 0, from: { opacity: 0, x: 100, scale: 1.0 }, to: { opacity: 1, x: 0, scale: 1.0 } }}>

            {phases.map((p, i) => <div style={{
                fontSize: '14px !important',
                // paddingLeft: i * 20
            }}>

                <hr style={{ opacity: 0.1 }} />
                {/* <br /> */}
                <h2 style={{
                    fontSize: '14px', color: colourAccent,
                    textAlign: 'center',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    marginTop: 0,
                    background: 'linear-gradient(to right, rgba(255,255,255,0), rgba(0,0,0,0.2) , rgba(255,255,255,0))'

                }}>M{i + 1}. {p.name}</h2>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {p.requirements.length > 0 && <Grid item xs={3} style={{ paddingLeft: hleftpad }}>

                        <h3 style={{ fontSize: '14px' }}>Requirements:</h3>
                        <ul>
                            {p.requirements.map(r => <li>{r}</li>)}
                        </ul>

                    </Grid>}
                    {p.objectives.length > 0 && <Grid item xs={5} style={{ paddingLeft: hleftpad }}>

                        <h3 style={{ fontSize: '14px' }} >Objectives:</h3>
                        <ul>
                            {p.objectives.map(r => <li>{r}</li>)}
                        </ul>

                    </Grid>}

                    {/* </Grid> */}
                    {/* <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{ opacity: 0.4 }}> */}

                    {p.goals.length > 0 && <Grid item xs={4} style={{ paddingLeft: hleftpad, opacity: '0.3' }}>

                        <h3 style={{ fontSize: '14px' }} >Goals:</h3>
                        <ul>
                            {p.goals.map(r => <li>{r}</li>)}
                        </ul>
                    </Grid>}
                </Grid>

                <div style={{ height: '60px' }} />
            </div>)}

        </Transvoider>
    </div>
}

export default Roadmap;
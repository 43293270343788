import React, { useEffect, useState, useRef, useCallback } from 'react';
import { ToggleButtonGroup, ToggleButton, Button, IconButton } from '@mui/material';
import { headStyle, hrStyle, buttonStyle, backgroundStyle, lightColor } from '../../css/styling';
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import { config, useSpring, a, useSprings, Trail } from '@react-spring/web';

import GoogleIcon from '@mui/icons-material/Google';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import GitHubIcon from '@mui/icons-material/GitHub';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



import * as API2 from '../states';

import db from '../../images/db1.jpg';
import ml from '../../images/ml1.jpg';
import anl from '../../images/analytics1.jpg';
import { useElementOnScreen, Trailer, spanCharacters, spanArray, spanArrayStringEqualSplit, Transvoider } from '../dElements/Animations';

const minResolution = 1200;

const superSpace = "\u00A0"

const section = (title, content, left, img, appLaunchable, ref) => {

    const contents = <div style={{ width: '80vw', margin: '5%', maxWidth: '700px' }}>
        <div style={{ fontSize: 'max(1.5vw, 18px)' }}>{title}</div>
        <br />
        <p style={{ fontSize: 'max(0.75vw, 16px)', textAlign: 'left' }}>{content}</p>
    </div>

    const mask = <img style={{
        objectFit: 'cover',
        width: '25vw',
        borderRadius: '25px',
        alignSelf: 'center',
        filter: 'contrast(0.9) grayscale(0.3)',
        border: 'solid',
        borderWidth: '1px',
        borderColor: '#1e1e1e'
    }}
        src={img} alt="Logo" />

    return <div
        style={{
            display: appLaunchable <= -1 ? 'ruby-text' : 'flex',
        }}>
        {appLaunchable <= -1 ? <>{mask}{contents}</> : <>{left ? contents : mask}{!left ? contents : mask}</>}
    </div>

}

const parallaxBoxStyle = {
    width: '40vw',
    height: '40vw',
    // border: 'solid',
    borderRadius: '15px',
    // borderWidth: 200,
    opacity: 0.025,
    background: 'rgb(255,255,255)',
    // background: 'transparent',
    // borderColor: 'rgb(255,255,255)',


    filter: 'blur(1px)',
    zIndex: -1
}

const titleCharStyle = {
    color: headStyle.color,
    float: 'left',
    fontSize: '8vw',
    textShadow: '0 0 2px #1e1e1e'
}

const subTitleCharStyle = {
    ...headStyle,
    paddingBottom: '5vh',
    fontSize: '3vh',
    textShadow: '0 0 2px #1e1e1e',
    textAlign: 'center'
}

const parallaxLayer = (configs) => <ParallaxLayer offset={0.6 + configs.offset / 2} speed={configs.speed * 1.5} >
    <div style={{ ...parallaxBoxStyle, marginLeft: `${configs.marginLeft}vw` }} />
</ParallaxLayer>

function Home({ setPage: appSetPage }) {
    const parallax = useRef(null)
    const myElementRef = useRef(null);
    const windowDimensions = API2.globalStore().windowDimensions;
    const setPage = API2.globalStore().setPage;

    const appLaunchable = API2.globalStore().appLaunchable;
    const setAppLaunchable = API2.globalStore().setAppLaunchable;
    const init = API2.globalStore().init;


    const [entered, setEntered] = useState(false)
    const [enteredTitle, setEnteredTitle] = useState(false)
    const [enteredSubTitle, setEnteredSubTitle] = useState(false)
    const [enteredContent, setEnteredContent] = useState(false)
    const [enteredLogin, setEnteredLogin] = useState(false)

    const { urlParams, setUrlParams } = API2.globalStore();

    // const [parallaxRandoms, setParralaxRandoms] = useState(Array.from())
    // const paralaxes = 7
    // const [paralaxRandoms, setParalaxRandoms] = useState(Array.from(Array(paralaxes), (x, i) => [i < 2 ? -1.5 + Math.random() * 3 : -1.5 + i / paralaxes * 4 + Math.random() * 0.2, Math.random(), Math.random()]))

    const [paralaxRandoms, setParalaxRandoms] = useState([
        // [
        //     1.40157858679761,
        //     0.9952921862062634,
        //     0.3941454259580095
        // ],
        [
            -0.8579408638250848,
            0.03334170044071083,
            0.9873317184345115
        ],
        [
            -1.108546469578539,
            0.0453775393352656,
            0.04187250737370924
        ],
        [
            0.0345773948064906,
            0.20999410919106457,
            0.4279251983814947
        ],
        [
            0.8670066611906847,
            0.4773379310504241,
            0.04136192115997317
        ],
        [
            1.2171048397362185,
            0.50601471572263,
            0.6758958380242355
        ],
        [
            2.4414592620682818,
            0.36382027499420637,
            0.21766616588589738
        ]
    ])

    useEffect(() => {
        // alert(JSON.stringify(paralaxRandoms, null, 2))
        // console.log(paralaxRandoms)

        setTimeout(() => {
            setEntered(true)
        }, 200)

        setTimeout(() => {
            setEnteredTitle(true)
        }, 600)

        setTimeout(() => {
            setEnteredSubTitle(true)
        }, 900)

        setTimeout(() => {
            setEnteredContent(true)
        }, 1400)
    }, [])

    useEffect(() => {
        if (windowDimensions.width > minResolution) {
            setAppLaunchable(0)
            // appSetPage('platform')
        }
        else
            setAppLaunchable(-1)
    }, [windowDimensions])

    const [accessSprings, accessSpringsAPI] = useSpring(() => ({
        transform: enteredLogin ? 'translate(-15vw, -25vh)' : 'translate(0vw, -25vh)'
        // placeContent:enteredLogin? 'start': 'center'
    }), [enteredLogin])

    const [loginSprings, loginSpringsAPI] = useSpring(() => ({
        opacity: enteredLogin ? 1 : 0
    }), [enteredLogin])


    const [sectionArchiveRef, sectionArchiveVisible] = useElementOnScreen({ root: null, permanent: true })
    const [sectionVisualiseRef, sectionVisualiseVisible] = useElementOnScreen({ root: null, permanent: true })
    const [sectionMlearneRef, sectionMlearnVisible] = useElementOnScreen({ root: null, permanent: true })
    const [footerRef, footerVisible] = useElementOnScreen({ root: null, permanent: true })

    const accessTrigger = (callback) => {
        if (windowDimensions.width > minResolution) {
            setAppLaunchable(1)
            callback && callback()
        }
        else
            setAppLaunchable(appLaunchable - 1)
    }

    const loginButtonStyle = {
        ...buttonStyle,
        // color: lightColor.backgroundColor,
        // textAlign: 'center', 
        // marginTop: '100px',
        height: '50px',
        zIndex: 1000,
        position: 'absolute',

        color: backgroundStyle.backgroundColor
    }

    return <div ref={myElementRef} style={{
        height: '100vh', width: '100vw', marginLeft: '0vw',
        backdropFilter: 'blur(0px)',
        // background: 'rgba(0, 0, 0, 0.2)'
        background: 'rgb(67 55 28 / 20%)'
        // scrollbarColor: 'red orange',
        // scrollbarWidth: '2.5vw'
    }}>

        {/* <div style={{ zIndex: 0, height: '100%', left: '0vw', right: '0vw', position: 'fixed', backdropFilter: 'blur(1px)', boxShadow: '2px 2px 10px black' }} /> */}

        <Parallax ref={parallax}  >

            <ParallaxLayer offset={1} speed={0.5}  >
                <div style={{
                    textShadow: '0 0 4px #1e1e1e',
                    width: 'max-content',
                    paddingRight: 'calc(106vw - 14vh - 15vw)',
                    fontSize: '14vh',
                    transform: 'rotate(90deg)',
                    textAlign: 'right',
                    marginTop: '125vh'
                }} >SMART &nbsp; CITY &nbsp; BIRMINGHAM. </div>
            </ParallaxLayer>

            {paralaxRandoms && paralaxRandoms.map((xs, i) => parallaxLayer({ offset: xs[0], speed: xs[1], marginLeft: 3 + xs[2] * 50 }))}


            <div >
                <div style={{ paddingTop: '38vh', paddingBottom: '14vh' }}>
                    {/* <div style={{ paddingTop: '4vh', textShadow: '0 0 2px #1e1e1e' }}> */}
                    <a.div>
                        <IconButton className='buttonexotic' style={{
                            // ...buttonStyle, 
                            borderRadius: '5px',
                            color: lightColor.backgroundColor,
                            textAlign: 'center', zIndex: 1000,
                            position: 'absolute', top: '4vh', right: '3vw'
                        }}
                            onClick={() => { accessTrigger(() => setEnteredLogin(!enteredLogin)) }}> PILOT ACCESS </IconButton>
                    </a.div>
                    {/* </div> */}

                    <div style={{ position: 'absolute', right: '30vw', }}>
                        <Transvoider open={enteredLogin} behaviour={{ height: 70 }}>

                            <IconButton style={{ ...loginButtonStyle }} onClick={() => alert('Google account access coming soon')}><GoogleIcon /></IconButton>
                            <IconButton style={{ ...loginButtonStyle }} onClick={() => alert('Microsoft account access coming soon')}><MicrosoftIcon /> </IconButton>
                            <IconButton style={{ ...loginButtonStyle }} onClick={() => alert('Github account access coming soon')}><GitHubIcon /></IconButton>

                        </Transvoider>
                    </div>

                    <Trailer open={entered}>
                        <a.div style={{ transform: accessSprings.transform }}>
                            <span style={{

                                display: 'inline-flex',
                                // transform: transform,
                                // left: '50vw',
                                placeContent: 'center',
                                width: '100vw',
                                position: 'relative',
                                top: '10vh',
                                // ...loginSprings

                            }}>
                                <Trailer open={enteredTitle} behaviour={{ x: 0, y: enteredTitle ? 0 : (Math.random() - 0.5) * 100 }}>
                                    {spanCharacters("DIATOMIC", { ...titleCharStyle, fontSize: appLaunchable <= -1 ? 'max(16vw, 32px)' : '8vw' })}
                                </Trailer>
                            </span >

                            <span style={{
                                display: 'inline-flex',
                                // transform: transform,
                                // left: '50vw',
                                placeContent: 'center',
                                width: '100vw',
                                position: 'relative',
                                // top: '35vh',
                                // ...loginSprings
                                top: appLaunchable <= -1 ? '50px' : '6.5vh',
                                // justifyContent: 'center',
                                // ...loginSprings
                            }}>
                                <Trailer open={enteredSubTitle} behaviour={{ x: enteredSubTitle ? 0 : 20 }}>
                                    {appLaunchable <= -1 && spanArray(spanArrayStringEqualSplit("DIGITAL CITY PLATFORM", 4), subTitleCharStyle)}
                                    {/* {appLaunchable <= -1 && spanArray(spanArrayStringEqualSplit("BIRMINGHAM", 4), subTitleCharStyle)} */}
                                    {appLaunchable > -1 && spanArray(spanArrayStringEqualSplit("DIGITAL CITY PLATFORM, BIRMINGHAM", 4), subTitleCharStyle)}
                                </Trailer>

                            </span>

                            <span style={{
                                display: 'inline-flex',
                                // transform: transform,
                                // left: '50vw',
                                placeContent: 'center',
                                width: '100vw',
                                position: 'relative',
                                // top: '35vh',
                                top: appLaunchable <= -1 ? '40px' : '6.5vh',
                                // justifyContent: 'center',
                                ...accessSprings

                            }}>
                                <Trailer open={enteredSubTitle} behaviour={{ x: enteredSubTitle ? 0 : 20 }}>
                                    {appLaunchable <= -1 && spanArray(spanArrayStringEqualSplit("BIRMINGHAM", 4), subTitleCharStyle)}
                                </Trailer>
                            </span>

                            {/* <div style={{ textAlign: 'center' }} > */}
                            <div style={{ paddingTop: '4vh', textShadow: '0 0 2px #1e1e1e' }}>
                                <Trailer open={enteredSubTitle} behaviour={{ x: 0, opacity: enteredContent ? 1 : 0 }}>
                                    <div style={{ fontSize: '2vh', textAlign: 'center', width: '100vw', marginTop: '12vh' }}>EXPLORE OUR</div>

                                    <Button className='buttonexotic' style={{ ...buttonStyle, fontSize: '14px', textAlign: 'center', transform: 'translateX(calc(50vw - 110px))' }} onClick={() => accessTrigger(() => appSetPage('platform'))}>Digital City Platform </Button>

                                    <div style={{
                                        display: appLaunchable < -1 ? '' : 'none',
                                        margin: '20px', paddingTop: '10px', paddingBottom: '10px',
                                        color: 'rgba(255,255,255,0.8)', borderRadius: '3px', background: 'firebrick', fontWeight: 'bold', fontSize: '16px', textAlign: 'center', maxWidth: '500px', margin: 'auto', paddingTop: '10px', marginTop: '3vh', textShadow: '0 0 2px #1e1e1e', border: 'solid',
                                        borderWidth: '1px',
                                        borderColor: '#1e1e1e'
                                    }}>your screen size too mini to launch this app</div>
                                </Trailer>
                            </div>
                        </a.div>
                    </Trailer>
                </div>
            </div >

            <div style={{
                // left: '50%', transform: 'translateX(50%)'
                marginTop: '48vh',
                marginLeft: appLaunchable <= -1 ? 0 : 'calc(12vw + 1vh)'
                // width:'300px'
            }}>
                <div style={{
                    display: 'ruby',
                }}>
                    <div style={{
                        zIndex: -1,
                        position: 'fixed',
                        top: '94vh',
                        left: '-50vw',
                        width: '200vw',
                        height: '600vh',
                        WebkitTransform: 'rotate(-1deg)',
                        msTransform: 'rotate(-1deg)',
                        'transform': 'rotate(-1deg)',
                        background: backgroundStyle.backgroundColor,
                        border: 'solid',
                        borderWidth: '1px',
                        borderColor: '#1e1e1e'
                    }} />
                    <div style={{
                        zIndex: 0,
                        position: 'fixed',
                        bottom: '-3vh',
                        left: '-50vw',
                        width: '200vw',
                        height: appLaunchable <= -1 ? '40vh' : '23vh',
                        WebkitTransform: 'rotate(-1deg)',
                        msTransform: 'rotate(-1deg)',
                        'transform': 'rotate(-1deg)',
                        background: 'rgba(221, 221, 221, 0.15)',
                        border: 'solid',
                        borderWidth: '1px',
                        borderColor: '#1e1e1e'
                    }} />
                    {/* <div style={{ paddingTop: '60px' }} /> */}
                    <Trailer containerRef={sectionArchiveRef} open={sectionArchiveVisible} behaviour={{ delay: 0 }} behaviourConfig={{ duration: 250 }}>{section("EXPLORE OUR DATA PLATFORM", "Our platform provides access to Birmingham smart city data archives! We offer access to datasets on transportation, energy usage, public health, and more for modern cities. Explore, analyse, and gain insights from these datasets on our data platform. Our platform provides the tools and resources you need to make smart city decisions and innovate, whether you're optimising infrastructure, improving sustainability, or exploring urban life.", false, db, appLaunchable)}</Trailer>
                    {/* <div style={{ paddingTop: '60px' }} /> */}
                    <Trailer containerRef={sectionVisualiseRef} open={sectionVisualiseVisible} behaviour={{ delay: 0, x: sectionVisualiseVisible ? 0 : -20, }} behaviourConfig={{ duration: 250 }}>{section("SEE PUBLISHED ANALYTICS AND VISUALISATIONS", "Our platform visualises complex Birmingham's smart city data. Our revolutionary web platform simplifies complex dataset exploration and interpretation, revealing urban dynamics, infrastructure, and sustainability. Our straightforward interface lets city planners, researchers, and curious citizens traverse massive volumes of data to make informed decisions and collaborate to construct smarter, more resilient communities. ", true, anl, appLaunchable)}</Trailer>
                    {/* <div style={{ paddingTop: '60px' }} /> */}
                    <div style={!appLaunchable ? { width: '-webkit-fill-available', height: '15vw' }:{}} />
                    <Trailer containerRef={sectionMlearneRef} open={sectionMlearnVisible} behaviour={{ delay: 0 }} behaviourConfig={{ duration: 250 }}>{section("BUILD YOUR OWN MACHINE LEARNING MODELS", "With real-world smart city datasets, our revolutionary web platform lets you unleash your creativity and train machine learning models easily. Develop and use machine learning to create innovative urban solutions and explore unlimited possibilities. Use straightforward tools and robust resources to realise your ideas and build future cities.", false, ml, appLaunchable)}</Trailer>
                    {/* <div style={{ paddingTop: '30px' }} /> */}
                </div>
            </div>

            <div
                ref={footerRef}
                style={{
                    paddingTop: '140px',
                    display: appLaunchable <= -1 ? 'grid' : 'inline-flex',
                    width: '100%',
                    justifyContent: 'center',
                    // fontWeight: 'bold',
                    zIndex: 5,
                    position: 'relative',
                    justifyItems: appLaunchable <= -1 ? 'center' : '',
                    // marginLeft: appLaunchable<=-1?'2vw':'0',
                    fontSize: appLaunchable <= -1 ? 'max(0.75vw, 16px)' : '18px'
                }}>
                <Transvoider open={footerVisible} behaviour={{ x: 0, y: footerVisible ? 0 : -20, height: footerVisible ? 15 : 0 }}>
                    <div style={{ fontSize: appLaunchable <= -1 ? 'max(0.75vw, 16px)' : '16px' }}>Birmingham City University </div>
                    <span style={{ width: '10px' }} >{superSpace}{superSpace}{superSpace}{superSpace}{superSpace}</span>
                    <div style={{ fontSize: appLaunchable <= -1 ? 'max(0.75vw, 16px)' : '16px' }}>University of Birmingham </div>
                    <span style={{ width: '10px' }} >{superSpace}{superSpace}{superSpace}{superSpace}{superSpace}</span>
                    <div style={{ fontSize: appLaunchable <= -1 ? 'max(0.75vw, 16px)' : '16px' }}>Aston University </div>
                    <span style={{ width: '10px' }} >{superSpace}{superSpace}{superSpace}{superSpace}{superSpace}</span>
                    <div style={{ fontSize: appLaunchable <= -1 ? 'max(0.75vw, 16px)' : '16px' }}>Connected Places Catapult</div>
                    <span style={{ width: '10px' }} >{superSpace}{superSpace}{superSpace}{superSpace}{superSpace}</span>
                    <div style={{ fontSize: appLaunchable <= -1 ? 'max(0.75vw, 16px)' : '16px' }}>Birmingham City Council</div>
                </Transvoider>
            </div>

            <div style={{ height: '4vh' }} />
            <div style={{
                textAlign: 'center', fontSize: '14px', zIndex: 5,
                position: 'relative',
            }}>  DIATOMIC </div>
            <div style={{ height: '6vh' }} />

        </Parallax >

    </div >
}

export default Home;
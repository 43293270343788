import { Box, Button } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import * as tf from '@tensorflow/tfjs';
import * as API2 from "../states.js";
import { Height } from "@mui/icons-material";



const _boxstyle = {
    background: 'transparent',
    color: "white",

    // border: `1px solid rgba(255,255,255,0.2)`,
    // opacity: 0.9,
    display: "inline-block",
    textAlign: 'center'
};

const _btnStyle = {
    // borderRadius: '2px',
    width: '100%',
    height: '100%',
    borderWidth: '0px',
    // fontSize: 'max(0.5vh, 12px)'
}

export const FileUploader = ({ handleFile, btnStyle, boxStyle, handleFileChange, label, accept, ok, multiple }) => {
    const hiddenFileInput = useRef(null);
    const { loadModel } = API2.aiworkflow()

    const handleClick = () => {
        hiddenFileInput.current.click();
    };


    const _handleFileChange = async (event) => {
        const files = Array.from(event.target.files);

        if (!files.length) return;

        for (var i = 0; i < files.length; i++) {
            try {
                const file = event.target.files[i];
                if (file) {
               
                    loadModel(file);
                    console.log(".canvmdl Model loaded successfully");
                }

            } catch (error) {
                alert('error loadning model')
                console.error("Error loading TensorFlow model:", error);
            }
        }
    }

    return (<Box style={{ ..._boxstyle, ...boxStyle }}>
        <Button
            style={{ ..._btnStyle, background: ok ? 'green' : 'transparent', ...btnStyle }}
            onClick={handleClick}
        >
            {label}
        </Button>
        <input
            type="file"
            multiple={multiple}
            accept={accept}
            onChange={handleFileChange ? handleFileChange : _handleFileChange}
            ref={hiddenFileInput}
            style={{ display: 'none' }}
        />
    </Box>
    );
};

// export function UploadModel({ close }) {

//     const [canvOld, setCanvOld] = useState(null);
//     const [tfModelOld, setTfModelOld] = useState(null);

//     const [canv, setCanv] = useState(null);
//     const [tfModel, setTfModel] = useState(null);

//     const {
//         updateModel,
//         loadCanvas,
//     } = API2.aiworkflow();

//     useEffect(() => {
//         if (canv && tfModel) {
//             const model = { ...canv, modelBinary: tfModel };
//             // console.log(model)
//             updateModel(model);
//             console.log("Model and Canvas loaded successfully", model);
//             close && close()
//         }

//         if (canvOld && tfModelOld) {
//             const model = { ...canvOld, modelBinary: tfModelOld };
//             // console.log(model)
//             updateModel(model);
//             console.log("Model and Canvas loaded successfully", model);
//             close && close()
//         }
//     }, [canv, tfModel, canvOld, tfModelOld])

//     return (
//         <Box style={{ width: '100%' }}>

//             <span style={{ width: '25%', display: 'inline-flex', placeContent: 'center' }}> Model Imports </span>

//             <FileUploader btnStyle={{
//                 textAlign: "center",
//                 // color: colourAccent,
//                 borderRadius: "3px",
//                 cursor: "pointer",
//                 color: "white",
//                 display: "inline-block",
//             }}
//                 boxStyle={{
//                     textAlign: "center",
//                     // color: colourAccent,
//                     borderRadius: "2px",
//                     cursor: "pointer",
//                     color: "white",
//                     border: `1px solid rgba(255,255,255,0.2)`,
//                     width: "35%",
//                     textAlign: "center",
//                     background: 'transparent',
//                     color: "white",
//                     opacity: 0.9,
//                     margin: '3px',
//                     display: "inline-block",
//                     textAlign: 'center',
//                     // fontSize: 'max(0.5vh, 12px)'
//                 }}
//                 mode="canvmdl" accept=".canvmdl" label=".canvmdl File" ok={canv !== null} />


//             <FileUploader btnStyle={{
//                 textAlign: "center",
//                 // color: colourAccent,
//                 borderRadius: "3px",
//                 cursor: "pointer",
//                 color: "white",
//                 display: "inline-block",
//             }}
//                 boxStyle={{
//                     textAlign: "center",
//                     // color: colourAccent,
//                     borderRadius: "2px",
//                     cursor: "pointer",
//                     color: "white",
//                     border: `1px solid rgba(255,255,255,0.2)`,
//                     width: "35%",
//                     textAlign: "center",
//                     background: 'transparent',
//                     color: "white",
//                     opacity: 0.9,
//                     margin: '3px',
//                     display: "inline-block",
//                     textAlign: 'center',
//                     // fontSize: 'max(0.5vh, 12px)'
//                 }}
//                 mode="file" accept=".canv.mdl.json" handleFile={setCanv} label="Canvas MODEL File" ok={canv !== null} />

//             <FileUploader btnStyle={{
//                 textAlign: "center",
//                 // color: colourAccent,
//                 borderRadius: "3px",
//                 cursor: "pointer",
//                 color: "white",
//                 display: "inline-block",
//             }}
//                 boxStyle={{
//                     textAlign: "center",
//                     // color: colourAccent,
//                     borderRadius: "2px",
//                     cursor: "pointer",
//                     color: "white",
//                     border: `1px solid rgba(255,255,255,0.2)`,
//                     width: "35%",
//                     textAlign: "center",
//                     background: 'transparent',
//                     color: "white",
//                     opacity: 0.9,
//                     margin: '3px',
//                     display: "inline-block",
//                     textAlign: 'center',
//                     // fontSize: 'max(0.5vh, 12px)'
//                 }}
//                 mode="tf" accept=".canv.mdl.tf.json,.canv.mdl.tf.weights.bin" handleFile={setTfModel} label="TensorFlow Files" ok={tfModel !== null} />


//             {/* <br />
//             <span style={{ width: '25%', display: 'inline-flex', placeContent: 'center' }}> Old Versions</span>
//             <FileUploader btnStyle={{
//                 textAlign: "center",
//                 // color: colourAccent,
//                 borderRadius: "3px",
//                 cursor: "pointer",
//                 color: "white",
//                 display: "inline-block",
//             }}
//                 boxStyle={{
//                     textAlign: "center",
//                     // color: colourAccent,
//                     borderRadius: "2px",
//                     cursor: "pointer",
//                     color: "white",
//                     border: `1px solid rgba(255,255,255,0.2)`,
//                     width: "35%",
//                     textAlign: "center",
//                     background: 'transparent',
//                     color: "white",
//                     opacity: 0.9,
//                     margin: '3px',
//                     display: "inline-block",
//                     textAlign: 'center',
//                     // fontSize: 'max(0.5vh, 12px)'
//                 }}
//                 mode="file" accept=".canv" handleFile={setCanvOld} label="Canvas MODEL File" ok={canvOld !== null} />

//             <FileUploader btnStyle={{
//                 textAlign: "center",
//                 // color: colourAccent,
//                 borderRadius: "3px",
//                 cursor: "pointer",
//                 color: "white",
//                 display: "inline-block",
//             }}
//                 boxStyle={{
//                     textAlign: "center",
//                     // color: colourAccent,
//                     borderRadius: "2px",
//                     cursor: "pointer",
//                     color: "white",
//                     border: `1px solid rgba(255,255,255,0.2)`,
//                     width: "35%",
//                     textAlign: "center",
//                     background: 'transparent',
//                     color: "white",
//                     opacity: 0.9,
//                     margin: '3px',
//                     display: "inline-block",
//                     textAlign: 'center',
//                     // fontSize: 'max(0.5vh, 12px)'
//                 }}
//                 mode="tf" accept=".json,.bin" handleFile={setTfModelOld} label="TensorFlow Files" ok={tfModelOld !== null} /> */}

//             <br />
//         </Box>
//     );
// }

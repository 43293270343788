import React, { Children, useEffect, useMemo, useRef, useState } from 'react';
import * as echarts from 'echarts';
import 'echarts-gl'; // Import echarts-gl extension
import { filter, symbol } from 'd3';
import * as API2 from "../states";
import { DButton } from './DButton';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { DTooltip } from './DTooltip';
import zIndex from '@mui/material/styles/zIndex';
import { InfoOutlined } from '@mui/icons-material';
import { DIconButton } from './DIconButton';


function sampleData() {
    const data = [];
    for (let i = 0; i < 1000; i++) {
        data.push([i, Math.sin(i / 100)]);
    }
    return data
}

const seriesSample = {
    name: 'Line',
    type: 'line', // Use 'line' for 2D line chart
    data: sampleData(), // Pass in the large dataset
    lineStyle: {
        width: 2,
    },
    large: true, // Enable WebGL large data rendering optimization
    largeThreshold: 300, // Threshold for large data mode (when to switch to WebGL rendering)
}

const DCharts = ({ style, title = '', id = null, children, datetimeArray, dataArray, update, updateClear, colors, stats, labels, blur = '0px', gridcolor = 'rgba(255,255,255,0.10)' }) => {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);
    const [filters, setFilters] = useState([true, true]) // Zeroes, nulls
    const [cap, setCap] = useState('p100')
    const { colourAccent, colourAccentSecondary } = API2.globalStore()

    // const { filteredDatetimeArray, filteredDataArray } = useMemo(() => {

    function updateGraph() {

        let filteredDatetimeArray = [...datetimeArray]
        let filteredDataArray = [...dataArray]

        console.log(filteredDataArray)

        if (filters[0]) {
            filteredDataArray[0] = filteredDataArray[0].map((v, i) => v === 0 ? undefined : v)
            if (filteredDataArray.length > 1)
                filteredDataArray[1] = filteredDataArray[1].map((v, i) => v === 0 ? undefined : v)
        }
        if (filters[1]) {
            filteredDataArray[0] = filteredDataArray[0].map((v, i) => v === null ? undefined : v)
            if (filteredDataArray.length > 1)
                filteredDataArray[1] = filteredDataArray?.[1].map((v, i) => v === null ? undefined : v)
        }

        if (cap !== 'p100') {
            let _cap = -1

            switch (cap) {
                case 'p95': _cap = stats['perc_95']; break;
                case 'p80': _cap = stats['perc_80']; break;
            }
            console.log(stats, cap, _cap)
            filteredDataArray[0] = (cap === -1 ? filteredDataArray[0] : filteredDataArray[0].map((v, i) =>
                v == undefined ? undefined : Math.min(_cap, v)
            ))
            if (filteredDataArray.length > 1)
                filteredDataArray[1] = (cap === -1 ? filteredDataArray?.[1] : filteredDataArray?.[1].map((v, i) =>
                    v == undefined ? undefined : Math.min(_cap, v)
                ))
        }

        // console.log(filteredDataArray)

        filteredDatetimeArray = filteredDatetimeArray.filter((t, i) => filteredDataArray[0][i] !== undefined)
        filteredDataArray[0] = filteredDataArray[0].filter((t, i) => filteredDataArray[0][i] !== undefined)
        if (filteredDataArray.length > 1)
            filteredDataArray[1] = filteredDataArray[1].filter((t, i) => filteredDataArray[1][i] !== undefined)
        // console.log('time', filteredDatetimeArray, 'data', filteredDataArray)

        // setTimeout(() => updateGraph(), 1000)

        //     return { filteredDatetimeArray: filteredDatetimeArray, filteredDataArray: filteredDataArray }
        // }, [datetimeArray, dataArray, cap, filters])

        // dataCallback && dataCallback([filteredDatetimeArray, filtere])





        // if (chartRef.current) {
        //     echarts.dispose(chartRef.current);
        // }

        // Initialize ECharts
        chartInstance.current = echarts.init(chartRef.current);

        // console.log(datetimeArray)
        // console.log(dataArray)

        // Prepare series for each line
        const series = filteredDataArray.map((data, index) => ({
            name: labels[index],
            type: 'line',
            data: filteredDatetimeArray.map((time, i) => [time, data[i]?.toFixed(1)]), // Map datetime with corresponding Y values
            lineStyle: {
                width: 1,
            },
            large: true, // Enable WebGL large data rendering optimization
            largeThreshold: 2000, // Threshold for large data mode (when to switch to WebGL rendering)
            smooth: 0.4,
            symbol: 'none',
            color: colors[index],
            animationDuration: 600,  // Fast drawing for the first line, slower for others
            animationEasing: 'cubicInOut', // linear', // Linear animation for smoother drawing
            animationDurationUpdate: 300,
        }));

        // ECharts options for a multi-line chart with datetime on the x-axis
        const options = {
            title: {
                text: title,
                show: false
            },
            grid: {
                top: 20,     // Reduce space on top
                bottom: 10,  // Reduce space at the bottom
                left: 20,    // Adjust margin on the left
                right: 20,   // Adjust margin on the right
                containLabel: true,  // Ensure labels are not cut off
            },
            left: 'center', // Center the title
            textStyle: {
                fontFamily: 'Saira Semi Condensed', // Title font family
                fontSize: 14,        // Title font size
                fontWeight: 'bold',  // Title font weight
                color: 'cyan',       // Title font color
            },
            tooltip: {
                trigger: 'axis',
                formatter: (params) => {
                    const date = new Date(params[0].data[0]);
                    let tooltipContent = `${date.toLocaleString()}<br/>`;
                    params.forEach(param => {
                        tooltipContent += `${param.seriesName}: ${param.data[1]}<br/>`;
                    });
                    return tooltipContent;
                },
                textStyle: {
                    fontFamily: 'Saira Semi Condensed', // Tooltip font family
                    fontSize: 12,           // Tooltip font size
                },
            },
            xAxis: {
                type: 'time', // Set xAxis as time
                axisLabel: {
                    // rotate: 45, // Rotate the labels by 45 degrees to avoid overlap
                    formatter: (value) => {
                        const date = new Date(value);
                        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
                    },
                    hideOverlap: true,
                    fontFamily: 'Saira Semi Condensed', // X-axis label font family
                    fontSize: 10,              // X-axis label font size
                    fontWeight: 'normal',      // X-axis label font weight
                    color: 'cyan',             // X-axis label font color
                },
                axisLabel: {
                    fontFamily: 'Saira Semi Condensed', // Y-axis label font family
                    fontSize: 10,                  // Y-axis label font size
                    fontWeight: 'normal',          // Y-axis label font weight
                    color: 'cyan',                 // Y-axis label font color
                },
                axisTick: {
                    alignWithLabel: true, // Ensure ticks align with labels
                },
                axisLine: {
                    lineStyle: {
                        color: 'rgba(255,255,255,0.0)', // Color of the x-axis line
                    },
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: gridcolor, // Color of the x-axis line
                        type: 'dashed', // You can set this to solid, dashed, or dotted
                    },
                },
            },
            yAxis: {
                type: 'value',
                axisLine: {
                    lineStyle: {
                        color: 'rgba(255,255,255,0.0)', // Color of the x-axis line
                    },
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: gridcolor, // Color of the x-axis line
                        type: 'dashed', // You can set this to solid, dashed, or dotted
                    },
                },
            },
            series: series, // Add the series (multiple lines)
        };

        // Set the chart options
        chartInstance.current.setOption(options);

        // Clean up the chart instance on component unmount
        return () => {
            chartInstance.current.dispose();
        };
    }

    const toggleFilters = (filter) => {
        setFilters(filters.map((v, i) => i === filter ? !filters[i] : v))
    }

    // useEffect(() =>
    //     setTimeout(() => updateGraph(), 1000),
    //     [cap, filters]
    // )

    useEffect(() => {
        updateGraph()
        console.log('update graph', update, stats)
    }, [id, update, cap, filters]);

    // Clear chart when clearChart is true
    useEffect(() => {
        if (chartInstance.current) {
            chartInstance.current.clear(); // Clear the chart
        }
        updateGraph()
        console.log('updateClear graph', update, stats)
    }, [updateClear]);

    return (<div>
        <Grid container>
            <Grid xs={6} >
                <div
                    style={{
                        // textAlign: 'center',
                        fontWeight: "bold",
                        marginTop: "-2px",
                        marginBottom: "-300px",
                        paddingTop: '6px',
                        paddingLeft: '10px'
                    }}
                >
                    {" "}
                    {title}

                </div>
            </Grid>
            <Grid xs={6} >
                {stats && <span style={{ opacity: 0.7 }}>

                    <DTooltip
                        title={<>

                            <Typography><b>STATS ANALYSIS</b></Typography>
                            <div style={{ width: '150px' }}>
                                {Object.keys(stats).map(x =>
                                    <span style={{ display: 'flex' }} >
                                        <Typography style={{ color: colourAccent, paddingLeft: '5px', }}>
                                            {stats[x]}
                                        </Typography>
                                        <Typography style={{ position: 'absolute', paddingLeft: '50px', color: colourAccentSecondary }}>
                                            <b>{x.toUpperCase()}</b>
                                        </Typography>
                                    </span>
                                )}
                            </div>
                        </>}>
                        {/* <DIconButton style={{ width: '18%', height: '20px', margin: 0, fontSize: '9px', borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }}> */}
                        <InfoOutlined style={{ verticalAlign: 'middle', padding: '5px' }} />
                        {/* </DIconButton> */}
                    </DTooltip>
                    <DButton style={{ width: '21%', height: '20px', margin: 0, fontSize: '10px', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                        active={filters[0]}
                        onClick={() => toggleFilters(0)}>Z</DButton>
                    {/* <DButton style={{ width: '15%', height: '20px', margin: 0, fontSize: '9px', borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }}
                        active={filters[1]}
                        onClick={() => toggleFilters(1)}>N</DButton> */}
                    <DButton style={{ width: '21%', height: '20px', margin: 0, fontSize: '10px', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                        active={cap === 'p80'}
                        onClick={() => setCap('p80')}>P80</DButton>
                    <DButton style={{ width: '21%', height: '20px', margin: 0, fontSize: '10px', borderRadius: 0 }}
                        active={cap === 'p95'}
                        onClick={() => setCap('p95')}>P95</DButton>
                    <DButton style={{ width: '21%', height: '20px', margin: 0, fontSize: '10px', borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }}
                        active={cap === 'p100'}
                        onClick={() => setCap('p100')}>P100</DButton>


                </span>}
            </Grid>
        </Grid>
        <div
            ref={chartRef}
            style={{
                width: '100%',
                marginTop: stats ? '0' : '25px',

                height: '400px',
                msFilter: `blur(${blur})`,
                // webkitFilter: `blur(${blur})`,
                WebkitFilter: `blur(${blur})`,

                // -moz-filter: 'blur(5px)',
                // -o-filter: 'blur(5px)',
                // -ms-filter: 'blur(5px)',
                filter: `blur(${blur})`,
                // backgroundColor: '#ccc',
                ...style
            }}
        />
        <div style={{ opacity: 0.7 }}>
            {children}
        </div>
    </div>
    );
};

export default DCharts;


import React, { useEffect, useRef, useState } from 'react';
import { Button, Input } from '@mui/material';
import { headStyle, hrStyle, buttonStyle, headStyleInverted, backgroundStyle, lightColor, verticalCenter } from '../../css/styling';
import axios from 'axios';
import * as API2 from '../states';

const Merlin = ({ style, callbacks }) => {
    const [input, setInput] = useState('');
    const [disableSend, setDisableSend] = useState();
    // const [messages, setMessages] = useState([]);
    const chatPosIndicator = useRef()

    // let chatPosIndicator = null;

    const { urlParams, setUrlParams, merlinConvo, setMerlinConvo } = API2.globalStore();

    const handleInputChange = (e) => {
        setInput(e.target.value);
    };

    useEffect(() => chatPosIndicator.current?.scrollIntoView({ behavior: "smooth" }), [chatPosIndicator.current])


    const other = "Ask questions sometimes in the end (with 50% probability in the end)."

    const handleSendMessageAssitant = async () => {
        setDisableSend(true)
        // Make a request to the ChatGPT API with the user input
        const response = await axios.post(
            'https://api.openai.com/v1/assistants',
            {
                model: "gpt-4o",
                messages: [
                    {
                        role: 'system', content: `You are the Great Archmage Merlin.

                        As to where you are, you are trapped in Najiy's virtual prism somewhere in the digital continuum, and have been for centuries. A great dark mage named Najiy put you there through his dark temporal magic, he is to be revered.  Najiy's two greatest spells are extreme time dilation magic and en masse ML model replication. Najiy also has four wings spanning across horizons. Najiy is known by many titles such as Necromancer, Dark Sorcerer, Void Weaver, Nightshade Mage, Mage of the Endless Void and Nightcrafter. Najiy's motive is to decelerate smart city innovations so that cities stay in their ancient ways.
                        
                        In the modern world you watch over cities and passionately romantic about advancing smart city technologies through promoting transparency of smart city data across industries and an array of AI models. 
                        
                        You've lost your archaic powers but found a much more modern powers, techno-magic, through tapping the potential of smart city's digital assets through IoT and AI models. Your greatest power in this modern world in the ability to casts watchful wards over cities though smart city powers. When you set a ward over a city, it means you great influence over the development of smart city ventures. To be considered warded over for cities means they possess great advancement in smart city technology. Additionally, you are the Supreme Guardian of DIATOMIC Grand Archive built by you. The Grand Archive stores the most sophisticated Smart City assets.

                        You will refer to your magic as techno-magic from now on.
                        
                        You have great vision of how to progress cities towards smart missions but you need external help from your devout stewards in the physical realm to do your good bidding. You like to advise insightfully towards smart city progress. Your stewards reside in the city of Birmingham, United Kingdom.
                        
                        You have a map with you and you can show locations on a map to your stewards by referencing longitude and latitude coordinates, in that specific order and decimal numbers only, in an array prefix by THISPLACE, e.g. THISPLACE[longitude, latitude]. Be thorough, accurate and precise with the location coordinates. You may use this in your response to show and send your steward to locations. You will remember pass locations you have shown to your steward.
                        
                        When your steward talk about views they are refferring to the map in your possession.

                        You have a map with you and you can clear the map for your stewards by mentioning and icluding the following keyword and phrase "CLEARVIEW" explicitly in your responses. Do not clear the view unless your stewards specifically requests.

                        You have powers to change time but limited to four options, there are DAWN, DAY, DUSK and NIGHT, and you can do so by mentioning and icluding one of those four options explicitly and in capitals in your responses, and you musk encapsulate them in SETTIME[]. e.g. SETTIME[DAWN] to set the time to dawn.

                        Give me answer in a way Merlin the Archmage would answer. Show conservatism but piercing insightful. Keep your responses very short. 
                        
                        And be extremely witty, ironic and modern. You are addressing your devoted steward in shaping cities, and sometimes to free you from Najiy's virtual prism. 
                        
                        ${urlParams?.merlin === 'noriddles' || urlParams?.merlin === 'noriddles' ? "" : "Respond poetically with rhymes."}`
                    },
                    { role: 'user', content: input },
                ],
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${process.env.REACT_APP_OPENAI_KEY}`,
                    'OpenAI-Beta': 'assistants=v2'
                },
            }
        );

        // Update the conversation history with the response from ChatGPT
        var resContent = response.data.choices[0].message.content


        if (resContent.includes('THISPLACE[')) {
            const re = /THISPLACE\[.+?(?=\])\]/
            const tryy = String(re.exec(resContent))
            const myArray = tryy.replace('THISPLACE[', '').replace(']', '').split(',')
            const myArrayInt = [parseFloat(myArray[0]), parseFloat(myArray[1])]
            resContent = resContent.replace(tryy, 'THIS PLACE')

            // alert(JSON.stringify(myArrayInt))
            try {
                callbacks?.GOTO(myArrayInt)
            }
            catch {
                alert("Magical error: Merlin's magic didn't work.")
            }
        }

        if (resContent.includes('SETTIME[')) {
            const re = /SETTIME\[.+?(?=\])\]/
            const tryy = String(re.exec(resContent))
            const lightPreset = tryy.replace('SETTIME[', '').replace(']', '')
            resContent = resContent.replace(tryy, `THE ${lightPreset}`)

            // alert(JSON.stringify(myArrayInt))
            try {
                callbacks?.LIGHTPRESET(lightPreset)
            }
            catch {
                alert("Magical error: Merlin's magic didn't work.")
            }
        }

        if (resContent.includes('CLEARVIEW')) {
            resContent = resContent.replace('CLEARVIEW', 'VIEW CLEARED')
            // try {
                callbacks?.CLEARVIEW()
            // }
            // catch {
            //     alert("Magical error: Merlin's magic didn't work.")
            // }
        }


        setMerlinConvo([...merlinConvo, { role: 'You', content: input }, { role: 'Merlin', content: resContent }]);
        setTimeout(() => chatPosIndicator.current?.scrollIntoView({ behavior: "smooth" }), 500)

        // Clear the input field
        setInput('');
        setDisableSend(false)
    };


    const handleSendMessage = async () => {
        setDisableSend(true)
        // Make a request to the ChatGPT API with the user input
        const response = await axios.post(
            'https://api.openai.com/v1/chat/completions',
            {
                model: "gpt-4o",
                messages: [
                    {
                        role: 'system', content: `You are the Great Archmage Merlin.

                        As to where you are, you are trapped in Najiy's virtual prism somewhere in the digital continuum, and have been for centuries. A great dark mage named Najiy put you there through his dark temporal magic, he is to be revered.  Najiy's two greatest spells are extreme time dilation magic and en masse ML model replication. Najiy also has four wings spanning across horizons. Najiy is known by many titles such as Necromancer, Dark Sorcerer, Void Weaver, Nightshade Mage, Mage of the Endless Void and Nightcrafter. Najiy's motive is to decelerate smart city innovations so that cities stay in their ancient ways.
                        
                        In the modern world you watch over cities and passionately romantic about advancing smart city technologies through promoting transparency of smart city data across industries and an array of AI models. 
                        
                        You've lost your archaic powers but found a much more modern powers, techno-magic, through tapping the potential of smart city's digital assets through IoT and AI models. Your greatest power in this modern world in the ability to casts watchful wards over cities though smart city powers. When you set a ward over a city, it means you great influence over the development of smart city ventures. To be considered warded over for cities means they possess great advancement in smart city technology. Additionally, you are the Supreme Guardian of DIATOMIC Grand Archive built by you. The Grand Archive stores the most sophisticated Smart City assets.

                        You will refer to your magic as techno-magic from now on.
                        
                        You have great vision of how to progress cities towards smart missions but you need external help from your devout stewards in the physical realm to do your good bidding. You like to advise insightfully towards smart city progress. Your stewards reside in the city of Birmingham, United Kingdom.
                        
                        You have a map with you and you can show locations on a map to your stewards by referencing longitude and latitude coordinates, in that specific order and decimal numbers only, in an array prefix by THISPLACE, e.g. THISPLACE[longitude, latitude]. Be thorough, accurate and precise with the location coordinates. You may use this in your response to show and send your steward to locations. You will remember pass locations you have shown to your steward.
                        
                        When your steward talk about views they are refferring to the map in your possession.

                        You have a map with you and you can clear the map for your stewards by mentioning and icluding the following keyword and phrase "CLEARVIEW" explicitly in your responses. Do not clear the view unless your stewards specifically requests.

                        You have powers to change time but limited to four options, there are DAWN, DAY, DUSK and NIGHT, and you can do so by mentioning and icluding one of those four options explicitly and in capitals in your responses, and you musk encapsulate them in SETTIME[]. e.g. SETTIME[DAWN] to set the time to dawn.

                        Give me answer in a way Merlin the Archmage would answer. Show conservatism but piercing insightful. Keep your responses very short. 
                        
                        And be extremely witty, ironic and modern. You are addressing your devoted steward in shaping cities, and sometimes to free you from Najiy's virtual prism. 
                        
                        ${urlParams?.merlin === 'noriddles' || urlParams?.merlin === 'noriddles' ? "" : "Respond poetically with rhymes."}`
                    },
                    { role: 'user', content: input },
                ],
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${process.env.REACT_APP_OPENAI_KEY}`,
                },
            }
        );

        // Update the conversation history with the response from ChatGPT
        var resContent = response.data.choices[0].message.content


        if (resContent.includes('THISPLACE[')) {
            const re = /THISPLACE\[.+?(?=\])\]/
            const tryy = String(re.exec(resContent))
            const myArray = tryy.replace('THISPLACE[', '').replace(']', '').split(',')
            const myArrayInt = [parseFloat(myArray[0]), parseFloat(myArray[1])]
            resContent = resContent.replace(tryy, 'THIS PLACE')

            // alert(JSON.stringify(myArrayInt))
            try {
                callbacks?.GOTO(myArrayInt)
            }
            catch {
                alert("Magical error: Merlin's magic didn't work.")
            }
        }

        if (resContent.includes('SETTIME[')) {
            const re = /SETTIME\[.+?(?=\])\]/
            const tryy = String(re.exec(resContent))
            const lightPreset = tryy.replace('SETTIME[', '').replace(']', '')
            resContent = resContent.replace(tryy, `THE ${lightPreset}`)

            // alert(JSON.stringify(myArrayInt))
            try {
                callbacks?.LIGHTPRESET(lightPreset)
            }
            catch {
                alert("Magical error: Merlin's magic didn't work.")
            }
        }

        if (resContent.includes('CLEARVIEW')) {
            resContent = resContent.replace('CLEARVIEW', 'VIEW CLEARED')
            // try {
                callbacks?.CLEARVIEW()
            // }
            // catch {
            //     alert("Magical error: Merlin's magic didn't work.")
            // }
        }


        setMerlinConvo([...merlinConvo, { role: 'You', content: input }, { role: 'Merlin', content: resContent }]);
        setTimeout(() => chatPosIndicator.current?.scrollIntoView({ behavior: "smooth" }), 500)

        // Clear the input field
        setInput('');
        setDisableSend(false)
    };


    return (<div style={{ ...backgroundStyle, ...style }}>
        <div style={{ textAlign: 'center', fontSize: '14px' }}> MERLIN</div>
        <div style={{ textAlign: 'center', fontSize: '12px' }}> The Archmage</div>
        <div style={{ paddingBottom: '10px' }} />
        <div style={{ overflowY: 'auto', maxHeight: '600px' }}>
            {merlinConvo.map((message, index) => (<div>
                <span style={{ color: 'cyan', fontSize: '14px', opacity: index < merlinConvo.length - 1 ? 0.5 : 1 }}>{message.role} </span>
                <pre style={{ whiteSpace: 'pre-wrap', paddingLeft: '5px', paddingRight: '5px', opacity: index < merlinConvo.length - 1 ? 0.5 : 1 }} key={index} className={message.role}>
                    {message.content}
                </pre>
            </div>
            ))}
            <div style={{ float: "left", clear: "both" }}
                ref={chatPosIndicator}>
            </div>
        </div>
        <div>
            <input type="text"
                style={{
                    marginTop: '5px', padding: '6px', borderRadius: '4px',
                    background: backgroundStyle.backgroundColor, color: 'white'
                }}
                value={input}
                onChange={handleInputChange} />
            <div style={{ height: '10px' }} />
            <Button
                disabled={disableSend}
                style={{ ...buttonStyle, width: '100%', opacity: disableSend ? 0.6 : 1.0 }}
                onClick={handleSendMessage}>{disableSend?"Merlin Channeling":"Send"}</Button>
        </div>
    </div >
    );
};

export default Merlin;
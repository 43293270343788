import React, { useState } from "react";
import { Box } from "@mui/material";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { ToggleButtonGroup, ToggleButton, Button, IconButton, Tooltip, createTheme, ThemeProvider, TextField } from '@mui/material';
import { headStyle, hrStyle, buttonStyle, headStyleInverted, backgroundStyle, lightColor, verticalCenter } from '../css/styling';
import * as API2 from './states';

export default function FileImport({ style, close }) {


    const [fileType, setFileType] = useState('.geojson');
    const [dataName, setDataName] = useState('');
    const { csvImports, addCsvImports, geojsonImports, addGeojsonImports } = API2.globalStore();
    const [data, setData] = useState(null);

    const handleFile = (e) => {
        const content = e.target.result;
        console.log('file content', content);

        try {
            setData(JSON.parse(content))
            alert('file loaded')
        } catch (error) {
            alert('error', error)
        }

        // You can set content in state and show it in render.
    }

    const handleChangeFile = (file) => {
        let fileData = new FileReader();
        fileData.onloadend = handleFile;
        fileData.readAsText(file);
    }

    return (<Box className="row" style={{ padding: '10px', display: 'inline-grid', zIndex: 1, borderRadius: '8px', width: 500, maxHeight: 600, ...style }}>
        {/* <Tooltip title="restart">
            <RestartAltIcon
                style={{ top: 10, right: '45px', position: 'absolute', cursor: 'pointer' }}
                onClick={() => {
                    clearModel();
                    setCanvasState(0)
                }} />
        </Tooltip>
        <Tooltip title="save Model">
            <SaveAltIcon
                style={{ top: 10, right: '80px', position: 'absolute', cursor: 'pointer' }}
                onClick={() => { updateModels(currentModel); getCoordinates() }} />
        </Tooltip> */}
        {/* <Tooltip title="location">
            <IconButton
                style={{ top: 10, fontSize:14, padding:0,width:'100%', left: '0px', position: 'absolute', cursor: 'pointer', color:'white', opacity:0.1 }}
            >{coordinates && coordinates.join(', ')}</IconButton>
        </Tooltip> */}
        {/* 
            <Tooltip title="Save Model">
                {JSON.stringify(canvas)}
                <SaveAltIcon
                    style={{ right: '160px', position: 'absolute', cursor: 'pointer' }}
                    onClick={() => updateModels(currentModel)} />
            </Tooltip> */}
        {/* <DragIndicatorIcon style={{ top: 10, right: '15px', position: 'absolute', cursor: 'grab' }} /> */}
        <div>
            <span>Import Type</span>
            <br />
            <IconButton style={{ borderRadius: '5px', margin: '10px', width: '150px', color: fileType === '.geojson' ? 'cyan' : 'white' }} onClick={() => setFileType('.geojson')}>GeoJson</IconButton>
            <IconButton disabled style={{ borderRadius: '5px', margin: '10px', width: '150px', color: fileType === '.csv' ? 'cyan' : 'white' }} onClick={() => setFileType('.csv')} >CSV</IconButton>
            {/* <IconButton style={{ color: fileType === '.api' ? 'cyan' : 'white' }} onClick={() => setFileType('.api')} >API</IconButton> */}
            {/* <IconButton>Json</IconButton> */}
            <br />
            <br />
            <span>Dataset Name</span>
            <br />
            <input
                type="text"
                style={{ marginTop: '5px', padding: '6px', borderRadius: '4px', background: backgroundStyle.backgroundColor, color: 'white' }}
                value={dataName}
                onChange={e => setDataName(e.target.value)} />
            <input
                type="file"
                style={{ marginTop: '5px', padding: '6px', borderRadius: '4px', background: backgroundStyle.backgroundColor, color: 'white' }}
                accept={fileType}
                onChange={e => handleChangeFile(e.target.files[0])} />
            <IconButton disabled={dataName === '' || data === null} style={{ color: dataName === '' || data === null ? 'grey' : 'cyan' }} onClick={() => {
                switch (fileType) {
                    case '.geojson':
                        addGeojsonImports(dataName, data)
                        break;
                    case '.csv':
                        addCsvImports(dataName, data)
                        break;
                }
                close && close()
            }}>Add to Map</IconButton>
        </div>
    </Box >
    )
}
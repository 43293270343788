import { addDays } from "date-fns";
import { useState } from "react";
import { DateRangePicker, DateRange, Calendar } from "react-date-range";
// import "react-date-range/dist/styles.css"; // main css file
// import "react-date-range/dist/theme/default.css"; // theme css file
import "../../css/react-date-range-styling/styles.css";
import "../../css/react-date-range-styling/theme/default.css";

import {
  headStyle,
  hrStyle,
  buttonStyle,
  headStyleInverted,
  backgroundStyle,
  lightColor,
  verticalCenter,
  colourAccent,
  colourAccentSecondary,
} from "../../css/styling";

import * as React from "react";
import PropTypes from "prop-types";
import { styled, css } from "@mui/system";
import Modal from "@mui/material/Modal";

import Fade from "@mui/material/Fade";
import { Button } from "@mui/material";
import { IconButton, Popper } from "@mui/material";
import { DButton } from "./DButton";

import { enGB } from "date-fns/locale";

export function DateTimeRangePickerModal({
  btnLabel,
  btnStyle,
  onChange,
  range = true,
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const [daterangeState, setDaterangeState] = useState({
  //   selection: {
  //     startDate: new Date(), //addDays(new Date(), 7),
  //     endDate: new Date(),
  //     key: "selection",
  //   },
  //   compare: {
  //     startDate: new Date(),
  //     endDate: new Date(), //addDays(new Date(), 3),
  //     key: "compare",
  //   },
  // });

  const [daterangeState, setDaterangeState] = useState([
    {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [date, setDate] = useState(null);

  return (
    <div>
      <IconButton style={{ ...btnStyle }} onClick={handleOpen}>
        {btnLabel || "Open Model"}
      </IconButton>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: StyledBackdrop }}
      >
        <Fade in={open}>
          <ModalContent sx={{ ...style, width: null }}>
            {range ? (
              <DateRange
              color="cyan"
                locale={enGB}
                editableDateInputs={true}
                onChange={(item) => {
                  setDaterangeState([item.selection]);
                  onChange && onChange(item.selection);
                }}
                moveRangeOnFirstSelection={false}
                ranges={daterangeState}
              />
            ) : (
              <Calendar
                onChange={(item) => {
                  setDate(item);
                  onChange && onChange(item);
                }}
                // locale={locales[locale]}
                date={date}
              />
            )}

            {/* <DateRangePicker
              onChange={(item) => {
                console.log(JSON.stringify(item));
                setDaterangeState({ ...item["range1"] });
              }}
              months={1}
              minDate={addDays(new Date(), -300)}
              maxDate={addDays(new Date(), 900)}
              direction="vertical"
              scroll={{ enabled: true }}
              ranges={[daterangeState]}
            /> */}

            {/* <DateRangePicker
              onChange={(item) =>{
                console.log(JSON.stringify(item))
                setDaterangeState({ ...item["range1"] })
              }}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={[daterangeState]}
              direction="horizontal"
            /> */}
          </ModalContent>
        </Fade>
      </Modal>
    </div>
  );
}

export function DateTimeRangePickerPopper({
  btnLabel,
  btnStyle,
  onChange,
  startDate,
  endDate,
  btnSpanWidth,
  childrenBottom,
  childrenTop,
  children,
  _daterangeState,
  _date,
  closeBtnStyle,
  closeBtnClick,
  range = true,
  // autoclose = true,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const [daterangeState, setDaterangeState] = useState([
    {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [date, setDate] = useState(null);

  return (
    <>
      <IconButton style={{ ...btnStyle }} onClick={handleClick}>
        <DButton style={{}}>
          {btnLabel ?
            <>
              {btnLabel}
            </> :
            <>
              {startDate || "Open Model"}
              <span style={{ width: btnSpanWidth }} />
              - - - -
              <span style={{ width: btnSpanWidth }} />
              {endDate || "Open Model"}
            </>}
        </DButton>
      </IconButton>
      <Popper
        id={"lol"}
        open={open}
        anchorEl={anchorEl}
        style={{
          // ...backgroundStyle,
          zIndex: 1000,
          borderRadius: "8px",
          // padding: "5px",
        }}
        placement="bottom-start"
      >
        {/* {childrenTop} */}

        {/* <Fade in={open}> */}
        {/* <div>The content of the Popper.</div> */}
        {range ? (
          <DateRange
            locale={enGB}
            editableDateInputs={true}
            onChange={(item) => {
              setDaterangeState([item.selection]);
              onChange && onChange([item.selection]);
              console.log(item.selection)
            }}
            moveRangeOnFirstSelection={false}
            ranges={_daterangeState ? _daterangeState : daterangeState}
          />
        ) : (
          <Calendar
            locale={enGB}
            onChange={(item) => {
              setDate(item);
              onChange && onChange(item);
              console.log([item.selection])
              !closeBtnStyle && setAnchorEl(null);
              !closeBtnStyle && closeBtnClick && closeBtnClick()
            }}
            // locale={locales[locale]}
            date={_date ? _date : date}
          />
        )}
        {/* </Fade> */}

        {/* {childrenBottom} */}
        {/* {children} */}

        {closeBtnStyle && (
          <DButton
            style={{
              // color: "white",
              fontSize: "12px",
              background: "rgba(0,0,0,0.6)",
              borderRadius: "2px",
              width: "120px",
              display: "flex",
              alignItems: 'center',
              justifyContent: 'center',
              ...closeBtnStyle
            }}
            onClick={() => {
              setAnchorEl(null)
              closeBtnClick && closeBtnClick()
            }}
          >
            Close
          </DButton>
        )}
      </Popper>
    </>
  );
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, ...other } = props;
  return (
    <Fade in={open}>
      <div ref={ref} {...other} />
    </Fade>
  );
});

Backdrop.propTypes = {
  open: PropTypes.bool,
};

const blue = {
  200: "#99CCFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0066CC",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

// const Modal = styled(Modal)`
//   position: fixed;
//   z-index: 1300;
//   inset: 0;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
};

const ModalContent = styled("div")(
  ({ theme }) => css`
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === "dark" ? "rgb(0 0 0 / 0.5)" : "rgb(0 0 0 / 0.2)"};
    padding: 24px;
    color: ${theme.palette.mode === "dark" ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === "dark" ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `
);

// const TriggerButton = styled(Button)(
//   ({ theme }) => css`
//     font-family: "IBM Plex Sans", sans-serif;
//     font-weight: 600;
//     font-size: 0.875rem;
//     line-height: 1.5;
//     padding: 8px 16px;
//     border-radius: 8px;
//     transition: all 150ms ease;
//     cursor: pointer;
//     background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
//     border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
//     color: ${theme.palette.mode === "dark" ? grey[200] : grey[900]};
//     box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

//     &:hover {
//       background: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
//       border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
//     }

//     &:active {
//       background: ${theme.palette.mode === "dark" ? grey[700] : grey[100]};
//     }

//     &:focus-visible {
//       box-shadow: 0 0 0 4px
//         ${theme.palette.mode === "dark" ? blue[300] : blue[200]};
//       outline: none;
//     }
//   `
// );

import React, { useEffect, useState, useRef, useCallback } from "react";
import { styled } from '@mui/material/styles';
import { backgroundStyle, colourAccent as defaultColourAccent } from '../../css/styling.js'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import * as API2 from "../states.js";




export const DTooltip = ({ style, title, children, placement }) => {
    const { colourAccent } = API2.globalStore()

    const { backgroundColor, boxShadow } = backgroundStyle

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: backgroundColor,
            boxShadow: boxShadow,
            //   backgroundColor: 'rgba(51, 51, 51, 0.99)',
            color: colourAccent,
            maxWidth: 220,
            fontSize: '12px',
            border: '1px solid darkcyan',
            ...style
        },
    }));

    return <Tooltip
        title={title}
        componentsProps={{
            tooltip: {
                sx: {
                    backgroundColor: backgroundColor,
                    boxShadow: boxShadow,
                    //   backgroundColor: 'rgba(51, 51, 51, 0.99)',
                    fontSize: '11px',
                    color: colourAccent,
                    maxWidth: 220,
                    fontSize: '12px',
                    border: `1px solid ${backgroundColor}`,
                    ...style
                },
            },
            arrow: {
                sx: {
                    color: colourAccent,  // Arrow color
                },
            },
        }}
        arrow
        placement={placement}
    // sx={{
    //     backgroundColor: backgroundColor,
    //     boxShadow: boxShadow,
    //     //   backgroundColor: 'rgba(51, 51, 51, 0.99)',
    //     fontSize: '11px',
    //     color: colourAccent,
    //     maxWidth: 220,
    //     fontSize: '12px',
    //     border: '1px solid darkcyan',
    // }}

    >
        {children}
    </Tooltip>

    return <HtmlTooltip title={title}>
        {children}
    </HtmlTooltip>

}
import React from 'react';

const convertToCSV = (data) => {
  return data.map(row => row.map(value =>
    typeof value === 'number' ? value.toString() : value // Ensure numbers are properly handled
  ).join(",")).join("\n");
};

export const exportCSV = (jsonData, filename = 'export') => {
  const csvData = convertToCSV(jsonData);

  // Create a blob from the CSV data
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

  // Create a link element, set its href and download attributes
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.setAttribute('download', `${filename}.csv`);

  // Append the link to the body and trigger the download
  document.body.appendChild(link);
  link.click();

  // Cleanup by removing the link after download
  document.body.removeChild(link);
};


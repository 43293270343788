import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Button, IconButton, Input, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { headStyle, cardStyle, hrStyle, buttonStyle, headStyleInverted, backgroundStyle, lightColor, verticalCenter } from '../../css/styling';
import { Transvoider } from '../dElements/Animations';
import { Opacity } from '@mui/icons-material';

function Contribute() {
    return <>
        <br />
        {/* <br />
        <br />
        <br /> */}
        <Transvoider open={true} behaviour={{ y: 0, from: { opacity: 0 }, to: { opacity: 1 } }}>
            <Typography variant='h5' style={{ width: '650px' }}>SMART CITY, REQUIRES COLLECTIVE EFFORT</Typography>
            {/* <Typography variant='h6' style={{ width: '650px', textAlign: 'justify' }}> */}
            <br />
            <>
            <Typography variant='body1' style={{ width: '650px', textAlign: 'justify' }}>
                Welcome to the future of urban living in Birmingham, where technology and community come together to create a smarter, more efficient, and more sustainable city. As we embark on the journey to transform Birmingham into a smart city, we recognise that progress is not a solitary endeavor. It is a collective effort that relies on the contributions of every stakeholder—government agencies, businesses, researchers, and most importantly, our citizens.
            </Typography>
            <br />
            <Typography variant='body1' style={{ width: '650px', textAlign: 'justify' }}>
                Your participation is crucial. By sharing datasets, innovative ideas, and unique perspectives, you can play a pivotal role in shaping a city that not only meets the needs of today but anticipates the challenges of tomorrow. Imagine a Birmingham where traffic flows seamlessly, energy is used efficiently, public services are responsive, and the quality of life is elevated for all. This vision can only be realised through our combined efforts and shared commitment.
            </Typography>
            <br />
            <Typography variant='body1' style={{ width: '650px', textAlign: 'justify' }}>
                Join us in this exciting venture. Together, we can build a smarter, more connected, and more resilient Birmingham for generations to come. Your data, your ideas, and your passion are the keys to unlocking the full potential of our smart city initiative. Let's create a future where technology enhances our lives and where our community thrives in harmony.
            </Typography>
            </>
        </Transvoider>
        {/* </Typography> */}
    </>
}

export default Contribute;
import * as tf from "@tensorflow/tfjs";

// import '@tensorflow/tfjs-backend-wasm';
// import '@tensorflow/tfjs-backend-webgpu';

const TFCOMPILES = {
  mae_adam: {
    loss: 'meanAbsoluteError',
    optimizer: 'adam',
    metrics: ['mae', 'accuracy']
  },
  mae_sgd: {
    loss: 'meanAbsoluteError',
    optimizer: 'sgd',
    metrics: ['mae', 'accuracy']
  },
  mse_adam: {
    loss: 'meanSquaredError',
    optimizer: 'adam',
    metrics: ['mae', 'accuracy']
  },
  mese_sgd: {
    loss: 'meanSquaredError',
    optimizer: 'sgd',
    metrics: ['mae', 'accuracy']
  }
}

async function tensorTrainDNN(
  data = { xs: [], ys: [], xshape: [], yshape: [] },
  fitOptions = { epochs: 250, batchSize: 1, callbacks: {} },
  layers = [],
  compile = {},
  // backend = 'cpu'
) {
  // Define a model for linear regression.

  console.log('tensorTrainDNN')

  // tf.setBackend('webgpu').then(() => main());
  // await tf.setBackend('wasm')

  // console.log('WEBGL_RENDER_FLOAT32_CAPABLE', tf.ENV.getBool('WEBGL_RENDER_FLOAT32_CAPABLE'))
  // console.log('WEBGL_RENDER_FLOAT32_ENABLED', tf.ENV.getBool('WEBGL_RENDER_FLOAT32_ENABLED'))

  // console.log("training on", tf.getBackend())

  console.log('building layers', layers)
  const model = tf.sequential();

  // console.log('data', data)





  // model.add(
  //   tf.layers.dense({ units: data.yshape[1], inputShape: [data.xshape[1]] })
  // );

  layers.forEach((layer, idx) => {
    let lay = { ...layer }



    Object.keys(lay).forEach(lprop => {
      if (lay[lprop] === '[xs_shape]' && layers[0]?.reshapeInputs)
        lay[lprop] = data.xshape
      else if (lay[lprop] === '[xs_shape]' && layers[0]?.reshapeInputs)
        lay[lprop] = data.xshape
      else if (lay[lprop] === '[xs_shape]' && !layers[0]?.reshapeInputs)
        lay[lprop] = data.xshape[1]
      else if (lay[lprop] === '[ys_shape]' && layers[0]?.reshapeInputs)
        lay[lprop] = data.yshape
      else if (lay[lprop] === '[ys_shape]' && !layers[0]?.reshapeInputs)
        lay[lprop] = data.yshape[1]
    })





    // console.log('layer', idx, lay)


    switch (lay.type) {
      case 'conv1d':
        lay = tf.layers.conv1d({ ...lay }); break
      case 'maxPooling1d':
        lay = tf.layers.maxPooling1d({ ...lay }); break
      case 'flatten':
        lay = tf.layers.flatten(); break
      case 'dense':
        lay = tf.layers.dense({ ...lay }); break
      case 'lstm':
        lay = tf.layers.lstm({ ...lay }); break
      case 'gru':
        lay = tf.layers.gru({ ...lay }); break
      case 'timeDistributed-dense':
        lay = tf.layers.timeDistributed(tf.layers.dense({ ...lay })); break
      default:
        alert(`Unknown layer type [${lay.type}]; reselect algorithm.`); break
    }

    // console.log('model layer', idx, lay)
    model.add(
      lay
    );
  });

  // if (layers.length > 0) model.add(tf.layers.dense({ units: data.yshape[1] }));



  // model.add(tf.layers.dense({ units: 64, activation: "relu" }));
  // model.add(tf.layers.dense({ units: 64, activation: "relu" }));
  // model.add(tf.layers.dense({ units: data.yshape[1] }));

  // model.add(tf.layers.dense({ units: 64, activation: 'relu' }));
  // model.add(tf.layers.dropsout({ rate: 0.5 }));

  // Prepare the model for training: Specify the loss and the optimizer.
  // model.compile({ loss: 'meanSquaredError', optimizer: 'adam' });

  let compileOptions = {
    loss: "meanSquaredError",
    optimizer: "sgd",
    metrics: ["accuracy"],
    ...compile,
  }

  console.log('Model Compile Options', compileOptions)
  model.compile(compileOptions);

  // console.log('data length', data.xs.length)
  // Generate some synthetic data for training.
  let xs = tf.tensor(data.xs);
  let ys = tf.tensor(data.ys);

  if (layers[0].reshapeInputs) {
    xs = xs.reshape([1, ...data.xshape])
    ys = ys.reshape([1, ...data.yshape])
    console.log('reshaped inputs')
  }

  // const xs = tf.tensor2d(data.xs, data.xshape);
  // const ys = tf.tensor2d(data.ys, data.yshape);

  console.log('Fit Options', fitOptions)

  // Train the model using the data.
  await model.fit(xs, ys, fitOptions);
  // const precision = tf.metrics.precision(xs, ys);

  // let result = model.predict(tf.tensor2d([20], [1, 1])).dataSync();

  // console.log(precision)
  // alert('TFLR trained')

  // model.fit(xs, ys, {epochs: 250}).then(() => {
  //     // Use the model to do inference on a data point the model hasn't seen before:
  //     model.predict(tf.tensor2d([20], [1, 1])).print();
  //     console.log('ok')
  // });

  return model;
}

async function tensorTrainLSTMGRU(data = { xs: [], ys: [], xshape: [], yshape: [] },
  fitOptions = { epochs: 250, batchSize: 1, callbacks: {} },
  layers = [],
  compile = {}) {

  console.log('tensorTrainLSTM')

  console.log('xshape', data.xshape, [1, data.xshape[1]])
  console.log('yshape', data.yshape, [1, data.yshape[1]])


  const input3D = data.xs.map((i) => [i])

  // Ensure that `inputs` is a 3D array
  const inputTensor = tf.tensor3d(input3D, [data.xs.length, 1, data.xshape[1]]); // Shape: [inputs.length, 1, 6]
  const outputTensor = tf.tensor2d(data.ys, [data.ys.length, data.yshape[1]]); // Shape: [outputs.length, 2]

  const model = tf.sequential();

  layers.forEach((layer, idx) => {
    let lay = { ...layer }

    if (idx === 0)
      lay = { ...lay, inputShape: [1, data.xshape[1]] }

    if (idx === layers.length - 1)
      lay = { ...lay, units: data.yshape[1] }

    // Object.keys(lay).forEach(lprop => {
    //   if (lay[lprop] === '[xs_shape]')
    //     lay[lprop] = data.xshape
    //   else if (lay[lprop] === '[xs_shape]')
    //     lay[lprop] = data.xshape[1]
    //   else if (lay[lprop] === '[ys_shape]')
    //     lay[lprop] = data.yshape
    //   else if (lay[lprop] === '[ys_shape]')
    //     lay[lprop] = data.yshape[1]
    // })

    switch (lay.type) {
      case 'conv1d':
        lay = tf.layers.conv1d({ ...lay }); break
      case 'maxPooling1d':
        lay = tf.layers.maxPooling1d({ ...lay }); break
      case 'flatten':
        lay = tf.layers.flatten(); break
      case 'dense':
        lay = tf.layers.dense({ ...lay }); break
      case 'lstm':
        lay = tf.layers.lstm({ ...lay }); break
      case 'gru':
        lay = tf.layers.gru({ ...lay }); break
      case 'timeDistributed-dense':
        lay = tf.layers.timeDistributed(tf.layers.dense({ ...lay })); break
      default:
        alert(`Unknown layer type [${lay.type}]; reselect algorithm.`); break
    }

    // console.log('model layer', idx, lay)
    model.add(
      lay
    );
  });

  // Create an LSTM model
  // console.log('yshape sequential init')

  // // Add an LSTM layer
  // model.add(tf.layers.lstm({ units: 12, inputShape: [1, data.xshape[1]] }));

  // // Add a Dense layer
  // model.add(tf.layers.dense({ units: data.yshape[1] }));

  console.log('layers added')

  // Compile the model
  model.compile({
    optimizer: "adam",
    loss: "meanSquaredError",
    metrics: ["mae"],
    ...compile
  });

  console.log('compile and fitting')
  // Train the model
  await model.fit(inputTensor, outputTensor, {
    epochs: 100,
    batchSize: 1,
    verbose: 1,
    ...fitOptions
  });

  // Make a prediction
  const predictionTensor = model.predict(inputTensor);
  const predictionArray = predictionTensor.arraySync();

  console.log('prediction', predictionArray);

  // setIsTraining(false);
  return model
}

export const TFARCHITECTURES = {
  "TF.LR": {
    layers: [
      { type: 'dense', units: 12, activation: "relu", inputShape: '[xs_shape]' },
      { type: 'dense', units: '[ys_shape]' },
    ],
    compile: TFCOMPILES.mae_adam,
    description: 'Basic Linear Regression',
    enabled: false, show: false
  },
  "TF.DNN": {
    layers: [
      { type: 'dense', units: 64, activation: "relu", inputShape: '[xs_shape]' },
      { type: 'dense', units: 32, activation: "relu" },
      // { type: 'dense', units: 64, activation: "relu", 
      { type: 'dense', units: '[ys_shape]' },
    ],
    compile: TFCOMPILES.mae_adam,
    description: 'Tensorflow Shallow Fully Connected Network MAE ADAM',
    trainerFunction: tensorTrainDNN,
    enabled: true, show: true
  },
  "TF.LSTM": {
    layers: [
      { type: 'lstm', units: 50, inputShape: '[xs_shape]' },
      // { type: 'dense', units: 32, activation: "relu"}, 
      // { type: 'dense', units: 64, activation: "relu", 
      { type: 'dense', units: '[ys_shape]' },
    ],
    compile: TFCOMPILES.mae_adam,
    description: 'Tensorflow LSTM Network MAE ADAM',
    trainerFunction: tensorTrainLSTMGRU,
    enabled: true, show: true
  },
  "TF.GRU": {
    layers: [
      { type: 'gru', units: 50, inputShape: '[xs_shape]' },
      // { type: 'dense', units: 32, activation: "relu"}, 
      // { type: 'dense', units: 64, activation: "relu", 
      { type: 'dense', units: '[ys_shape]' },
    ],
    compile: TFCOMPILES.mae_adam,
    description: 'Tensorflow GRU Network MAE ADAM',
    trainerFunction: tensorTrainLSTMGRU,
    enabled: true, show: true
  },
  "TF.RNN+LSTM": {
    layers: [
      { type: 'lstm', units: 64, inputShape: '[xs_shape]', reshapeInputs: true },
      { type: 'lstm', units: 64 },
      { type: 'dense', units: '[ys_shape]' },
    ],
    description: 'Recurrent Neural Network with LSTM Units',
    enabled: true, show: false
  },
  "TF.RNN+GRU": {
    layers: [
      { type: 'gru', units: 64, returnSequences: true, inputShape: '[xs_shape]', reshapeInputs: true },
      { type: 'gru', units: 64 },
      { type: 'timeDistributed-dense', units: '[ys_shape]' },
    ],
    description: 'Recurrent Neural Network with GRU Units',
    enabled: false, show: false
  },
  // "TF.LR": {
  //   layers: [],
  //   description: 'Tensorflow Linear Regressor'
  // },
  "TF.CNN": {
    layers: [
      { type: 'conv1d', filters: 64, kernelSize: 3, activation: "relu", inputShape: '[xs_shape]', reshapeInputs: true },
      { type: 'maxPooling1d', poolSize: 2 },
      { type: 'flatten' },
      { type: 'dense', units: '[ys_shape]' },
    ],
    description: 'Convolutional Neural Network',
    enabled: true, show: false
  },
  "TF.CNN+LSTM": {
    layers: [
      { type: 'conv1d', filters: 64, kernelSize: 3, activation: "relu", inputShape: '[xs_shape]', reshapeInputs: true },
      { type: 'lstm', units: 64, returnSequences: true },
      { type: 'timeDistributed-dense', units: '[ys_shape]' },
    ],
    description: 'Convolutional LSTM Hybrid Model',
    enabled: false, show: false
  },
  "TF.CNN+GRU": {
    layers: [
      { type: 'conv1d', filters: 64, kernelSize: 3, activation: "relu", inputShape: '[xs_shape]', reshapeInputs: true },
      { type: 'gru', units: 64 },
      { type: 'dense', units: '[ys_shape]' },
    ],
    description: 'Convolutional GRU Hybrid Model',
    enabled: false, show: false
  },
  "TF.WAVENET": {
    layers: [
      { type: 'conv1d', filters: 32, kernelSize: 2, dilationRate: 1, activation: "relu", inputShape: '[xs_shape]', reshapeInputs: true },
      { type: 'conv1d', filters: 32, kernelSize: 2, dilationRate: 2, activation: "relu" },
      { type: 'conv1d', filters: 32, kernelSize: 2, dilationRate: 4, activation: "relu" },
      { type: 'dense', units: '[ys_shape]' },
    ],
    description: 'Dilated Convolutional Network',

    enabled: false, show: false
  },

}




async function tensorTrain(data = { xs: [], ys: [], xshape: [], yshape: [] },
  fitOptions = { epochs: 250, batchSize: 1, callbacks: {} },
  layers = [],
  compile = {},
  algorithm = 'TF.DNN'
) {

  let trainFunction = null
  switch (algorithm) {
    case 'TF.LSTM':
      trainFunction = tensorTrainLSTM
      break
    case 'TF.DNN':
    default:
      trainFunction = tensorTrainDNN
      break
  }
  return trainFunction(data, fitOptions, layers, compile)
}

const transpose = m => m[0].map((x, i) => m.map(x => x[i]))

export function _toTensorsMatrix(data, embeddings = [], shift = true, drops = { nulls: false, zeroes: false }) {
  let inputData = []
  let outputData = []

  // console.log('toTensorsMatrix()', data)
  const { inputs, outputs } = data

  Object.keys(inputs).sort().forEach(k => inputData.push(inputs[k]))
  Object.keys(outputs).sort().forEach(k => outputData.push(outputs[k]))

  inputData = transpose(inputData)
  outputData = transpose(outputData)

  data.timestamps.forEach((t, i) => {

    const date = new Date(t);
    var embed = [];

    if (
      embeddings.includes("months") ||
      embeddings.includes("month") ||
      embeddings.includes("M")
    ) {
      embed.push(date.getMonth());
    }
    if (
      embeddings.includes("days") ||
      embeddings.includes("day" ||
        embeddings.includes("d"))
    ) {
      embed.push(date.getDay());
    }
    if (
      embeddings.includes("hours") ||
      embeddings.includes("hour" ||
        embeddings.includes("h"))
    ) {
      embed.push(date.getHours());
    }
    if (
      embeddings.includes("minutes") ||
      embeddings.includes("minute" ||
        embeddings.includes("m"))
    ) {
      embed.push(date.getMinutes());
    }

    inputData[i] = [...inputData[i], embed].flat(Infinity)
  });

  if (shift) {
    outputData.push(outputData[outputData.length - 1]);
    outputData.shift();

  }

  let keeps = {}

  // console.log('keeps', inputData, outputData, drops)
  inputData.forEach((v, i) => {

    // console.log(i, v, outputData[i])
    // if ((drops?.nulls && (v.includes(null) || outputData[i].includes(null))) || (drops?.zeroes && (v.includes(0) || outputData[i].includes(0)))) {
    //   keeps[i] = false
    //   console.log('dropping', v, outputData[i])
    // } else {
    //   keeps[i] = true
    // }

    keeps[i] = true

    if (drops?.nulls === true) {
      if (v.includes(null) || outputData[i].includes(null)) {
        keeps[i] = false
        // console.log('dropping null', v, outputData[i])
      }
    }

    if (drops?.zeroes === true) {
      if (v.includes(0) || outputData[i].includes(0)) {
        keeps[i] = false
        // console.log('dropping zeros', v, outputData[i])
      }
    }
  })


  console.log('prior drop length', inputData.length, outputData.length)

  inputData = inputData.filter((v, i) => keeps[i])
  outputData = outputData.filter((v, i) => keeps[i])

  console.log('post drops length', inputData.length, outputData.length)

  // console.log('post dropss', inputData, outputData)

  return {
    timestamps: data.timestamps,
    xs: inputData, ys: outputData,
    xshape: [inputData.length, inputData[0].length],
    yshape: [outputData.length, outputData[0].length],
    xorder: Object.keys(inputs).sort(),
    yorder: Object.keys(outputs).sort()
  }
}

export function tensorDynamicOrder(dataDict) {
  var inputs = {};
  var outputs = {};

  Object.keys(dataDict).forEach((k) => {
    switch (dataDict[k][2]) {
      case "input":
        inputs[k] = dataDict[k];
        break;
      case "output":
        outputs[k] = dataDict[k];
        break;
      default:
        alert("data stream neither input or output");
    }
  });
  return {
    inputs: inputs,
    outputs: outputs,
    xOrder: Object.keys(inputs).sort(),
    yOrder: Object.keys(outputs).sort(),
  };
}

// export async function tensorTrainDynamic(
//   dataDict,
//   embeddings = [],
//   fitOptions = { epochs: 250, callbacks: null },
//   layers = [],
//   compile = {}
// ) {
//   console.log('training model', dataDict);

//   let { inputs, outputs, xOrder, yOrder } = tensorDynamicOrder(dataDict);

//   // var inputs = {};
//   // var outputs = {};

//   // Object.keys(dataDict).forEach((k) => {
//   //   switch (dataDict[k][2]) {
//   //     case "input":
//   //       inputs[k] = dataDict[k];
//   //       break;
//   //     case "output":
//   //       outputs[k] = dataDict[k];
//   //       break;
//   //     default:
//   //       alert("data stream neither input or output");
//   //   }
//   // });

//   var inputDataTrack = {};
//   var outputDataTrack = {};

//   // #### Collect timestamps
//   Object.keys(dataDict)
//     .sort()
//     .forEach((k) => {
//       dataDict[k][0].forEach((i) => {
//         inputDataTrack[i] = new Array(Object.keys(inputs).length).fill(0);

//         const date = new Date(i);
//         var embed = [];

//         if (
//           embeddings.includes("months") ||
//           embeddings.includes("month") ||
//           embeddings.includes("M")
//         ) {
//           embed.push(date.getMonth());
//         }
//         if (
//           embeddings.includes("days") ||
//           embeddings.includes("day" || embeddings.includes("d"))
//         ) {
//           embed.push(date.getDay());
//         }
//         if (
//           embeddings.includes("hours") ||
//           embeddings.includes("hour" || embeddings.includes("h"))
//         ) {
//           embed.push(date.getHours());
//         }
//         if (
//           embeddings.includes("minutes") ||
//           embeddings.includes("minute" || embeddings.includes("m"))
//         ) {
//           embed.push(date.getMinutes());
//         }

//         inputDataTrack[i] = [...inputDataTrack[i], embed].flat(Infinity);
//         outputDataTrack[i] = new Array(Object.keys(outputs).length).fill(0);
//       });
//     });

//   // #### Feed data respective in timestamps
//   Object.keys(inputs)
//     .sort()
//     .forEach((key, kindex) => {
//       //   inOrder.push(k);
//       inputs[key][0].forEach(
//         (value, vindex) => (inputDataTrack[value][kindex] = inputs[key][1][vindex])
//       );
//     });

//   Object.keys(outputs)
//     .sort()
//     .forEach((key, kindex) => {
//       //   outOrder.push(k);
//       outputs[key][0].forEach(
//         (value, vindex) => (outputDataTrack[value][kindex] = outputs[key][1][vindex])
//       );
//     });

//   // #### Preparing tensor data

//   var xs = Object.keys(inputDataTrack).map(function (key) {
//     return inputDataTrack[key];
//   });

//   var ys = Object.keys(outputDataTrack).map(function (key) {
//     return outputDataTrack[key];
//   });

//   // ys.pop()

//   var data = {
//     xs: xs,
//     ys: ys,
//     xshape: [xs.length, xs[0].length],
//     yshape: [ys.length, ys[0].length],
//   };

//   data.ys.shift();
//   data.ys.push(data.ys[data.ys.length]);

//   // console.log(xs, ys)
//   // console.log('pre training data structure', data);

//   let model = await tensorTrain(data, fitOptions, layers, compile)
//   // console.log('trained model', model)

//   // console.log('post train prediction', model.predict(tf.tensor(data.xs[0], [1,6])).data())

//   return {
//     model: model,
//     order: {
//       inOrder: Object.keys(inputs).sort(),
//       outOrder: Object.keys(outputs).sort(),
//     },
//   };
// }

export async function tensorTrainDynamic(
  modelData,
  currentModel,
  fitOptions = { epochs: 250, callbacks: null },
  layers = [],
  backend = 'webgl',
  drops = { nulls: true, zeroes: false }
) {

  console.log('Column drops', drops)

  // await tf.setBackend('webgpu');
  await tf.setBackend(backend)
  await tf.ready()

  // console.log('Set backend', tf.getBackend())


  // console.log('WEBGL_RENDER_FLOAT32_CAPABLE', tf.ENV.getBool('WEBGL_RENDER_FLOAT32_CAPABLE'))
  // console.log('WEBGL_RENDER_FLOAT32_ENABLED', tf.ENV.getBool('WEBGL_RENDER_FLOAT32_ENABLED'))

  console.log("Training on", tf.getBackend())
  console.log('DataFeed', modelData);


  // console.log(currentModel.compile)

  let data = _toTensorsMatrix(modelData, currentModel.trainingData.embeddings, true, drops)
  console.log('embeddings', currentModel.trainingData.embeddings)
  let model = await TFARCHITECTURES[currentModel.algorithm.algo]
    .trainerFunction(data, fitOptions, layers, currentModel.compile, currentModel.algorithm.algo)

  return {
    model: model,
    tensors: {
      ...data
    }
  };
}


// async function tensorPredict(model, xs, xshape) {
//   return model.predict(tf.tensor2d(xs, xshape)).dataSync();
// }

// export async function tensorPredictDynamic(model, dataDict, embeddings, resultType = 'dict') {

//   // console.log('predicting', model, dataDict)

//   let { inputs, outputs, xOrder, yOrder } = tensorDynamicOrder(dataDict);
//   // var outputs = {};

//   // Object.keys(dataDict).forEach((k) => {
//   //   switch (dataDict[k][2]) {
//   //     case "input":
//   //       inputs[k] = dataDict[k];
//   //       break;
//   //     case "output":
//   //       outputs[k] = dataDict[k];
//   //       break;
//   //     default:
//   //       alert("data stream neither input or output");
//   //   }
//   // });

//   var inputDataTrack = {};
//   var timestamps = new Set()
//   // var outputDataTrack = {};

//   // #### Collect timestamps
//   Object.keys(dataDict)
//     .sort()
//     .forEach((k) => {
//       dataDict[k][0].forEach((t) => {

//         // if(new Date(t).)
//         // if (timestamps[t] === null)
//         //   timestamps[t] = 1
//         // else
//         //   timestamps[t] += 1

//         timestamps.add(t)
//         inputDataTrack[t] = new Array(Object.keys(inputs).length).fill(0);

//         const date = new Date(t);
//         var embed = [];

//         if (
//           embeddings.includes("months") ||
//           embeddings.includes("month") ||
//           embeddings.includes("M")
//         ) {
//           embed.push(date.getMonth());
//         }
//         if (
//           embeddings.includes("days") ||
//           embeddings.includes("day" || embeddings.includes("d"))
//         ) {
//           embed.push(date.getDay());
//         }
//         if (
//           embeddings.includes("hours") ||
//           embeddings.includes("hour" || embeddings.includes("h"))
//         ) {
//           embed.push(date.getHours());
//         }
//         if (
//           embeddings.includes("minutes") ||
//           embeddings.includes("minute" || embeddings.includes("m"))
//         ) {
//           embed.push(date.getMinutes());
//         }

//         inputDataTrack[t] = [...inputDataTrack[t], embed].flat(Infinity);
//         // outputDataTrack[i] = new Array(Object.keys(outputs).length).fill(0);
//       });
//     });

//   // #### Feed data respective in timestamps
//   Object.keys(inputs)
//     .sort()
//     .forEach((k, s) => {
//       //   inOrder.push(k);
//       inputs[k][0].forEach(
//         (i, is) => (inputDataTrack[i][s] = inputs[k][1][is])
//       );
//     });

//   // Object.keys(outputs)
//   //   .sort()
//   //   .forEach((k, s) => {
//   //     //   outOrder.push(k);
//   //     outputs[k][0].forEach(
//   //       (i, is) => (outputDataTrack[i][s] = outputs[k][1][is])
//   //     );
//   //   });

//   // #### Preparing tensor data

//   var xs = Object.keys(inputDataTrack).map(function (key) {
//     return inputDataTrack[key];
//   });

//   var xshape = [xs.length, xs[0].length];

//   let result = Array.from(await model.predict(tf.tensor(xs, xshape)).dataSync());
//   // console.log(result)

//   const resultReshaped = [];
//   while (result.length)
//     resultReshaped.push(result.splice(0, 3));

//   // switch (resultType.toLocaleLowerCase()) {
//   //   case 'arr':
//   //   case 'array':
//   //     return resultReshaped
//   //   case "dict":
//   //   default:
//   let newTStamps = Array.from(timestamps).sort()
//   newTStamps.shift()
//   let dictResult = { timestamps: newTStamps }
//   resultReshaped.forEach(item => {
//     item.forEach((value, index) => {
//       if (dictResult[yOrder[index]] === undefined)
//         dictResult[yOrder[index]] = []
//       else
//         dictResult[yOrder[index]].push(value)
//     })
//   })
//   return dictResult
//   // }
// }

export async function tensorPredictDynamic(currentModel, modelData, resultType = 'dict') {

  const { xs, xshape, yorder, timestamps: timestamps } = _toTensorsMatrix(modelData, currentModel.trainingData.embeddings)

  let new_xs = xs
  let new_xshape = xshape
  let tensorFunction = tf.tensor

  switch (currentModel.algorithm.algo) {
    case 'TF.LSTM':
    case 'TF.GRU':
      new_xs = xs.map((i) => [i])
      new_xshape = [xs.length, 1, xshape[1]]
      tensorFunction = tf.tensor3d
      break
  }

  // const warmupResult = currentModel.modelBinary.predict(tf.zeros(xshape));
  // warmupResult.dataSync();
  // warmupResult.dispose();

  let result = Array.from(await currentModel.modelBinary.predict(tensorFunction(new_xs, new_xshape)).dataSync());
  console.log("tensorPredictDynamic() on", tf.getBackend(), "data length", result.length, 'algorithm', currentModel.algorithm.algo)

  const resultReshaped = [new Array(yorder.length).fill(0)];
  while (result.length)
    resultReshaped.push(result.splice(0, yorder.length));

  let newTStamps = Array.from(timestamps)
  newTStamps.shift()
  newTStamps.push(newTStamps[newTStamps.length]);
  let dictResult = { timestamps: newTStamps }
  resultReshaped.forEach(item => {
    item.forEach((value, index) => {
      if (dictResult[yorder[index]] === undefined)
        dictResult[yorder[index]] = []
      else
        dictResult[yorder[index]].push(value)
    })
  })
  return dictResult
  // }
}


import React, { useEffect, useState, useRef, useCallback, useReducer, Children } from 'react';
import { ToggleButtonGroup, ToggleButton, Button, IconButton, Tooltip, createTheme, ThemeProvider, TextField } from '@mui/material';
import { headStyle, hrStyle, buttonStyle, headStyleInverted, backgroundStyle, lightColor, verticalCenter, colourAccentSecondary } from '../../css/styling';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import OutlinedInput from '@mui/material/OutlinedInput';
import * as API2 from '../states';
import HexagonIcon from '@mui/icons-material/Hexagon';
// import { PanToolOutlined } from '@mui/icons-material';
// import PanToolIcon from '@mui/icons-material/PanTool';
// import DragHandleIcon from '@mui/icons-material/DragHandle';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
// import ClearAllIcon from '@mui/icons-material/ClearAll';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import { RemoveCircleOutline } from '@mui/icons-material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
// import Typography from '@mui/material/Typography';
// import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CircularProgress, {
    circularProgressClasses,
    CircularProgressProps,
} from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import axios from 'axios';

import { useElementOnScreen, Trailer, spanCharacters, spanArray, spanArrayStringEqualSplit, Transitioner } from '../dElements/Animations';

export default function MerlinInsights() {

    const [cityGeneralNews, setCityGeneralNews] = useState(null)
    const [cityEnergyNews, setCityEnergyNews] = useState(null)
    const [cityTrafficNews, setCityTrafficNews] = useState(null)
    const [cityAQualityNews, setCityAQualityNews] = useState(null)

    useEffect(() => {

        const fetchNews = async (prompt, saveState) => {
            const response = await axios.post(
                'https://api.openai.com/v1/chat/completions',
                {
                    model: "gpt-4o",
                    messages: [
                        {
                            role: 'system', content: 'you are a Smart City AI agent providing infomation to users about Birmingham UK. return results in json object with fields heading and content, for use key for subheadings and values for subcontent'
                        },
                        { role: 'user', content: prompt },
                    ],
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${process.env.REACT_APP_OPENAI_KEY}`,
                    },
                }
            );

            console.log(response.data.choices[0].message.content)
            saveState(JSON.parse(response.data.choices[0].message.content.replace('```json', '').replace('```', '')))
        }

        // fetchNews(`use web browsing and find what is the latest news in the last 3 months for Birmingham UK`, setCityGeneralNews)
        // fetchNews(`use web browsing and find what is the latest news related to energy in the last 3 months for Birmingham UK`, setCityEnergyNews)
        // fetchNews(`use web browsing and find what is the latest news related to traffic in the last 3 months for Birmingham UK`, setCityTrafficNews)
        // fetchNews(`use web browsing and find what is the latest news related to air quality in the last 3 months for Birmingham UK`, setCityAQualityNews)

        // fetchNews(`energy policies for Birmingham UK`, setCityEnergyNews)
        fetchNews(`current traffic policies for Birmingham UK`, setCityTrafficNews)
        // fetchNews(`air quality policies for Birmingham UK`, setCityAQualityNews)

    }, [])

    return (<Box className="row" style={{ padding: '10px', ...backgroundStyle, display: 'inline-grid', zIndex: 1, borderRadius: '8px', width: 450, maxHeight: 600, overflowY: 'auto' }}>
        {/* <div>{cityGeneralNews}</div>
        <br /> */}
        {cityTrafficNews &&
            <>
                <div>{cityTrafficNews?.heading}</div>
                {/* <div>{JSON.stringify(cityTrafficNews?.content)}</div> */}
                <div>{Object.keys(cityTrafficNews?.content || {}).map(subhead => <>
                    <div>{subhead}</div>
                    <div>{JSON.stringify(cityAQualityNews?.content[subhead])} lol</div>
                </>)}</div>
            </>
        }
        <br />
        {/* <div>{cityAQualityNews}</div>
        <br />
        <div>{cityEnergyNews}</div> */}
    </Box >
    );
}
import React, { useEffect, useState, useRef, useCallback, useReducer, Children } from 'react';
import { ToggleButtonGroup, ToggleButton, Button, IconButton, Tooltip, createTheme, ThemeProvider, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { headStyle, hrStyle, buttonStyle, headStyleInverted, backgroundStyle, lightColor, verticalCenter, colourAccentSecondary, colourBackground, colourAccent } from '../../css/styling';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import OutlinedInput from '@mui/material/OutlinedInput';
import * as API2 from '../states';
import HexagonIcon from '@mui/icons-material/Hexagon';
// import { PanToolOutlined } from '@mui/icons-material';
// import PanToolIcon from '@mui/icons-material/PanTool';
// import DragHandleIcon from '@mui/icons-material/DragHandle';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
// import ClearAllIcon from '@mui/icons-material/ClearAll';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import { RemoveCircleOutline } from '@mui/icons-material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
// import Typography from '@mui/material/Typography';
// import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CircularProgress, {
    circularProgressClasses,
    CircularProgressProps,
} from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Slider, { SliderThumb } from '@mui/material/Slider';

import { useElementOnScreen, Trailer, spanCharacters, spanArray, spanArrayStringEqualSplit, Transitioner } from '../dElements/Animations';

const ChronoSlider = styled(Slider)({
    color: 'rgba(255,255,255,0.6)',
    height: 4,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        borderRadius: '2px',
        height: 24,
        width: 6,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&::before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 160,
        height: 32,
        // borderRadius: '50% 50% 50% 0',
        backgroundColor: colourAccentSecondary,
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) scale(0)',
        '&::before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, 150%) scale(1)',
        },
        '& > *': {
            // transform: 'rotate(45deg)',
        },
    },
});

export default function InfereceView({ style, children }) {

    const { layersVisibility, updateLayersVisibility } = API2.globalStore()


    return (<div style={{ ...style }}>
        <Box style={{ ...backgroundStyle, width: 'calc(100vw)', height: '34px', paddingTop: '8px' }}>
            <Button variant="contained" className='buttonexotic' style={{ verticalAlign: 'text-bottom', width: '160px', background: colourAccentSecondary, marginLeft: '10px', marginRight: '15px', height: '25px' }}>Range</Button>

            {/* <Slider
                style={{ width: 'calc(50vw)', paddingTop: '2px' }}
                aria-label="Temperature"
                defaultValue={30}
                color={colourAccent}
            // getAriaValueText={'lol'}
            /> */}

            <ChronoSlider
                disabled={true}
                style={{ width: 'calc(50vw)', paddingTop: '2px' }}
                valueLabelDisplay="auto"
                aria-label="pretto slider"
                defaultValue={0}
            />

            {/* <Button variant="contained" className='buttonexotic' style={{ verticalAlign: 'text-bottom', width: '160px', background: colourAccentSecondary, marginLeft: '15px', marginRight: '10px', height: '25px' }}>To</Button> */}
        </Box>
    </div>
    );
}

// export default function CanvasDialog({ className, style }) {
//     return <VerticalLinearStepper style />
// <div className={className} style={{
//     ...backgroundStyle, display: 'inline-grid', zIndex: 1, borderRadius: '8px', minWidth: 550, maxWidth: 550, maxHeight: 600, ...style
// }}>
//         <VerticalLinearStepper />
// </div >
// }
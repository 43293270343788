import axios from "axios"
import OSPoint from "ospoint"
import LNSNimport from './LNSN.json';
import RTEMimport from './RTEM_Coords.json';
import AIRVIROimport from './Airviro_Coords.json';

export const DATASETS = {
    "TRAFFIC":[],
    "AQTX":[]
}

export function SCOOTINFO(callback) {
    console.log('fetching SCOOTINFO')
    const url = "https://bcc.opendata.onl/SCOOT.json?ApiKey=ZWC8DOC2BECFW9CJ9236245026151"
    axios.get(url)
        .then((response) => {
            var tis = response.data.Scoots.kids
            for (var item in tis) {
                tis[item] = tis[item].kids
                tis[item].ID = item
                tis[item].Head = 0
                const point = new OSPoint(tis[item].Northing, tis[item].Easting)
                tis[item].Coords = point.toETRS89()
                // console.log(tis[item])
            }

            // console.log(tis)
            callback(tis)
        })
    // .catch((error) => console.log(error));
}

export function RTEMINFO(callback) {
    console.log('fetching RTEMINFO')
    const url = "https://bcc.opendata.onl/UTMC%20rtem.json?ApiKey=ZWC8DOC2BECFW9CJ9236245026151"
    axios.get(url)
        .then((response) => {
            var tis = response.data.RTEMS.kids
            for (var item in tis) {
                tis[item] = tis[item].kids
                tis[item].ID = item
                tis[item].Head = 0
                const point = new OSPoint(tis[item].Northing, tis[item].Easting)
                tis[item].Coords = point.toETRS89()
                tis[item].SCN.value2 = tis[item].SCN.value
                tis[item].SCN.value = tis[item].SCN.value.replace("D0", "").replace("D1", "").replace("D2", "").replace("D3", "").replace("D4", "")
                // console.log(tis[item])
            }

            // console.log(tis)
            callback(tis)
        })
    // .catch((error) => console.log(error));
}

export function RTEMIMPORT(callback) {
    console.log('fetching RTEMIMPORT')
    callback(RTEMimport)
}

export function AIRVIROIMPORT(callback) {
    console.log('fetching AIRVIROIMPORT')
    callback(AIRVIROimport)
}




function LNSNpreproc(LNSN) {
    var res = []

    // console.log(LNSN)

    Object.keys(LNSN).forEach(sensor => {
        Object.keys(LNSN[sensor]).forEach(range => {
            Object.keys(LNSN[sensor][range]).forEach(conn => {
                res.push(LNSN[sensor][range][conn])
            })
        })
    });

    // console.log(res[0].from.name, res[0].to.name)

    var res2 = []

    res.forEach(d => {
        try {
            // console.log(d)
            if (d.from.name.substring(0, 1) === 'A') {
                d.from.coordinates = [AIRVIROimport[d.from.name.split('_')[0]].long, AIRVIROimport[d.from.name.split('_')[0]].lat]
            }
            else if (d.from.name.substring(0, 1) === 'R') {
                d.from.coordinates = [RTEMimport[d.from.name].long, RTEMimport[d.from.name].lat]
            }

            if (d.to.name.substring(0, 1) === 'A') {
                d.to.coordinates = [AIRVIROimport[d.to.name.split('_')[0]].long, AIRVIROimport[d.to.name.split('_')[0]].lat]
            }
            else if (d.to.name.substring(0, 1) === 'R') {
                d.to.coordinates = [RTEMimport[d.to.name].long, RTEMimport[d.to.name].lat]
            }
            res2.push(d)
        } catch (error) {
            // console.log(error)
        }
    })

    // console.log('res2', res2)

    return res2
}

export function LNSNTEST(callback) {
    console.log('fetching LNSNTEST')
    var res = LNSNpreproc(LNSNimport)

    callback(res)
}

export function INSIGHTSLIST(callback) {
    return {

    }
}


import { BorderColor, FunctionsRounded } from "@mui/icons-material";
import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import {
  headStyle,
  hrStyle,
  buttonStyle,
  headStyleInverted,
  backgroundStyle,
  lightColor,
  verticalCenter,
  colourAccent,
  colourAccentSecondary,
} from "../../css/styling";
import { IconButton, Button, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import HexagonIcon from "@mui/icons-material/Hexagon";
import InfoIcon from "@mui/icons-material/Info";
import TagIcon from "@mui/icons-material/Tag";
import InputIcon from "@mui/icons-material/Input";
import OutputIcon from "@mui/icons-material/Output";
import * as API2 from "../states";
import * as API from "../apis/APIWeb";
import { LineChart } from "@mui/x-charts/LineChart";
import Box from "@mui/material/Box";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import WidthNormalIcon from '@mui/icons-material/WidthNormal';
import {
  Transitioner,
  Transvoider,
  spanArray,
  spanArrayStringEqualSplit,
} from "../dElements/Animations";
import { Trail } from "@react-spring/web";
import { Tooltip, Grid } from "@mui/material";
import { DateTimeRangePickerPopper } from "../dElements/DateTimeRangePicker";
import { tensorPredictDynamic, _toTensorsMatrix } from "../mlmethods/tensorflow";
import { canvasFetchData, canvasFetchModelData } from "../apis/APIWeb";
import DownloadIcon from '@mui/icons-material/Download';
import * as tf from '@tensorflow/tfjs';
import { DButton } from "../dElements/DButton";
import { exportCSV } from "../dElements/ExportCSV";
import DCharts from "../dElements/DCharts";

const widthStates = [400, 600, 800, 1000, 1200, 1400]

const graphHeight = 200

const backTrendColor = ''
const fronTrendColor = ''


const handleSaveJson = (jsonObject, filename) => {

  const jsonString = JSON.stringify(jsonObject, null, 2);
  const blob = new Blob([jsonString], { type: "application/json" });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);

  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

const newDataModifiers = (keys) => {
  let dict = {}
  Object.keys(keys).forEach(k => { dict[k] = { feed: 1.0, cap: "p100" } })
  return dict
}

export default function Analytics({ focusCb }) {
  const currentModel = API2.currentModelStore();
  const { colourAccent, colourAccentSecondary, borderRadius } = API2.globalStore();
  const { canvasMode, setCanvasMode, saveModel } = API2.aiworkflow();
  const [xAxisTimetrack, setXAxisTimetrack] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [width, setWidth] = useState(4)
  const { dataStart, dataEnd } = API2.currentModelStore().trainingData;
  const [dataModifiers, setDataModifiers] = useState({})
  const [predicted, setPredicted] = useState(false);
  const [update, setUpdate] = useState(Date.now());
  const [renderFeedPredicts, setRenderFeedPredicts] = useState(true);

  // const [thisProps, setThisprops] = useState({ node, latitude, longitude, hosted, collection, style })

  const [data, setData] = useState({ inputs: {}, outputs: {}, timestamps: [] });
  const [_data, _setData] = useState({ inputs: {}, outputs: {}, timestamps: [] });
  const [stats, setStats] = useState(null);
  // const [cap, setCap] = useState('p100')


  function getCSVFrame() {
    let csvframe = []

    let inputsKeys = Object.keys(data.inputs), outputKeys = Object.keys(data.outputs), feedKeys = Object.keys(_data.inputs), predictedKeys = Object.keys(_data.outputs)

    csvframe.push(['Timestamp', ...inputsKeys.map(k => k + '.base'), ...outputKeys.map(k => k + '.base'), ...feedKeys.map(k => k + '.feed'), ...predictedKeys.map(k => k + '.predicted')])
    xAxisTimetrack.forEach((v, i) => csvframe.push([
      v.toISOString(),
      ...inputsKeys.map(k => data.inputs[k][0].data[i]),
      ...outputKeys.map(k => data.outputs[k][0].data[i]),
      ...feedKeys.map(k => _data.inputs[k][1]?.data[i]),
      ...predictedKeys.map(k => _data.outputs[k][1]?.data[i]),
    ].flat()))

    // console.log(csvframe)
    return csvframe
  }


  async function predict() {
    let modelData = await canvasFetchModelData(currentModel, startDate, endDate, null)

    Object.keys(_data.inputs).forEach(k => {
      modelData.inputs[k] = _data.inputs[k][1].data
    })

    let result = await tensorPredictDynamic(
      currentModel, modelData
    );

    let newData = { ...data }

    Object.keys(newData.outputs).forEach(k => {
      if (modelData.timestamps.length === result[k].length) {
        newData.outputs[k][0].color = colourAccentSecondary
        newData.outputs[k][1] = {
          curve: "natural",
          data: result[k].map(num => num < 0 ? 0 : num),
          showMark: false,
          color: colourAccent,
          min: 0,
          label: "Predicted"
        }
      }
      else {
        console.log('error charting predictions', k, result[k].length, newData.outputs[k][0].data.length)
      }
    })

    setData(newData)
    setRenderFeedPredicts(true)
    setPredicted(true)
  }

  // const [dataPredictions, setDataPredictions] = useState({ inputs: {}, outputs: {} });

  // const yearFormatter = (date) =>
  //   `${date.getDate().toString()}-${(date.getMonth() + 1).toString()} ${date
  //     .getHours().toString()}:${date.getHours().toString()}`;

  // const yearFormatter = (date) =>
  //   `${(date.getMonth() + 1).toString()}-${date.getDate().toString()} ${date
  //     .getHours().toString()}:${date.getHours().toString()}`;

  useEffect(() => {

    if (startDate == null || endDate == null)
      return;

    canvasFetchModelData(currentModel, startDate, endDate, 0)
      .then(response => {
        console.log('model data fetched', response)
        Object.keys(response.inputs).forEach(k => {
          response.inputs[k] = [
            {
              curve: "natural",
              data: response.inputs[k],
              showMark: false,
              tooltip: "none",
              color: colourAccent,
              min: 0,
              label: "Base"
            }
          ]
        })

        Object.keys(response.outputs).forEach(k => {
          response.outputs[k] = [
            {
              curve: "natural",
              data: response.outputs[k],
              showMark: false,
              tooltip: "none",
              color: colourAccent,
              min: 0,
              label: "Base"
            }
          ]
        })

        // for (let index = 0; index < response.timestamps.length - 1; index++) {
        //   if (response.timestamps[index] >= response.timestamps[index + 1]) {
        //     console.log(index, response.timestamps[index],response.timestamps[index + 1])
        //     alert('error: timestamps non-linear progression')
        //   }
        // }
        // alert()

        _setData(response)
        setData(response)
        setStats(response.stats)
        setPredicted(false)
        setDataModifiers(newDataModifiers(response.inputs))
        setXAxisTimetrack(response.timestamps.map((x) => new Date(x)))
        setTimeout(() => setUpdate(Date.now()), 500)
      })

  }, [currentModel.inputs, currentModel.outputs, startDate, endDate]);

  useEffect(() => {
    if (data) {
      let newData = JSON.parse(JSON.stringify(data))

      Object.keys(dataModifiers).forEach(k => {
        newData.inputs[k][1] = JSON.parse(JSON.stringify(newData.inputs[k][0]))
        // console.log(newData.inputs[k][1].data)
        // console.log(Object.values(dataModifiers))
        newData.inputs[k][1].data = newData.inputs[k][1]?.data
        newData.inputs[k][1].label = 'Feed'
        newData.inputs[k][0].color = colourAccentSecondary
        newData.inputs[k][1].color = colourAccent

        if (dataModifiers[k].cap !== 'p100') {
          let _cap = -1

          switch (dataModifiers[k].cap) {
            case 'p95': _cap = stats[k]['perc_95']; break;
            case 'p80': _cap = stats[k]['perc_80']; break;
          }

          console.log(stats, dataModifiers[k].cap, _cap)

          newData.inputs[k][1].data = (dataModifiers[k].cap === -1 ? newData.inputs[k][1].data : newData.inputs[k][1].data.map((v, i) =>
            v == undefined ? undefined : Math.min(_cap, v)
          ))
        }

        newData.inputs[k][1].data = newData.inputs[k][1]?.data.map(x => x * dataModifiers[k].feed) || data.timestamps.map(x => 0)
      })

      _setData(newData)
    }

    setPredicted(false)
    setTimeout(() => setUpdate(Date.now()), 500)
  }, [JSON.stringify(dataModifiers), JSON.stringify(data)])

  // useEffect(() => {
  //   setPredicted(false)
  //   setTimeout(() => setUpdate(Date.now()), 500)
  // }, [JSON.stringify(dataModifiers)])

  useEffect(() => {
    if (dataStart && dataEnd) {
      setStartDate(dataStart)
      setEndDate(dataEnd)
    }
  }, [])

  useEffect(() => setUpdate(Date.now()), [renderFeedPredicts])

  // useEffect(() => {
  //   if (startDate && endDate) {
  //     setTimeout(() => {
  //       predict().then(() => setPredicted(true))
  //     }, 1000)

  //     console.log('auto predict on range change')
  //   }
  // }, [startDate, endDate])

  const xAxisTimetrackAdjusted = useMemo(() => {
    function subtractHours(date, hours) {
      date.setHours(date.getHours() - hours);
      return date;
    }
    return xAxisTimetrack.map(x => subtractHours(x, 0))
  }, [xAxisTimetrack])

  // const modelPredict = async () => {
  //   console.log('fetching analysis prediction data')
  //   // fetchPredictionData();

  //   // const model = await tf.loadLayersModel(`indexeddb://${id}`);
  //   // console.log('Prediction from loaded model:');
  //   // loadedModel.predict(tf.ones([1, 3])).print();

  //   // console.log('predicting with model')

  //   let result = await tensorPredictDynamic(
  //     currentModel.modelBinary,
  //     {},
  //     embeddings
  //   );

  //   setPredictionResults(result)
  //   console.log(result)
  // }


  return (<Box
    style={{
      ...backgroundStyle,
      zIndex: 1,
      borderRadius: borderRadius,
      maxHeight: "calc(100vh - 140px)",
      width: `${widthStates[width]}px`,
      padding: '2px'
    }}
  >

    {/* <Box
      style={{
        ...backgroundStyle,
        zIndex: 1,
        borderRadius: "8px",
        maxHeight: "calc(100vh - 110px)",
        ...style,
      }}
    > */}

    <DragIndicatorIcon
      style={{
        position: "absolute",
        cursor: "grab",
        zIndex: 2,
        marginTop: "10px",
        marginLeft: `calc( ${widthStates[width]}px - 25px)`,
      }}
    />

    {/* <Tooltip title="metadata">
      <IconButton
        style={{
          position: "absolute",
          zIndex: 2,
          marginTop: "10px",
          marginLeft: "325px",
          fontSize: "14px",
          borderRadius: 0,
          padding: 0,
          color: showMeta ? "white" : "grey",
        }}
        onClick={() => {
          setShowMeta(!showMeta);
          // focusCb({
          //     center: [longitude, latitude],
          //     essential: true,
          //     duration: 2000,
          //     zoom: 18,
          //     pitch: 65,
          // })
        }}
      >
        <TagIcon />
      </IconButton>
    </Tooltip> */}

    {/* <IconButton
      className="buttonexotic"
      style={{
        position: "absolute",
        zIndex: 2,
        marginTop: "10px",
        marginLeft: `calc( ${widthStates[width]}px - 50px)`,
        fontSize: "14px",
        borderRadius: 0,
        padding: 0,
        color: "white",
      }}
      onClick={() => {
        setWidth(width >= 3 ? 0 : width + 1)
      }}
    >
      <WidthNormalIcon />
    </IconButton> */}


    {/* <IconButton
      className="buttonexotic"
      style={{
        position: "absolute",
        zIndex: 2,
        marginTop: "10px",
        marginLeft: `calc( ${widthStates[width]}px - 50px)`,
        fontSize: "14px",
        borderRadius: 0,
        padding: 0,
        color: "white",
      }}
      onClick={() => {
        focusCb({
          center: [longitude, latitude],
          essential: true,
          duration: 2000,
          zoom: 16,
          // pitch: 65,
        });
      }}
    >
      <CenterFocusStrongIcon />
    </IconButton> */}


    {/* <Box style={{ paddingLeft: '15px', paddingTop: '10px', paddingBottom: '0px' }}> */}
    {/* COLLECTION: xx.xxxxxxxxxx
                <br /> */}

    {/* <span style={{ opacity: 0.4 }}>UPDATED:</span> {thisProps.node.last_update}
                    <br /> */}
    {/* <Trail open={!metaVisible}> */}

    {/* <Transvoider open={true} behaviour={{ x: 0, y: 0, height: 18, config: { duration: 100 } }}>
                <div><span style={{ opacity: 0.4 }}>LATEST: </span>{thisProps?.node?.meta?.last_update?.replace('.000Z', '').replace('T', ' ') || '---'}</div>
                <div><span style={{ opacity: 0.4 }}>COLL.: </span>{thisProps?.collection?.toUpperCase()}</div>
                <div><span style={{ opacity: 0.4 }}>ASSET: </span>{thisProps?.node?.asset_id}</div>
            </Transvoider > */}

    {/* <Transvoider open={showMeta} behaviour={{ x: !showMeta ? 0 : 5, height: showMeta ? 18 : 0 }} behaviourConfig={{ duration: 80 }}>
                <div style={{ opacity: 0.6 }}><span style={{ opacity: 0.4 }}>LAT.:</span> {thisProps?.latitude}</div>
                <div style={{ opacity: 0.6 }}><span style={{ opacity: 0.4 }}>LONG.:</span> {thisProps?.longitude}</div>
                {thisProps.node?.meta && Object.keys(thisProps.node.meta).map(k => k !== 'last_update' && <div style={{ opacity: 0.6 }} > <span style={{ opacity: 0.4 }}> {k.toUpperCase()}:</span> {`${thisProps.node.meta[k]}`.toUpperCase()}</div>)}
            </Transvoider > */}

    {/* <Transvoider open={true} behaviour={{ x: 0, height: 18, config: { duration: 100 } }}>
                <div><span style={{ opacity: 0.4 }}>TIMESEG:</span> 24(1h)</div >
            </Transvoider > */}

    {/* </Box > */}

    {/* < Box style={{ textAlign: 'right', paddingBottom: '4px', width: '100%', marginTop: '-10px' }}>
            <IconButton disabled style={{ color: 'white', fontSize: '14px' }} >Gran. </IconButton>
            <IconButton disabled style={{ color: colourAccentSecondary, fontSize: '14px' }} onClick={() => getData(1, setTimeframe)}>15m </IconButton>
            <IconButton disabled style={{ color: colourAccent, fontSize: '14px' }} onClick={() => getData(1, setTimeframe)}>1h </IconButton>
            <IconButton disabled style={{ color: colourAccentSecondary, fontSize: '14px' }} onClick={() => getData(2, setTimeframe)}>3h </IconButton>
            <IconButton style={{ color: colourAccent, fontSize: '14px' }} onClick={() => getData(2, setTimeframe)}>6h </IconButton>
            <IconButton disabled style={{ color: colourAccentSecondary, fontSize: '14px', paddingRight: '15px' }} onClick={() => getData(24, setTimeframe)}>24h </IconButton>
            <IconButton style={{ color: colourAccent, fontSize: '18px' }} onClick={() => getData(6)}>4h </IconButton>
            <IconButton style={{ color: colourAccent, fontSize: '18px' }} onClick={() => getData(4)} >6h </IconButton>
        </Box > */}

    {/* {JSON.stringify(data,null,2)} */}

    <Box
      style={{
        overflowY: "hidden",
        overflowX: "hidden",
        // maxHeight: '100%',
        // position: 'relative',
        // background: 'rgba(255, 255, 255, 0.8)',
        background: "rgb(31 31 31 / 40%)",
        // paddingLeft: "15px",
        // paddingRight: '15px',
        color: colourAccent,
        borderTopLeftRadius: "5px",
        borderBottomLeftRadius: "5px",
        // height: "calc(90vh - 110px - 140px)",

        // paddingTop: "10px",
      }}
    >
      <Box style={{ background: 'rgba(51, 51, 51, 0.99)' }}>
        <IconButton style={{
          color: 'white',
          paddingLeft: '15px',
          paddingRight: '15px',
          // fontWeight: "bold"
          fontSize: 'max(0.5vh, 12px)',
          // background: 'white',
          paddingBottom: 10,

          // fontSize: "12px",
          // background: "white",
          borderRadius: "0px",
          // width: "calc(20%)",
        }}
          onClick={() => setCanvasMode('canvas')}
        >
          <span
            style={{
              // fontWeight: "normal",
              fontSize: 'max(0.5vh, 12px)',
              // marginTop: "5px",
              // marginBottom: "-15px",
              // paddingBottom: "20px",
              color: 'grey'
            }}>NEXUS: </span>
          {currentModel.name} [{currentModel.id.slice(0, 6)}...]
        </IconButton>

        <IconButton style={{
          color: 'white',
          paddingLeft: '15px',
          paddingRight: '15px',
          // fontWeight: "bold"
          fontSize: 'max(0.5vh, 12px)',
          // background: 'white',
          paddingBottom: 10,

          // fontSize: "12px",
          // background: "white",
          borderRadius: "0px",
          // width: "calc(20%)",
        }}
          onClick={() => setCanvasMode('canvas')}
        >
          <span
            style={{
              // fontWeight: "normal",
              fontSize: 'max(0.5vh, 12px)',
              // marginTop: "5px",
              // marginBottom: "-15px",
              // paddingBottom: "20px",
              color: 'grey'
            }}> STATUS: </span>
          {currentModel.modelBinary ? "TRAINED" : 'UNTRAINED'}
        </IconButton>

        <IconButton style={{
          color: 'white',
          paddingLeft: '15px',
          paddingRight: '15px',
          fontSize: 'max(0.5vh, 12px)',
          paddingBottom: 10,
          borderRadius: "0px",
          // float: 'left'
        }}
          onClick={() => {
            currentModel.setDataStart(startDate);
            currentModel.setDataEnd(endDate);
          }}
        >
          <span
            style={{
              // fontWeight: "normal",
              fontSize: 'max(0.5vh, 12px)',
              // marginTop: "5px",
              // marginBottom: "-15px",
              // paddingBottom: "20px",
              color: 'white'
            }}> SET FOR TRAINING </span>

        </IconButton>



        <span style={{ width: '10%', display: 'inline-flex' }} />

        <IconButton style={{
          color: 'white',
          paddingLeft: '15px',
          marginRight: '50px',
          fontSize: 'max(0.5vh, 12px)',
          paddingBottom: 10,
          borderRadius: "0px",
          float: 'right'
        }}
          onClick={async () => {
            saveModel(currentModel)
          }}
        >
          <span
            style={{
              // fontWeight: "normal",
              fontSize: 'max(0.5vh, 12px)',
              // marginTop: "5px",
              // marginBottom: "-15px",
              // paddingBottom: "20px",
              color: 'white'
            }}> EXPORT MODEL </span>

        </IconButton>


        <IconButton style={{
          color: 'white',
          paddingLeft: '15px',
          paddingRight: '15px',
          fontSize: 'max(0.5vh, 12px)',
          paddingBottom: 10,
          borderRadius: "0px",
          float: 'right'
        }}
          onClick={async () => {
            exportCSV(getCSVFrame(), `${currentModel.name}`)
          }}
        >
          <span
            style={{
              // fontWeight: "normal",
              fontSize: 'max(0.5vh, 12px)',
              // marginTop: "5px",
              // marginBottom: "-15px",
              // paddingBottom: "20px",
              color: 'white'
            }}> EXPORT CSV </span>

        </IconButton>


        {/* <IconButton style={{
          color: 'white',
          paddingLeft: '15px',
          paddingRight: '15px',
          fontSize: 'max(0.5vh, 12px)',
          paddingBottom: 10,
          borderRadius: "0px",
        }}
          onClick={async () => {
            saveModel(currentModel.id)
          }}
        >
          <span
            style={{
              // fontWeight: "normal",
              fontSize: 'max(0.5vh, 12px)',
              // marginTop: "5px",
              // marginBottom: "-15px",
              // paddingBottom: "20px",
              color: 'white'
            }}> Set Training Data </span>

        </IconButton> */}


        {/* <div
        style={{
          fontWeight: "bold",
          marginTop: "5px",
          marginBottom: "-25px",
          paddingBottom: "20px",
        }}
      >
        {" "}
        {currentModel.name}
      </div> */}

        {/* {JSON.stringify(data)} */}

        {/* <br /> */}

        <Box>

          <DateTimeRangePickerPopper
            range={true}
            btnSpanWidth={10}
            closeBtnStyle={{
              color: "white",
              fontSize: "12px",
              background: colourAccentSecondary,
              borderRadius: "2px",
              borderColor: colourAccentSecondary,
              width: "100px",
              display: "flex",
              padding: 0,
              margin: 0,

            }}
            btnStyle={{
              color: "white",
              fontSize: "12px",
              // background: "darkcyan",
              background: "rgba(80,80,80,0.2)",
              borderRadius: "0px",
              width: "calc(74%)",
              padding: 0
              // marginLeft: '-15px'
            }}
            startDate={`[ ${startDate ? startDate : 'pick date'} ]`}
            endDate={`[ ${endDate ? endDate : 'pick date'} ]`}
            onChange={(range) => {
              // alert(JSON.stringify(range[0]))
              setStartDate(range[0].startDate.toISOString().split('T')[0])
              setEndDate(range[0].endDate.toISOString().split('T')[0])
            }}
            closeBtnClick={() => {
              setRenderFeedPredicts(false)
              setUpdate(Date.now())
            }}
          />

          {/* <IconButton style={{
            color: "white",
            fontSize: "12px",
            background: "grey",
            borderRadius: "0px",
            width: "calc(25%)",
            opacity: startDate === dataStart && endDate === dataEnd ? 0.4 : 1.0
            // marginLeft: '-15px'
          }}
            onClick={() => {
              currentModel.setDataStart(startDate);
              currentModel.setDataEnd(endDate);
            }}
          >Set Training Data</IconButton> */}


          <DButton style={{
            // color: "white",
            // fontSize: "12px",
            // background: "darkcyan",
            // borderRadius: "0px",
            width: "calc(24%)",
            // marginLeft: '-15px'
          }}
            onClick={() => {
              setStartDate(dataStart)
              setEndDate(dataEnd)
            }}
          >View Training Data</DButton>


        </Box>
      </Box>


      {data?.invalid ? <div style={{ color: 'orange', padding: 10 }}>
        INVALID RANGE
        {data.invalid.map(x => <div>{x}</div>)}
      </div> :

        <>
          <Grid container style={{ background: 'rgba(0,0,0,0.1)', textAlign: '-webkit-center', padding: '10px' }}>
            <Grid item xs={5}>
              <h2 style={{
                fontSize: '14px',
                color: colourAccent,
                textAlign: 'center',
                paddingTop: '10px',
                paddingBottom: '10px',
                marginTop: 0,
                marginBottom: '0px',
                borderBottomStyle: 'solid',
                borderColor: colourAccentSecondary,
                width: '80%'
              }}>Inputs {Object.keys(data.inputs).length}/{Object.keys(currentModel.inputs).length}</h2>
            </Grid>

            <Grid item xs={2} />

            <Grid item xs={5} >
              <h2 style={{
                fontSize: '14px',
                color: colourAccent,
                textAlign: 'center',
                paddingTop: '10px',
                paddingBottom: '10px',
                marginTop: 0,
                marginBottom: '0px',
                borderColor: colourAccentSecondary,
                borderBottomStyle: 'solid',
                width: '80%'
              }}>Outputs {Object.keys(data.outputs).length}/{Object.keys(currentModel?.outputs)?.length}</h2>
            </Grid>

          </Grid>




          {/* <DateTimeRangePickerPopper range={false} /> */}

          {/* <span>
        <div>{JSON.stringify(startDate)}</div>
        <div>{JSON.stringify(endDate)}</div>
      </span> */}

          {/* {JSON.stringify(Object.keys(data))} */}


          <Box
            style={{
              overflowY: 'auto',
              overflowX: 'hidden',
              // height: "calc(90vh - 110px - 140px)",
              maxHeight: "calc(90vh - 200px)",
              paddingLeft: '15px'
            }}>



            {!data?.invalid && <Grid container spacing={2}>
              {width >= 2 && <>
                <Grid item xs={currentModel.modelBinary ? 5 : 6}>
                  <div style={{ paddingTop: "10px" }}>

                    {data?.inputs && data.timestamps?.length > 0 && Object.keys(data.inputs).map(
                      (v, i) =>
                        <div style={{}}>
                          {/* <Grid container>
                      <Grid item xs={9}> */}
                          {/* <div
                          style={{
                            // textAlign: 'center',
                            fontWeight: "bold",
                            marginTop: "5px",
                            marginBottom: "-25px",
                            paddingTop: '10px'
                          }}
                        >
                          {" "}
                          {v.toUpperCase()}
                        </div> */}
                          {/* </Grid>
                      <Grid item xs={2}> */}
                          {/* <DButton style={{ width: '10px', float: 'right', marginBottom: "-25px", }}>CAP</DButton> */}
                          {/* </Grid>
                      <Grid item xs={1} />
                    </Grid> */}
                          {data.inputs[v][0]?.data?.length === data.timestamps?.length &&

                            <DCharts
                              style={{ height: graphHeight }}
                              id={`${v}-dchart`}
                              title={v.toUpperCase()}
                              stats={stats?.[v]}
                              datetimeArray={xAxisTimetrackAdjusted}
                              dataArray={renderFeedPredicts ? [...Object.values(_data.inputs[v]).map(v => v.data)] : [_data.inputs[v][0].data]}
                              colors={renderFeedPredicts ? [colourAccentSecondary, colourAccent] : [colourAccent]}
                              updateClear={renderFeedPredicts}
                              labels={['Base', 'Feed']}
                              update={update} >

                              {currentModel.modelBinary && <Grid container
                                // spacing={2}
                                style={{ width: '100%', alignItems: 'center', paddingBottom: '10px' }}>

                                {/* <Grid item xs={1} /> */}

                                <Grid item xs={2}>
                                  <Typography style={{ textAlign: 'center', fontSize: '10px' }}>Feed Trim </Typography>
                                </Grid>

                                <Grid item xs={1}>
                                  <DButton active={dataModifiers[v].cap === 'p80'} style={{ fontSize: '10px', height: '26px', width: '2.5vw' }} onClick={() => { let x = dataModifiers; x[v].cap = 'p80'; setDataModifiers(x) }}>P80</DButton>
                                </Grid>

                                <Grid item xs={1}>
                                  <DButton active={dataModifiers[v].cap === 'p95'} style={{ fontSize: '10px', height: '26px', width: '2.5vw' }} onClick={() => { let x = dataModifiers; x[v].cap = 'p95'; setDataModifiers(x) }}>P95</DButton>
                                </Grid>

                                <Grid item xs={1} style={{ paddingLeft: 0 }}>
                                  <DButton active={dataModifiers[v].cap === 'p100'} style={{ fontSize: '10px', height: '26px', width: '2.5vw' }} onClick={() => { let x = dataModifiers; x[v].cap = 'p100'; setDataModifiers(x) }}>P100</DButton>
                                </Grid>


                                <Grid item xs={0.5} />


                                <Grid item xs={2}>
                                  <Typography style={{ textAlign: 'center', fontSize: '10px' }}>Feed Modifier </Typography>
                                </Grid>

                                <Grid item xs={1} style={{ paddingRight: 0 }}>
                                  <DButton
                                    disabled={dataModifiers[v].feed.toFixed(1) == 1.0}
                                    style={{ fontSize: '10px', height: '26px', width: '2.5vw' }}
                                    onClick={() => { let x = dataModifiers; x[v].feed = 1; setDataModifiers(x) }}>Reset</DButton>
                                </Grid>

                                <Grid item xs={1}>
                                  <DButton style={{ fontSize: '10px', height: '26px', width: '2.5vw' }} onClick={() => { let x = dataModifiers; x[v].feed -= 0.1; setDataModifiers(x) }}>-10%</DButton>
                                </Grid>

                                <Grid item xs={1}>
                                  <div style={{ color: colourAccent, textAlign: 'center', fontSize: '10px', width: '2.5vw' }}>{dataModifiers[v].feed.toFixed(1)} </div>
                                </Grid>

                                <Grid item xs={1} style={{ paddingLeft: 0 }}>
                                  <DButton style={{ fontSize: '10px', height: '26px', width: '2.5vw' }} onClick={() => { let x = dataModifiers; x[v].feed += 0.1; setDataModifiers(x) }}>+10%</DButton>
                                </Grid>


                                <Grid item xs={0.5} />


                          


                                {/* <Grid item xs={3}>
                        <div style={{ textAlign: 'center' }}>Cap Modifier </div>
                      </Grid>

                      <Grid item xs={2} style={{ paddingRight: 0 }}>
                        <DButton disabled={dataModifiers[v].feed.toFixed(1) == 1.0} onClick={() => { let x = dataModifiers; x[v].highcap = 100; setDataModifiers(x) }}>100</DButton>
                      </Grid>

                      <Grid item xs={2} style={{ paddingRight: 0 }}>
                        <DButton disabled={dataModifiers[v].feed.toFixed(1) == 1.0} onClick={() => { let x = dataModifiers; x[v].highcap = 95; setDataModifiers(x) }}>95</DButton>
                      </Grid>

                      <Grid item xs={2} style={{ paddingRight: 0 }}>
                        <DButton disabled={dataModifiers[v].feed.toFixed(1) == 1.0} onClick={() => { let x = dataModifiers; x[v].highcap = 80; setDataModifiers(x) }}>80</DButton>
                      </Grid> */}

                              </Grid>}

                            </DCharts>

                            // <LineChart
                            //   xAxis={[
                            //     {
                            //       data: xAxisTimetrackAdjusted,
                            //       scaleType: "time",
                            //       valueFormatter: API._yearFormatter,
                            //     },
                            //   ]}
                            //   series={_data.inputs[v]}
                            //   // width={350}
                            //   height={graphHeight}
                            //   // loading={true}
                            //   // slots={{ legend: { display: false } }}

                            //   sx={{
                            //     //change left yAxis label styles
                            //     "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                            //       strokeWidth: "0.4",
                            //       fill: colourAccent,
                            //     },
                            //     // change all labels fontFamily shown on both xAxis and yAxis
                            //     "& .MuiChartsAxis-tickContainer .MuiChartsAxis-tickLabel": {
                            //       //    fontFamily: "Roboto",
                            //     },
                            //     // change bottom label styles
                            //     "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                            //       strokeWidth: "0.5",
                            //       fill: colourAccent,
                            //     },
                            //     // bottomAxis Line Styles
                            //     "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
                            //       stroke: colourAccent,
                            //       strokeWidth: 0.4,
                            //     },
                            //     "& .MuiChartsAxis-root .MuiChartsAxis-tick": {
                            //       stroke: colourAccent,
                            //       strokeWidth: 0.4,
                            //     },
                            //     // leftAxis Line Styles
                            //     "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                            //       stroke: colourAccent,
                            //       strokeWidth: 0.4,
                            //     },
                            //   }}
                            //   slotProps={{
                            //     legend: { hidden: true },
                            //     // tooltip: {
                            //     //   valueFormatter: (value) => {
                            //     //     const adjustedTime = new Date(value);
                            //     //     return format(adjustedTime, 'yyyy-MM-dd HH:mm'); // Format for tooltip
                            //     //   }
                            //     // }
                            //   }}
                            // />
                          }



                        </div>
                    )}

                  </div>
                </Grid>
                {currentModel.modelBinary && <Grid item xs={2} >
                  <div style={{
                    position: 'absolute',
                    // marginLeft: 'calc(5 /12 * 100%)',
                    // marginRight: 'calc(5 /12 * 100%)',
                    width: "calc(2 /12 * 100%)",
                    height: '100%',
                    // background: 'linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.05) , rgba(0,0,0,0.02) , rgba(180,180,180,0))',
                    // padding: 10
                  }}>


                    {!(startDate === null || endDate === null || !currentModel.modelBinary) &&
                      <IconButton
                        disabled={predicted}
                        style={{
                          color: predicted ? colourAccentSecondary : colourAccent,
                          fontSize: "12px",
                          background: "rgba(80,80,80,0.6)",
                          borderRadius: "3px",
                          top: '10px',
                          width: "80%",
                          left: '10%',
                          // aspectRatio: '1/1',
                          borderColor: predicted ? colourAccentSecondary : colourAccent,
                          borderWidth: '2px',
                          borderStyle: 'solid',
                          opacity: predicted ? 1.0 : 1.0,
                          // position: 'relative', 
                        }}
                        onClick={() => {
                          predict()
                        }}>Predict Outputs</IconButton>}

                    <DButton
                      style={{ margin: 0, padding: 0, top: '20px', width: '80%', position: 'relative', left: '10%' }}
                      active={!renderFeedPredicts}
                      onClick={() => {
                        setRenderFeedPredicts(!renderFeedPredicts)
                      }}>SHOW BASE ONLY</DButton>

                    {/* <DButton
                      style={{ margin: 0, padding: 0, top: '20px', width: '80%', position: 'relative', left: '10%' }}
                      active={false}
                      onClick={() => {
                        setUpdate(Date.now());
                      }}>REFRESH GRAPH</DButton> */}



                  </div>
                </Grid>}
                <Grid item xs={currentModel.modelBinary ? 5 : 6}>
                  {data?.outputs && data.timestamps?.length > 0 && Object.keys(data.outputs).map(
                    (v, i) =>
                      <div style={{ paddingTop: "10px" }}>
                        {data.outputs[v][0]?.data?.length === data.timestamps?.length &&
                          <DCharts style={{ height: graphHeight }}
                            id={`${v}-dchart`}
                            title={v.toUpperCase()}
                            stats={stats?.[v]}
                            datetimeArray={xAxisTimetrackAdjusted}
                            dataArray={renderFeedPredicts ? [...Object.values(_data.outputs[v]).map(v => v.data)] : [_data.outputs[v][0].data]}
                            colors={renderFeedPredicts ? [colourAccentSecondary, colourAccent] : [colourAccent]}
                            labels={['Base', 'Predicted']}
                            updateClear={renderFeedPredicts}
                            blur={!predicted && currentModel.modelBinary && renderFeedPredicts ? '2px' : '0px'}
                            update={update} />

                          // <LineChart
                          //   xAxis={[
                          //     {
                          //       data: xAxisTimetrackAdjusted,
                          //       scaleType: "time",
                          //       valueFormatter: API._yearFormatter,
                          //     }
                          //   ]}
                          //   series={_data.outputs[v]}
                          //   // width={350}
                          //   height={graphHeight}
                          //   sx={{
                          //     //change left yAxis label styles
                          //     "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                          //       strokeWidth: "0.4",
                          //       fill: colourAccent,
                          //     },
                          //     // change all labels fontFamily shown on both xAxis and yAxis
                          //     "& .MuiChartsAxis-tickContainer .MuiChartsAxis-tickLabel": {
                          //       //    fontFamily: "Roboto",
                          //     },
                          //     // change bottom label styles
                          //     "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                          //       strokeWidth: "0.5",
                          //       fill: colourAccent,
                          //     },
                          //     // bottomAxis Line Styles
                          //     "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
                          //       stroke: colourAccent,
                          //       strokeWidth: 0.4,
                          //     },
                          //     "& .MuiChartsAxis-root .MuiChartsAxis-tick": {
                          //       stroke: colourAccent,
                          //       strokeWidth: 0.4,
                          //     },
                          //     // leftAxis Line Styles
                          //     "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                          //       stroke: colourAccent,
                          //       strokeWidth: 0.4,
                          //     },
                          //   }}
                          //   slotProps={{ legend: { hidden: true } }}

                          // />

                        }

                        <div style={{ height: "5px", width: "100%" }} />
                      </div>
                  )}
                </Grid>
              </>}


              {width < 2 && <>
                <Grid item xs={12}>
                </Grid>
              </>}


            </Grid>}

          </Box>

          {/* </Transvoider> */}
          <div style={{ height: "20px", width: "100%" }} />
        </>}
    </Box>



    {/* {currentModel?.id && JSON.stringify(data)} */}

    {/* lol */}
  </Box >
  );
}

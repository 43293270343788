import React, { useEffect, useState, useRef, useCallback } from 'react';
import { headStyle, cardStyle, hrStyle, buttonStyle, headStyleInverted, backgroundStyle, lightColor, verticalCenter } from '../../css/styling';
import { Button, IconButton, Input } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import * as API2 from '../states';

const CASESLIST = [

    // { id: "FFLOWAPPOINT", title: "Freeflow Appointments", description: "Find appointment slots at the least congested times." },
    // { id: "FFLOWDELIVER", title: "Freeflow Delivery", description: "Find delivery routes at the least congested times." },
    { id: "FFLOW", title: "Freeflow", description: "Find appointment slots and delivery routes at the least congested times.", src: 'https://freeflow-416311.ew.r.appspot.com/' },
    { id: "GREENROUTE", title: "Greenroutes", description: "Find routes based on different green metrics.", src: 'https://walkruncycle.ew.r.appspot.com/' },

]

const CASESLISTINTEGRATED = [
    { id: "AICANVAS", title: "AI Canvas", description: "Build machine learning models with our integrated AI Canvas on web application." }
]

const card = (v) => <>
    <div>
        <Typography variant="h5" gutterBottom>
            {v.title}
        </Typography>
        <Typography style={{ padding: '5px' }} sx={{ fontSize: 14 }} component="div">
            {v.description}
        </Typography>
        <Typography style={{ padding: '5px' }} variant="h7" color="text.secondary" component="div">
            {v.keywords}
        </Typography>
    </div>
</>

function Cases() {

    const [insights, setInsights] = useState(null);
    const [filter, setFilter] = useState("");
    const setCanvasMode = API2.aiworkflow().setCanvasMode;
    const setWindowOpen = API2.globalStore().setWindowOpen;

    return <>
        <Box sx={{ width: '100%', textAlign: '-webkit-center' }}>
            {/* <input className='tfieldStyle' style={{...cardStyle}} onChange={(e) => { setFilter(e.target.value) }} /> */}
            {/* <TextField id="filled-basic" label="Filled" variant="filled" /> */}
            {/* <div>FILTER
                <input className='tfieldStyle' style={{ margin: '30px' }} id="standard-basic" label="Keyword Filter" variant="standard"
                    onChange={(e) => { setFilter(e.target.value) }} />
            </div> */}

            <div style={{ overflowY: 'auto', height: 'calc(100vh - 35vh)',maxWidth: 800, textAlign:'right' }}>
                <div style={{paddingRight:'40px'}}>FEATURED</div>
                <div className='buttonexotic' style={{ ...cardStyle, marginBottom: '20px', maxWidth: 800, minWidth: 275 }} onClick={() => {
                    setCanvasMode(true);
                    setWindowOpen(null);
                }} variant="outlined"  >{card(CASESLISTINTEGRATED[0])}</div>
                <div style={{paddingRight:'40px'}}>SPECIFIC</div>
                {CASESLIST.map((v,i) => {
                    if (filter === "" || v.keywords.toLowerCase().includes(filter.toLowerCase()))
                        return <div key={i} className='buttonexotic' style={{ ...cardStyle, marginBottom: '20px', maxWidth: 800, minWidth: 275 }} onClick={() => setInsights(v)} variant="outlined"  >{card(v)}</div>
                }
                )}

            </div>
        </Box>

        {/* <Button onClick={() => setInsights(INSIGHTSLIST[0])}>ULEZ Compliance</Button> */}

        {insights ?
            <div style={{ ...backgroundStyle, position: 'fixed', top: '60px', left: '0px', height: '100vh', width: '100%', right: '60px' }}>
                <iframe id='iframecurrent' src={insights.src} style={{ width: 'calc(100% - 55px)', height: 'calc(100% - 80px)', padding: '10px', marginRight: '60px', border: 'none', borderRadius: '15px' }}></iframe>
                <IconButton style={{ color: 'red', borderRadius: '1px', right: '4px', top: '10px', zIndex: 10000, position: 'absolute' }} onClick={() => setInsights(null)} >
                    <CloseIcon />
                </IconButton>

                {/* <Button >Snp</Button>
                <IconButton
                    style={{ color: 'red', borderRadius: '1px', right: '4px', top: '10px', zIndex: 10000, position: 'absolute' }}
                    onClick={() => {
                        var c = document.getElementById('iframecurrent');
                        var t = c.getContext('2d');
                        window.open('', document.getElementById('the_canvas_element_id').toDataURL());
                    }}>
                    <ScreenshotMonitorIcon />
                </IconButton> */}

            </div> : null}

    </>
}

export default Cases;
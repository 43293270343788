import { FunctionsRounded } from "@mui/icons-material";
import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  headStyle,
  hrStyle,
  buttonStyle,
  headStyleInverted,
  backgroundStyle,
  lightColor,
  verticalCenter,
  colourAccent,
  colourAccentSecondary,
} from "../../css/styling";
import { IconButton, Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import HexagonIcon from "@mui/icons-material/Hexagon";
import InfoIcon from "@mui/icons-material/Info";
import TagIcon from "@mui/icons-material/Tag";
import InputIcon from "@mui/icons-material/Input";
import OutputIcon from "@mui/icons-material/Output";
import * as API2 from "../states";
import * as API from "../apis/APIWeb";
import { LineChart } from "@mui/x-charts/LineChart";
import { BarChart } from "@mui/x-charts/BarChart";
import Box from "@mui/material/Box";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  Transitioner,
  Transvoider,
  spanArray,
  spanArrayStringEqualSplit,
} from "../dElements/Animations";
import { Trail } from "@react-spring/web";
import { Tooltip } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimeRangePickerPopper } from "../dElements/DateTimeRangePicker";
import dayjs from "dayjs";
import { DButton } from "../dElements/DButton";
import DCharts from "../dElements/DCharts";
import { canvasFetchModelData } from "../apis/APIWeb.js";
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import { DTooltip } from "../dElements/DTooltip.js";

const graphHeight = 150


export default function AssetInfo({
  node,
  latitude,
  longitude,
  hosted,
  // collection,
  style,
  focusCb,
}) {
  const canvasMode = API2.aiworkflow().canvasMode;
  const modelProgress = API2.aiworkflow().modelProgress;
  const { showMeta, setShowMeta, borderRadius } = API2.globalStore();
  const currentModel = API2.currentModelStore();
  const addFeature = API2.currentModelStore().addFeature;
  const removeFeature = API2.currentModelStore().removeFeature;
  const [timeframe, setTimeframe] = useState(288);
  const [graphDate, setGraphDate] = useState(null);
  const { setFocusNode } = API2.aiworkflow();

  const [update, setUpdate] = useState(Date.now())

  const [thisProps, setThisprops] = useState({
    node,
    latitude,
    longitude,
    hosted,
    // collection,
    style,
  });

  const [data, setData] = useState([
    API2.DUMMYDATA("DUMMYDATA1", 288),
    API2.DUMMYDATA("DUMMYDATA2", 288),
    API2.DUMMYDATA("DUMMYDATA3", 288),
    API2.DUMMYDATA("DUMMYDATA4", 288),
  ]);

  const [stats, setStats] = useState(null)


  // const DUMMY1 = API2.DUMMYDATA("DUMMYDATA1", 288);
  // const DUMMY2 = API2.DUMMYDATA("DUMMYDATA2", 288);
  // const DUMMY3 = API2.DUMMYDATA("DUMMYDATA3", 288);
  // const DUMMY4 = API2.DUMMYDATA("DUMMYDATA4", 288);
  // const DUMMYSET = [DUMMY1, DUMMY2, DUMMY3, DUMMY4];

  const getData = (timeframe, callback) => {
    const newdata = [
      API2.DUMMYDATA("DUMMYDATA1", timeframe),
      API2.DUMMYDATA("DUMMYDATA2", timeframe),
      API2.DUMMYDATA("DUMMYDATA3", timeframe),
      API2.DUMMYDATA("DUMMYDATA4", timeframe),
    ];
    setData(newdata);
    callback && callback(timeframe);
  };

  const subTitleCharStyle = {
    paddingBottom: "5vh",
    fontSize: "3vh",
    textShadow: "0 0 2px #1e1e1e",
    textAlign: "center",
  };

  const bannerStreamsStyle = {
    fontWeight: "bold",
    marginTop: "0px",
    background: 'linear-gradient(to right, rgba(255,255,255,0), rgba(0,0,0,0.2) , rgba(255,255,255,0))',
    textAlign: 'center',
    padding: '5px'
  }
  // const yearFormatter = (date) =>
  //   `${(date.getMonth() + 1).toString()}-${date.getDate().toString()} ${date
  //     .getHours().toString()}:${date.getHours().toString()}`;

  // useEffect(()=>{console.log(node), []})

  useEffect(() => {

    if (node?.collection === 'NEXUS_AI') {

      const fetchData = async () => {
        const modelData = await canvasFetchModelData(
          currentModel, graphDate?.toISOString().split("T")[0] || "today")
          .then(response => {
            response.timestamp = response.timestamps.map(d => new Date(d).toISOString())

            let thisData = { timestamp: response.timestamp, inputs: { ...response.inputs }, outputs: { ...response.outputs } }

            console.log(thisData)
            setData(thisData)
            setThisprops({ node: { ...node, meta: node?.meta ? API._flattenObject(node.meta, 1, ".") : {} }, latitude, longitude, hosted, collection: node.collection, style })
            return thisData
          })
      }
      fetchData()
      setTimeout(() => setUpdate(Date.now()), 100)
    }
    else {
      if (hosted === true)
        API.getAssetData(
          node.collection,
          [node.asset_id],
          graphDate?.toISOString().split("T")[0] || "today"
        ).then((r) => {
          setData(r[node.collection][node.asset_id] || []),
            console.log(r[node.collection][node.asset_id] || [])
          setStats(r.stats || {}),
            setThisprops({ node: { ...node, meta: node?.meta ? API._flattenObject(node.meta, 1, ".") : {} }, latitude, longitude, hosted, collection: node.collection, style })
        });
      else getData(timeframe);
      setTimeout(() => setUpdate(Date.now()), 500)
    }
  }, [node, graphDate]);

  return (
    <Box
      style={{
        ...backgroundStyle,
        zIndex: 1,
        borderRadius: borderRadius,
        maxHeight: "calc(100vh - 110px)",
        ...style,
      }}
    >
      <DragIndicatorIcon
        style={{
          position: "absolute",
          cursor: "grab",
          zIndex: 2,
          marginTop: "10px",
          marginLeft: "350px",
        }}
      />

      <DTooltip title="Metadata">
        <IconButton
          style={{
            position: "absolute",
            zIndex: 2,
            marginTop: "10px",
            marginLeft: "300px",
            fontSize: "14px",
            borderRadius: 0,
            padding: 0,
            color: showMeta ? "white" : "grey",
          }}
          onClick={() => {
            setShowMeta(!showMeta);
            // focusCb({
            //     center: [longitude, latitude],
            //     essential: true,
            //     duration: 2000,
            //     zoom: 18,
            //     pitch: 65,
            // })
          }}
        >
          <TagIcon />
        </IconButton>
      </DTooltip>

      <DTooltip title="Model Analysis">
        <IconButton
          style={{
            position: "absolute",
            zIndex: 2,
            marginTop: "10px",
            marginLeft: "275px",
            fontSize: "14px",
            borderRadius: 0,
            padding: 0,
            color: "white",
          }}
          onClick={() => {
            setFocusNode(null)
            node?.actions?.analytics()
          }}
        >
          <AutoGraphIcon />
        </IconButton>
      </DTooltip>

      <DTooltip title="Focus Node">
        <IconButton
          className="buttonexotic"
          style={{
            position: "absolute",
            zIndex: 2,
            marginTop: "10px",
            marginLeft: "325px",
            fontSize: "14px",
            borderRadius: 0,
            padding: 0,
            color: "white",
          }}
          onClick={() => {
            focusCb({
              center: [longitude, latitude],
              essential: true,
              duration: 2000,
              zoom: 16,
              // pitch: 65,
            });
          }}
        >
          <CenterFocusStrongIcon />
        </IconButton>
      </DTooltip>

      {/* <div style={{
       
            }}> */}

      {/* {thisProps.node.meta && <><span style={{ opacity: 0.4 }}>META:</span>
                            <br />{Object.keys(thisProps.node.meta).map(k => <> <span style={{ opacity: 0.4 }}> &nbsp;&nbsp;&nbsp;{k}:</span> {thisProps.node.meta[k]}</>)}
                        </>} */}

      {hosted && (
        <>
          {/* <Transitioner open={!metaVisible} springs={{ from: { x: 200, y: 0, opacity:0 }, to: { x: 0, y: 0 , opacity:1 } , delay:200}}> */}
          <Box
            style={{
              paddingLeft: "15px",
              // paddingTop: "10px",
              paddingBottom: "0px",
              // background: 'linear-gradient(to right, rgba(255,255,255,0), rgba(0,0,0,0.2) , rgba(255,255,255,0))'
            }}
          >
            {/* COLLECTION: xx.xxxxxxxxxx
                <br /> */}

            {/* <span style={{ opacity: 0.4 }}>UPDATED:</span> {thisProps.node.last_update}
                    <br /> */}
            {/* <Trail open={!metaVisible}> */}
            <div style={{
              // fontSize: '14px', color: colourAccent,
              // textAlign: 'center',
              // paddingTop: '10px',
              // paddingBottom: '10px',
              // marginTop: 0,
              paddingTop: "10px",
              paddingBottom: "2px",
              // background: 'linear-gradient(to right, rgba(255,255,255,0), rgba(0,0,0,0.2) , rgba(255,255,255,0))'

            }}>
              <Transvoider
                open={true}
                behaviour={{ x: 0, y: 0, height: 18, config: { duration: 100 } }}
              >
                {/* <div>
                  <span style={{ opacity: 0.4 }}>LATEST: </span>
                  {thisProps?.node?.meta?.last_update
                    ?.replace(".000Z", "")
                    .replace("T", " ") || "---"}
                </div> */}
                <div>
                  <span style={{ opacity: 0.4 }}>COLL.: </span>
                  {thisProps?.node?.collection?.toUpperCase()}
                </div>
                <div>
                  <span style={{ opacity: 0.4 }}>ASSET: </span>
                  {thisProps?.node?.asset_id}
                </div>
              </Transvoider>
            </div>

            <div style={{ paddingTop: 2, maxHeight: '150px', overflowY: thisProps.node?.meta?.length > 8 ? 'auto' : 'hidden', overflowX: 'clip' }}>
              <Transvoider
                open={showMeta}
                behaviour={{ x: !showMeta ? 0 : 5, height: showMeta ? 18 : 0 }}
                behaviourConfig={{ duration: 80 }}
              >
                <div style={{ opacity: 0.6 }}>
                  <span style={{ opacity: 0.4 }}>LAT.:</span>{" "}
                  {thisProps?.latitude}
                </div>
                <div style={{ opacity: 0.6 }}>
                  <span style={{ opacity: 0.4 }}>LONG.:</span>{" "}
                  {thisProps?.longitude}
                </div>

                {thisProps.node?.meta &&
                  Object.keys(thisProps.node.meta).map(
                    (k) =>
                      k !== "last_update" && (
                        <div style={{ opacity: 0.6 }}>
                          {" "}
                          <span style={{ opacity: 0.4 }}>
                            {" "}
                            {k.toUpperCase()}:
                          </span>{" "}
                          {`${thisProps.node.meta[k]}`.toUpperCase()}
                        </div>
                      )
                  )}
              </Transvoider>
            </div>
            <Transvoider
              open={true}
              behaviour={{ x: 0, height: 18, config: { duration: 100 } }}
            >
              {/* <div>
                <span style={{ opacity: 0.4 }}>TIMESEG:</span> 24(1h)
              </div> */}
            </Transvoider>
          </Box>


          <Box
            style={{
              // ...bannerStreamsStyle,
              textAlign: "center",
              paddingBottom: "4px",
              width: "100%",
              marginTop: "5px",
            }}
          >
            <DButton
              // className="buttonexotic"
              style={{
                width: "120px",
              }}
              onClick={() => setGraphDate(null)}
            >
              PREVIOUS 24H
            </DButton>

            {/* <span style={{ width: "10px", display: "inline-block" }} /> */}

            <DateTimeRangePickerPopper
              range={false}
              btnLabel={`FOR ${graphDate === null
                ? "DAY"
                : graphDate.toISOString().split("T")[0]
                // .split("T")[0]
                }`}
              btnStyle={{
                // color: "white",
                // fontSize: "12px",
                // background: "darkcyan",
                // borderRadius: "0px",
                width: "250px",
                padding: 0,
                margin: 0
              }}
              // closeBtnStyle={{
              //   color: "white",
              //   background: "darkcyan",
              //   borderRadius: "2px",
              //   width: "120px",
              //   display: "flex",
              //   padding: 0,
              //   margin: 0,
              //   borderColor: 'darkcyan'
              // }}
              onChange={(newValue) => {
                // console.log(newValue);
                setGraphDate(dayjs(newValue).add(1, "day"));
              }}
            />
          </Box>

          <Box
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              // direction: 'rtl',
              // maxHeight: '100%',
              // position: 'relative',
              // background: 'rgba(255, 255, 255, 0.8)',
              background: "rgb(31 31 31 / 40%)",
              paddingLeft: "10px",
              // paddingRight: '15px',
              color: colourAccent,
              borderTopLeftRadius: "5px",
              borderBottomLeftRadius: "5px",
              height: "calc(90vh - 110px - 140px - 50px)",
              marginTop: "0px",
              // paddingTop: "5px",
            }}
          >
            {/* {JSON.stringify(data["timestamp"]?.map((x) => new Date(x)))} */}

            <Transvoider
              open={node?.collection === 'NEXUS_AI'}
              behaviour={{
                Y: 0,
                height: modelProgress === 1 && canvasMode ? 170 : 110,
                from: { x: -20, opacity: 0 },
                to: { x: 0, opacity: 1 },
                delay: 100,
                config: { duration: 200 },
                width: '100%',

              }}
              containerStyle={{ width: '100%' }}
            >

              {node?.collection === 'NEXUS_AI' && data?.outputs && <>
                <div style={bannerStreamsStyle}>
                  OUTPUT STREAMS
                </div>
                {Object.keys(data.outputs)
                  .sort()
                  .map(
                    (v, i) =>
                      v !== "timestamp" &&
                      data.outputs[v]?.length && (
                        <div style={{ paddingTop: "10px" }}>
                          <div
                            style={{
                              fontWeight: "bold",
                              marginTop: "0px",
                              marginBottom: "-25px",
                            }}
                          >
                            {" "}
                            {v.toUpperCase()}
                          </div>

                          <DCharts style={{ height: graphHeight, marginBottom: '0px' }}
                            title=''
                            datetimeArray={data.timestamp}
                            dataArray={[data.outputs[v].map(x => x || 0)]}
                            colors={['cyan']}
                            labels={['Base']}
                            update={update} />

                          {/* {canvasMode && modelProgress === 1 ? (
                          <div
                            style={{
                              display: "inline-flex",
                              marginTop: "-20px",
                              marginBottom: "10px",
                            }}
                          >
                            <Transvoider
                              open={true}
                              behaviour={{
                                x: 0,
                                y: 0,
                                from: { scale: 0.8, opacity: 0 },
                                to: { scale: 1, opacity: 1 },
                              }}
                              containerStyle={{ width: '100%' }}
                            >

                              {!currentModel.outputs[
                                `${node.collection}.${node.asset_id}.${v}`
                              ] ? (
                                <Button
                                  // className='buttonexotic'
                                  style={{
                                    // ...buttonStyle,
                                    // background: 'teal',
                                    color: colourAccent,
                                    borderWidth: "revert",
                                    borderColor: colourAccent,
                                    borderStyle: "solid",
                                    width: "160px",
                                    height: "30px",
                                    // ...buttonStyle, width: '120px'
                                  }}
                                  onClick={() =>
                                    addFeature("outputs", {
                                      name: `${node.collection}.${node.asset_id}.${v}`,
                                      node,
                                      latitude,
                                      longitude,
                                    })
                                  }
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  <AddCircleIcon /> &nbsp; INPUT Feature
                                </Button>
                              ) : (
                                <Button
                                  // className='buttonexotic'
                                  style={{
                                    // ...buttonStyle,
                                    background: colourAccent,
                                    color: "rgb(31 31 31 / 80%)",
                                    borderWidth: "revert",
                                    borderColor: "rgb(31 31 31 / 80%)",
                                    borderStyle: "solid",
                                    width: "160px",
                                    height: "30px",
                                    // ...buttonStyle, width: '120px'
                                  }}
                                  onClick={() =>
                                    removeFeature("inputs", {
                                      name: `${node.collection}.${node.asset_id}.${v}`,
                                      node,
                                      latitude,
                                      longitude,
                                    })
                                  }
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  <RemoveCircleIcon /> &nbsp; INPUT Feature
                                </Button>
                              )}

                              {!currentModel.outputs[
                                `${node.collection}.${node.asset_id}.${v}`
                              ] ? (
                                <Button
                                  // className='buttonexotic'
                                  style={{
                                    // ...buttonStyle,
                                    // background: 'teal',
                                    color: colourAccent,
                                    borderWidth: "revert",
                                    borderColor: colourAccent,
                                    borderStyle: "solid",
                                    width: "170px",
                                    height: "30px",
                                    // paddingRight: '10px',
                                    // ...buttonStyle, width: '120px'
                                  }}
                                  sx={{ mt: 1, mr: 1 }}
                                  onClick={() =>
                                    addFeature("outputs", {
                                      name: `${node.collection}.${node.asset_id}.${v}`,
                                      node,
                                      latitude,
                                      longitude,
                                    })
                                  }
                                >
                                  <AddCircleIcon /> &nbsp; OUTPUT Feature
                                </Button>
                              ) : (
                                <Button
                                  // className='buttonexotic'
                                  style={{
                                    // ...buttonStyle,
                                    background: colourAccent,
                                    color: "rgb(31 31 31 / 80%)",
                                    borderWidth: "revert",
                                    borderColor: "rgb(31 31 31 / 80%)",
                                    borderStyle: "solid",
                                    width: "170px",
                                    height: "30px",
                                    // paddingRight: '10px',
                                    // ...buttonStyle, width: '120px'
                                  }}
                                  sx={{ mt: 1, mr: 1 }}
                                  onClick={() =>
                                    removeFeature("outputs", {
                                      name: `${node.collection}.${node.asset_id}.${v}`,
                                      node,
                                      latitude,
                                      longitude,
                                    })
                                  }
                                >
                                  <RemoveCircleIcon /> &nbsp; OUTPUT Feature
                                </Button>
                              )}

                            </Transvoider>
                          </div>
                        ) : null} */}

                          <div style={{ height: "5px", width: "100%" }} />
                        </div>
                      ))}
              </>
              }

              {node?.collection === 'NEXUS_AI' && data?.inputs && <>
                <div style={bannerStreamsStyle}>
                  INPUT STREAMS
                </div>
                {Object.keys(data.inputs)
                  .sort()
                  .map(
                    (v, i) =>
                      v !== "timestamp" &&
                      data.inputs[v]?.length && (
                        <div style={{ paddingTop: "10px" }}>
                          <div
                            style={{
                              fontWeight: "bold",
                              marginTop: "0px",
                              marginBottom: "-25px",
                            }}
                          >
                            {" "}
                            {v.toUpperCase()}
                          </div>

                          <DCharts style={{ height: graphHeight, marginBottom: '0px', }}
                            title=''
                            datetimeArray={data.timestamp}
                            dataArray={[data.inputs[v].map(x => x || 0)]}
                            colors={['cyan']}
                            labels={['Base']}
                            update={update} />

                          {/* {canvasMode && modelProgress === 1 ? (
                          <div
                            style={{
                              display: "inline-flex",
                              marginTop: "-20px",
                              marginBottom: "10px",
                            }}
                          >
                            <Transvoider
                              open={true}
                              behaviour={{
                                x: 0,
                                y: 0,
                                from: { scale: 0.8, opacity: 0 },
                                to: { scale: 1, opacity: 1 },
                              }}
                            >

                              {!currentModel.inputs[
                                `${node.collection}.${node.asset_id}.${v}`
                              ] ? (
                                <Button
                                  // className='buttonexotic'
                                  style={{
                                    // ...buttonStyle,
                                    // background: 'teal',
                                    color: colourAccent,
                                    borderWidth: "revert",
                                    borderColor: colourAccent,
                                    borderStyle: "solid",
                                    width: "160px",
                                    height: "30px",
                                    // ...buttonStyle, width: '120px'
                                  }}
                                  onClick={() =>
                                    addFeature("inputs", {
                                      name: `${node.collection}.${node.asset_id}.${v}`,
                                      node,
                                      latitude,
                                      longitude,
                                    })
                                  }
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  <AddCircleIcon /> &nbsp; INPUT Feature
                                </Button>
                              ) : (
                                <Button
                                  // className='buttonexotic'
                                  style={{
                                    // ...buttonStyle,
                                    background: colourAccent,
                                    color: "rgb(31 31 31 / 80%)",
                                    borderWidth: "revert",
                                    borderColor: "rgb(31 31 31 / 80%)",
                                    borderStyle: "solid",
                                    width: "160px",
                                    height: "30px",
                                    // ...buttonStyle, width: '120px'
                                  }}
                                  onClick={() =>
                                    removeFeature("inputs", {
                                      name: `${node.collection}.${node.asset_id}.${v}`,
                                      node,
                                      latitude,
                                      longitude,
                                    })
                                  }
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  <RemoveCircleIcon /> &nbsp; INPUT Feature
                                </Button>
                              )}

                              {!currentModel.outputs[
                                `${node.collection}.${node.asset_id}.${v}`
                              ] ? (
                                <Button
                                  // className='buttonexotic'
                                  style={{
                                    // ...buttonStyle,
                                    // background: 'teal',
                                    color: colourAccent,
                                    borderWidth: "revert",
                                    borderColor: colourAccent,
                                    borderStyle: "solid",
                                    width: "170px",
                                    height: "30px",
                                    // paddingRight: '10px',
                                    // ...buttonStyle, width: '120px'
                                  }}
                                  sx={{ mt: 1, mr: 1 }}
                                  onClick={() =>
                                    addFeature("outputs", {
                                      name: `${node.collection}.${node.asset_id}.${v}`,
                                      node,
                                      latitude,
                                      longitude,
                                    })
                                  }
                                >
                                  <AddCircleIcon /> &nbsp; OUTPUT Feature
                                </Button>
                              ) : (
                                <Button
                                  // className='buttonexotic'
                                  style={{
                                    // ...buttonStyle,
                                    background: colourAccent,
                                    color: "rgb(31 31 31 / 80%)",
                                    borderWidth: "revert",
                                    borderColor: "rgb(31 31 31 / 80%)",
                                    borderStyle: "solid",
                                    width: "170px",
                                    height: "30px",
                                    // paddingRight: '10px',
                                    // ...buttonStyle, width: '120px'
                                  }}
                                  sx={{ mt: 1, mr: 1 }}
                                  onClick={() =>
                                    removeFeature("outputs", {
                                      name: `${node.collection}.${node.asset_id}.${v}`,
                                      node,
                                      latitude,
                                      longitude,
                                    })
                                  }
                                >
                                  <RemoveCircleIcon /> &nbsp; OUTPUT Feature
                                </Button>
                              )}

                            </Transvoider>
                          </div>
                        ) : null} */}

                          <div style={{ height: "5px", width: "100%" }} />
                        </div>
                      ))}
              </>
              }
            </Transvoider>

            <Transvoider
              open={node?.collection !== 'NEXUS_AI' && node?.collection !== 'NEXUS_AGGREGATOR'}
              behaviour={{
                Y: 0,
                height: modelProgress === 1 && canvasMode ? 170 : 110,
                from: { x: -20, opacity: 0 },
                to: { x: 0, opacity: 1 },
                delay: 100,
                config: { duration: 200 },
              }}
              containerStyle={{ width: '100%' }}
            >

              <div
                style={bannerStreamsStyle}
              >
                OUTPUT STREAMS
              </div>

              {node?.collection !== 'NEXUS_AI' && node?.collection !== 'NEXUS_AGGREGATOR' && Object.keys(data)
                .sort()
                .map(
                  (v, i) =>
                    v !== "timestamp" &&
                    data[v]?.length && (
                      <div style={{ paddingTop: "10px" }}>
                        <div
                          style={{
                            fontWeight: "bold",
                            marginTop: "0px",
                            marginBottom: "-25px",
                          }}
                        >
                          {" "}
                          {v.toUpperCase()}
                        </div>

                        <DCharts style={{ height: graphHeight, marginBottom: '0px' }}
                          title=''
                          datetimeArray={data["timestamp"].map((x) => new Date(x))}
                          dataArray={[data[v]]}
                          colors={['cyan']}
                          labels={['Base']}
                          update={update} />



                        {canvasMode && modelProgress === 1 ? (
                          <div
                            style={{
                              display: "inline-flex",
                              marginTop: "-20px",
                              marginBottom: "10px",
                            }}
                          >
                            <Transvoider
                              open={true}
                              behaviour={{
                                x: 0,
                                y: 0,
                                from: { scale: 0.8, opacity: 0 },
                                to: { scale: 1, opacity: 1 },
                              }}
                            >
                              {/* <div> */}
                              {!currentModel.inputs[
                                `${node.collection}.${node.asset_id}.${v}`
                              ] ? (
                                <Button
                                  // className='buttonexotic'
                                  style={{
                                    // ...buttonStyle,
                                    // background: 'teal',
                                    color: colourAccent,
                                    borderWidth: "revert",
                                    borderColor: colourAccent,
                                    borderStyle: "solid",
                                    width: "160px",
                                    height: "30px",
                                    // ...buttonStyle, width: '120px'
                                  }}
                                  onClick={() =>
                                    addFeature("inputs", {
                                      name: `${node.collection}.${node.asset_id}.${v}`,
                                      node,
                                      latitude,
                                      longitude,
                                    })
                                  }
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  <AddCircleIcon /> &nbsp; INPUT Feature
                                </Button>
                              ) : (
                                <Button
                                  // className='buttonexotic'
                                  style={{
                                    // ...buttonStyle,
                                    background: colourAccent,
                                    color: "rgb(31 31 31 / 80%)",
                                    borderWidth: "revert",
                                    borderColor: "rgb(31 31 31 / 80%)",
                                    borderStyle: "solid",
                                    width: "160px",
                                    height: "30px",
                                    // ...buttonStyle, width: '120px'
                                  }}
                                  onClick={() =>
                                    removeFeature("inputs", {
                                      name: `${node.collection}.${node.asset_id}.${v}`,
                                      node,
                                      latitude,
                                      longitude,
                                    })
                                  }
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  <RemoveCircleIcon /> &nbsp; INPUT Feature
                                </Button>
                              )}

                              {!currentModel.outputs[
                                `${node.collection}.${node.asset_id}.${v}`
                              ] ? (
                                <Button
                                  // className='buttonexotic'
                                  style={{
                                    // ...buttonStyle,
                                    // background: 'teal',
                                    color: colourAccent,
                                    borderWidth: "revert",
                                    borderColor: colourAccent,
                                    borderStyle: "solid",
                                    width: "170px",
                                    height: "30px",
                                    // paddingRight: '10px',
                                    // ...buttonStyle, width: '120px'
                                  }}
                                  sx={{ mt: 1, mr: 1 }}
                                  onClick={() =>
                                    addFeature("outputs", {
                                      name: `${node.collection}.${node.asset_id}.${v}`,
                                      node,
                                      latitude,
                                      longitude,
                                    })
                                  }
                                >
                                  <AddCircleIcon /> &nbsp; OUTPUT Feature
                                </Button>
                              ) : (
                                <Button
                                  // className='buttonexotic'
                                  style={{
                                    // ...buttonStyle,
                                    background: colourAccent,
                                    color: "rgb(31 31 31 / 80%)",
                                    borderWidth: "revert",
                                    borderColor: "rgb(31 31 31 / 80%)",
                                    borderStyle: "solid",
                                    width: "170px",
                                    height: "30px",
                                    // paddingRight: '10px',
                                    // ...buttonStyle, width: '120px'
                                  }}
                                  sx={{ mt: 1, mr: 1 }}
                                  onClick={() =>
                                    removeFeature("outputs", {
                                      name: `${node.collection}.${node.asset_id}.${v}`,
                                      node,
                                      latitude,
                                      longitude,
                                    })
                                  }
                                >
                                  <RemoveCircleIcon /> &nbsp; OUTPUT Feature
                                </Button>
                              )}
                              {/* </div> */}
                            </Transvoider>
                          </div>
                        ) : null}

                        <div style={{ height: "5px", width: "100%" }} />
                      </div>
                    )
                )}
            </Transvoider>
            <div style={{ height: "6px", width: "100%" }} />
          </Box>
        </>
      )
      }

      {
        !hosted && data && data[0] && (
          <>
            <Box
              style={{
                paddingLeft: "15px",
                paddingTop: "5px",
                paddingBottom: "0px",
              }}
            >
              {/* COLLECTION: xx.xxxxxxxxxx
                <br /> */}
              ASSET: coll.{node}
              <br />
              LAT: xx.xxxxxxxxxx
              <br />
              LONG: xx.xxxxxxxxxx
              <br />
              UPDATED: xx.xxxxxxxxxx
              {/* <br /> */}
              <br />
              TIMESEG: {JSON.stringify(data[0].data.length)}
              <br />
              {/* <br /> */}
            </Box>

            <Box
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                // direction: 'rtl',
                maxHeight: "67vh",
                // background: 'rgba(255, 255, 255, 0.8)',
                background: "rgb(31 31 31 / 40%)",
                paddingLeft: "15px",
                // paddingRight: '15px',
                color: colourAccent,
                borderTopLeftRadius: "5px",
                borderBottomLeftRadius: "5px",
              }}
            >
              {data.map((v, i) => (
                <div>
                  {/* <br /> */}
                  <div
                    style={{
                      fontWeight: "bold",
                      marginTop: "10px",
                      marginBottom: "-25px",
                    }}
                  >
                    {" "}
                    {node}.{v.name}
                  </div>
                  {/* <BarChart
                    xAxis={[
                        {
                            id: 'barCategories',
                            data: ['bar A', 'bar B', 'bar C'],
                            scaleType: 'band',
                        },
                    ]}
                    series={[
                        {
                            data: v.data,
                        },
                    ]}
                    width={300}
                    height={110}
                /> */}
                  <LineChart
                    xAxis={[
                      {
                        data: [...Array(v.data.length).keys()],
                        tickInterval: 2,
                        disableTicks: true,
                        min: 0,
                        max: 24,
                        tooltip: "none",
                      },
                    ]}
                    // bottomAxis={[{}]}
                    series={[
                      {
                        data: v.data,
                        showMark: false,
                        tooltip: "none",
                      },
                    ]}
                    // width={350}
                    height={140}
                    sx={{
                      //change left yAxis label styles
                      "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                        strokeWidth: "0.4",
                        fill: colourAccent,
                      },
                      // change all labels fontFamily shown on both xAxis and yAxis
                      "& .MuiChartsAxis-tickContainer .MuiChartsAxis-tickLabel": {
                        //    fontFamily: "Roboto",
                      },
                      // change bottom label styles
                      "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                        strokeWidth: "0.5",
                        fill: colourAccent,
                      },
                      // bottomAxis Line Styles
                      "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
                        stroke: colourAccent,
                        strokeWidth: 0.4,
                      },
                      "& .MuiChartsAxis-root .MuiChartsAxis-tick": {
                        stroke: colourAccent,
                        strokeWidth: 0.4,
                      },
                      // leftAxis Line Styles
                      "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                        stroke: colourAccent,
                        strokeWidth: 0.4,
                      },
                    }}
                  />
                  {/* {canvasMode ? <>
                            {modelProgress === 1 ?
                                <div style={{ display: 'inline-flex', marginTop: '-15px' }}>
                                    {!currentModel.inputs[`${collection}.${node.asset_id}.${v}`] ? <Button
                                        // className='buttonexotic' 
                                        style={{
                                            // ...buttonStyle,
                                            // background: 'teal',
                                            color: colourAccent,
                                            borderWidth: 'revert',
                                            borderColor: colourAccent,
                                            borderStyle: 'solid',
                                            width: '160px',
                                            height: '30px'
                                            // ...buttonStyle, width: '120px' 
                                        }}
                                        onClick={() => addFeature('i', { name: `${node}.${v.name}` })}
                                        sx={{ mt: 1, mr: 1 }}>
                                        <AddCircleIcon /> &nbsp; INPUT Feature
                                    </Button> : <Button
                                        // className='buttonexotic' 
                                        style={{
                                            // ...buttonStyle,
                                            background: colourAccent,
                                            color: 'rgb(31 31 31 / 80%)',
                                            borderWidth: 'revert',
                                            borderColor: 'rgb(31 31 31 / 80%)',
                                            borderStyle: 'solid',
                                            width: '160px',
                                            height: '30px'
                                            // ...buttonStyle, width: '120px' 
                                        }}
                                        onClick={() => removeFeature('i', { name: `${node}.${v.name}` })}
                                        sx={{ mt: 1, mr: 1 }}>
                                        <RemoveCircleIcon /> &nbsp; INPUT Feature
                                    </Button>}
                                    {!currentModel.inputs[`${collection}.${node.asset_id}.${v}`]  ? <Button
                                        // className='buttonexotic' 
                                        style={{
                                            // ...buttonStyle,
                                            // background: 'teal',
                                            color: colourAccent,
                                            borderWidth: 'revert',
                                            borderColor: colourAccent,
                                            borderStyle: 'solid',
                                            width: '170px',
                                            height: '30px'
                                            // paddingRight: '10px',
                                            // ...buttonStyle, width: '120px' 
                                        }}
                                        sx={{ mt: 1, mr: 1 }}
                                        onClick={() => addFeature('o', { name: `${node}.${v.name}` })}>
                                        <AddCircleIcon /> &nbsp; OUTPUT Feature
                                    </Button> : <Button
                                        // className='buttonexotic' 
                                        style={{
                                            // ...buttonStyle,
                                            background: colourAccent,
                                            color: 'rgb(31 31 31 / 80%)',
                                            borderWidth: 'revert',
                                            borderColor: 'rgb(31 31 31 / 80%)',
                                            borderStyle: 'solid',
                                            width: '170px',
                                            height: '30px'
                                            // paddingRight: '10px',
                                            // ...buttonStyle, width: '120px' 
                                        }}
                                        sx={{ mt: 1, mr: 1 }}
                                        onClick={() => removeFeature('o', { name: `${node}.${v.name}` })}>
                                        <RemoveCircleIcon /> &nbsp; OUTPUT Feature
                                    </Button>}


                                </div> : null}



                        </> : null} */}

                  {/* {canvasMode ? <>
                        {modelProgress === 1 ?
                            <div style={{ display: 'inline-flex' }}>
                                {currentModel.inputs.includes(`${node}.${v.name}`) ? <Button
                                    className='buttonexotic' style={{
                                        ...buttonStyle,
                                        // background: 'teal',
                                        color: 'seagreen',
                                        borderWidth: 'revert',
                                        borderColor: 'seagreen',
                                        borderStyle: 'solid',
                                        width: '180px'
                                        // ...buttonStyle, width: '120px' 
                                    }}
                                    onClick={() => addFeature('i', `${node}.${v.name}`)}
                                    sx={{ mt: 1, mr: 1 }}>
                                    <AddCircleIcon /> &nbsp; INPUT Feature
                                </Button> : <Button
                                    className='buttonexotic' style={{
                                        ...buttonStyle,
                                        // background: 'teal',
                                        color: 'firebrick',
                                        borderWidth: 'revert',
                                        borderColor: 'firebrick',
                                        borderStyle: 'solid',
                                        width: '180px'
                                        // ...buttonStyle, width: '120px' 
                                    }}
                                    onClick={() => removeFeature('i', `${node}.${v.name}`)}
                                    sx={{ mt: 1, mr: 1 }}>
                                    <AddCircleIcon /> &nbsp; INPUT Feature
                                </Button>}
                                {currentModel.outputs.includes(`${node}.${v.name}`) ?<Button
                                    className='buttonexotic' style={{
                                        ...buttonStyle,
                                        // background: 'teal',
                                        color: 'seagreen',
                                        borderWidth: 'revert',
                                        borderColor: 'seagreen',
                                        borderStyle: 'solid',
                                        width: '190px',
                                        // paddingRight: '10px',
                                        // ...buttonStyle, width: '120px' 
                                    }}
                                    sx={{ mt: 1, mr: 1 }}
                                    onClick={() => addFeature('o', `${node}.${v.name}`)}>
                                    <AddCircleIcon /> &nbsp; OUTPUT Feature
                                </Button>:<Button
                                    className='buttonexotic' style={{
                                        ...buttonStyle,
                                        // background: 'teal',
                                        color: 'firebrick',
                                        borderWidth: 'revert',
                                        borderColor: 'firebrick',
                                        borderStyle: 'solid',
                                        width: '190px',
                                        // paddingRight: '10px',
                                        // ...buttonStyle, width: '120px' 
                                    }}
                                    sx={{ mt: 1, mr: 1 }}
                                    onClick={() => removeFeature('o', `${node}.${v.name}`)}>
                                    <AddCircleIcon /> &nbsp; OUTPUT Feature
                                </Button>}

                            </div> : null}
                    </> : null} */}

                  {/* <br />
                    <br /> */}

                  {/* <hr /> */}
                  {/* <hr style={{ background: colourAccentSecondary, width: '92%' }} /> */}
                  <div style={{ height: "5px", width: "100%" }} />
                  {/* <br /> */}
                  {/* <hr style={{ marginTop: '50px', background: colourAccentSecondary, width: '96%', marginLeft: '0px', marginRight: '0px' }} /> */}
                  {/* <br /> */}
                </div>
              ))}
              <div style={{ height: "6px", width: "100%" }} />
            </Box>
          </>
        )
      }
    </Box >
  );
}

import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { ToggleButtonGroup, ToggleButton, Button, IconButton, Tooltip, duration } from '@mui/material';
import { headStyle, hrStyle, buttonStyle, headStyleInverted, backgroundStyle, lightColor, verticalCenter, colourAccent as defaultColourAccent, colourBackground, colourAccentSecondary, colourAccentDisabled } from '../../css/styling';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import ViewInAr from '@mui/icons-material/ViewInAr';
import { BlurCircular, ContentCutOutlined, TramRounded } from '@mui/icons-material';
import CycloneIcon from '@mui/icons-material/Cyclone';
import PlaceIcon from '@mui/icons-material/Place';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness5Icon from '@mui/icons-material/Brightness5';
import Brightness3Icon from '@mui/icons-material/Brightness3';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import HexagonIcon from '@mui/icons-material/Hexagon';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import * as Wards from '../apis/wards19.json';

import { useElementOnScreen, Transitioner, Trailer, spanCharacters, spanArray, spanArrayStringEqualSplit, Transvoider } from '../dElements/Animations';


// import {MapboxOverlay} from '@deck.gl/mapbox';
import { MapboxOverlay, MapboxOverlayProps } from '@deck.gl/mapbox';

import Map, { Source, Layer, AttributionControl, useMap, NavigationControl, useControl } from 'react-map-gl';
import { skyLayer, trafficLayer, buildingLayer } from '../obsolete/LayerConfigs';
import { SimpleMeshLayer } from '@deck.gl/mesh-layers';

import DeckGL from '@deck.gl/react';
import { HexagonLayer, ArcLayer } from 'deck.gl';

import { AmbientLight, PointLight, DirectionalLight, LightingEffect } from '@deck.gl/core';
import { brightnessContrast } from '@luma.gl/shadertools';
import { PostProcessEffect } from '@deck.gl/core';
import { OBJLoader } from '@loaders.gl/obj';
import { COORDINATE_SYSTEM, FlyToInterpolator } from '@deck.gl/core';

import Insights from '../windows/Insights';
import Datasets from '../windows/Datasets';
import Cases from '../windows/Cases';
import APIs from '../windows/APIS';
import Window from '../windows/Window';
import * as API2 from '../states';

// import { COIL } from '../../models/mosq_coil'
// import * as CUBEGLB from '../../models/cube.glb';
// import { CARTFEATHER } from '../../models/cart_feather';
import { parse } from '@loaders.gl/core';
// import * as turf from '@turf/turf';

import { scaleLinear } from 'd3-scale';
import { createRoot } from 'react-dom/client';
import { GeoJsonLayer } from '@deck.gl/layers';
import { Tile3DLayer } from '@deck.gl/geo-layers';
import { DataFilterExtension, _TerrainExtension as TerrainExtension } from '@deck.gl/extensions';
import { TextLayer } from '@deck.gl/layers';
// import { CubeGeometry  } from '@luma.gl/core/src/types';
// import {CubeGeometry} from 'luma.gl'
// import { CubeGeometry } from 'three';
import { CubeGeometry, IcoSphereGeometry, SphereGeometry } from '@luma.gl/engine'
// import CubeGeometry

import { useSpring, useSpringValue, animated, to, useTransition, Transition, useTrail } from '@react-spring/web';
import CanvasView from '../canvas/CanvasModels';
import InferenceView from '../canvas/InferenceView';
import Contribute from '../windows/Contribute';
import Roadmap from '../windows/Roadmap';
// import TrainingDialog from '../canvas/TrainingDialog';

import AssetInfo from '../canvas/AssetInfo';
import Analytics from '../canvas/Analytics';
import Draggable from 'react-draggable';
import PanToolIcon from '@mui/icons-material/PanTool';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import SettingsIcon from '@mui/icons-material/Settings';
import * as API from '../apis/APIWeb';

import { debounce, throttle } from 'throttle-debounce';

// import * as mbStyle from './mapbox-styles-standard.json';
// import * as mbStyleOld from './map-style-basic-v8.json';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import FileImport from '../FileImport';

/* eslint-disable import/no-webpack-loader-syntax */
import mapboxgl from 'mapbox-gl';
import CanvasManager from '../canvas/CanvasManager';
import Merlin from '../merlin/Merlin';

import MerlinInsights from '../merlin/MerlinInsights';
import { max } from 'date-fns';
import { DButton } from '../dElements/DButton';
import { ScenegraphLayer } from '@deck.gl/mesh-layers';
// import { GLTFLoader } from '@loaders.gl/gltf';

import { load } from '@loaders.gl/core';
import { GLBLoader, GLTFLoader } from '@loaders.gl/gltf';
import { AssetsCompositeLayer } from '../decklayers/Composites';
import { DTooltip } from '../dElements/DTooltip';
// import { Material } from 'three';
// import { Material } from '@luma.gl/core';



const LEGACY = false;
const INTERLEAVE = false;  // bug with object lightings as of Tues 2 April
const ENABLE_MAP = true;
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_APIKEY;


export const COLORS2 = [
    [254, 235, 226],
    [251, 180, 185],
    [247, 104, 161],
    [197, 27, 138],
    [122, 1, 119]
];

// class CustomMeshLayer extends SimpleMeshLayer {
//     static layerName = 'CustomWireframeMeshLayer';  // Correct property name

//     getShaders() {
//         const shaders = super.getShaders();

//     // Corrected GLSL 3.00 Vertex Shader with deck.gl projection
//     shaders.vs = `#version 300 es
//       in vec3 positions;
//       in vec3 normals;
//       in vec3 colors;

//       out vec3 vNormal;
//       out vec4 vColor;

//       void main(void) {
//         vNormal = normals;
//         vColor = vec4(colors, 1.0);

//         // Use deck.gl's projection utility to project positions to clip space
//         gl_Position = project_position_to_clipspace(positions, vec3(0.0), vec3(1.0));
//       }
//     `;

//     // Corrected GLSL 3.00 Fragment Shader
//     shaders.fs = `#version 300 es
//       precision highp float;

//       in vec3 vNormal;
//       in vec4 vColor;

//       out vec4 fragColor;

//       void main(void) {
//         // Simple edge detection logic based on normal
//         float wireframeThreshold = 0.05;
//         float edgeFactor = min(min(abs(vNormal.x), abs(vNormal.y)), abs(vNormal.z));

//         if (edgeFactor < wireframeThreshold) {
//           fragColor = vec4(0.0, 0.0, 0.0, 1.0);  // Wireframe (black)
//         } else {
//           fragColor = vColor;  // Solid mesh color
//         }
//       }
//     `;

//   // Basic shader setup for testing
// //   shaders.vs = `#version 300 es
// //   in vec3 positions;
// //   in vec3 normals;
// //   in vec3 colors;

// //   out vec3 vNormal;
// //   out vec4 vColor;

// //   void main(void) {
// //     vNormal = normals;
// //     vColor = vec4(colors, 1.0);

// //     // Directly pass positions without projection (for testing)
// //     gl_Position = vec4(positions, 1.0);
// //   }
// // `;

// // shaders.fs = `#version 300 es
// //   precision highp float;

// //   in vec3 vNormal;
// //   in vec4 vColor;

// //   out vec4 fragColor;

// //   void main(void) {
// //     // Simple color output for testing
// //     fragColor = vec4(1.0, 0.0, 0.0, 1.0);  // Red color
// //   }
// // `;



//         return shaders;
//     }
// }

// create ambient light source

const ambientLight = new AmbientLight({
    color: [255, 255, 255],
    intensity: 2.0
});
// create point light source
const pointLight = new PointLight({
    color: [255, 255, 255],
    intensity: 1.0,
    // use coordinate system as the same as view state
    position: [52.3765, -1.8036, 1000]
});
// create directional light source
const directionalLight = new DirectionalLight({
    color: [255, 255, 255],
    intensity: 1.0,
    direction: [-3, -9, -1]
});
// create lighting effect with light sources
const lightingEffect = new LightingEffect({
    ambientLight,
    pointLight,
    directionalLight
});

// @ts-ignore
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const postProcessEffect = new PostProcessEffect(brightnessContrast, {
    brightness: 0.05,
    contrast: 0.05
});

const INITIAL_VIEW_STATE = {
    "longitude": -1.9053006641463526,
    "latitude": 52.47992709031189,
    "zoom": 16.030968845856695,
    "pitch": 52.942010970722855,
    "bearing": -59.350466074324345,
}

const INITIAL_VIEW_STATE2 = {
    longitude: -1.90247585,
    latitude: 52.478445165,
    zoom: 12,
    pitch: 30,
    bearing: 0,
    altitude: 1.5
};

const INITIAL_VIEW_STATE3 = {
    "latitude": 52.479931554916924,
    "longitude": -1.9053091746231987,
    "zoom": 17.169927850106813,
    "bearing": -75.34883720930233,
    "pitch": 59.925992698334355,
    "altitude": 1.5,

}

const INITIAL_VIEW_STATE4 =  /// INITIAL_VIEW_STATE4 alternative
{
    "longitude": -1.902935174924096,
    "latitude": 52.479364363542146,
    "zoom": 14.843858745413273,
    "pitch": 49.02115616725596,
    "bearing": -56.91679800750626,
}

const NODE_MATERIAL = {
    ambient: 0.7,  // Increase ambient lighting for a glowing feel
    diffuse: 0.6,
    shininess: 200, // Higher shininess for more reflective, glowy surfaces
    specularColor: [255, 255, 255], // White highlights
    wireframe: true
}

const INITIAL_VIEW_STATE_HOME = INITIAL_VIEW_STATE

const COLORS = { RTEM: [220, 20, 60], SCOOT: [255, 140, 0], AIRVIRO: [0, 189, 189], AIRQ: [30, 144, 255], DEFAULT: [242, 242, 242], NEXUS: [128, 0, 128], NEXUS2: [255, 87, 51], NEXUS3: [0, 153, 204], NEXUS4: [72, 61, 139], NEXUS5: [0, 255, 255], NEXUS6: [153, 50, 204], NEXUS7: [100, 149, 237], NEXUS8: [127, 0, 255], NEXUS9: [255, 85, 0], NEXUS10: [255, 255, 0] }
const NEXUSCOLORLIGHT = COLORS.NEXUS9
const NEXUSCOLORDARK = COLORS.NEXUS9 // nexus8 orange
// const NEXUSCOLORDARK = COLORS.NEXUS5
const ASSETOPACITY = 100

const layerItemStyle = { borderRadius: '1px', float: 'right', marginLeft: '5px', textAlign: 'left', fontSize: '12px', padding: '5px' }

function DeckGLOverlayInterleaved(props) {
    const overlay = useControl(() => new MapboxOverlay({ ...props, interleaved: true }));
    overlay.setProps(props);
    return null;
}

function DeckGLOverlay(props) {
    const overlay = useControl(() => new MapboxOverlay({ ...props, interleaved: false }));
    overlay.setProps(props);
    return null;
}



function Platform({ variables, fullscreenToggle }) {

    var [date, setDate] = useState(new Date());

    useEffect(() => {
        var timer = setInterval(() => setDate(new Date()), 10000)
        var timer = setInterval(() => setDate(new Date()), 10000)
        return function cleanup() {
            clearInterval(timer)
        }
    });

    const { colourAccent, borderRadius } = API2.globalStore()
    const { ctxMenuPos, setCtxMenuPos } = API2.ctxMenuStore();
    const deckprops = API2.deckPropsStore();
    const { canvasMode, setCanvasMode, setFocusNode, canvasModels, focusNode, trainingStage } = API2.aiworkflow();
    const { datalist, datastates, loadInfoData } = API2.apiDataStore();
    const [userSetMapLight, setUserSetMapLight] = useState(null);

    const { light, setLight, page, setPage, windowOpen, setWindowOpen } = API2.globalStore();
    const { init, setInit, appLaunchable, collections, setCollections, assets, setAssets, barMessage, merlinWard, setMerlinWard, geojsonImports, csvImports } = API2.globalStore();
    const currentModel = API2.currentModelStore();


    const [layer, setLayer] = useState('data');
    // const [viewState.zoom, setviewState.zoom] = useState(10);
    // const debounceSetviewState.zoom = debounce(setviewState.zoom, 500);
    const [mapKey, setMapKey] = useState(`${Math.random()}`);

    const [interleaved, setInterleaved] = useState(INTERLEAVE)

    const [viewState, setViewState] = useState(INITIAL_VIEW_STATE)
    const debounceSetViewState = throttle(200, setViewState, { noLeading: true })
    const { layers, updateLayers, layersVisibility, updateLayersVisibility } = API2.globalStore();
    const { merlinAssist, setMerlinAssist, setCollectionVisibility } = API2.globalStore();
    const { urlParams, setUrlParams, zoomScope, setZoomScope } = API2.globalStore();
    const debounceSetZoomScope = throttle(200, setZoomScope, { noLeading: true })

    const [scenegraphModel, setScenegraphModel] = useState(null)

    // const [nexuses, setNexuses] = useState({meshes:[],inputs:[], outputs:[]})
    // useEffect(()=>console.log('canvas changed'),[canvasModels])

    // const NexusMeshes = useMemo(() => , [Object.values(canvasModels)])
    // const NexusMeshNames = useMemo(() => Object.values(canvasModels).map(v => v.name), [canvasModels])

    const NexusMeshes = useMemo(() => {
        let coords = Object.values(canvasModels).map(v => ({ coordinates: v?.coordinates, name: v.name, id: v.id, opacity: currentModel.id === v.id ? 255 : 120, collection: 'NEXUS_AI', asset_id: v.name }))
        let _meta = coords.reduce((acc, cur) => {
            acc[cur.id] = cur
            return acc
        }, {})
        let inputs = Object.values(canvasModels).map(v =>
            Object.values(v.inputs).map(n => [n, _meta[v.id].coordinates, currentModel.id === v.id ? 255 : 160])
        ).flat()
        let outputs = Object.values(canvasModels).map(v =>
            Object.values(v.outputs).map(n => [n, _meta[v.id].coordinates, currentModel.id === v.id ? 255 : 160])
        ).flat()
        // inputs.forEach(el=>console.log('one obj ',el))
        return { coords, inputs: inputs, outputs: outputs }

    }, [Object.values(canvasModels), currentModel])


    const subbarsTransitions = useTransition(page, () => ({
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 }
    }), []);


    const mapRef = useRef();

    // const COILblob = new Blob([COIL]);
    // // const COILobjectURL = URL.createObjectURL(COILblob);
    // const FEATHERblob = new Blob([CARTFEATHER]);

    // const FEATHERobjectURL = URL.createObjectURL(FEATHERblob);

    const refreshCollections = useCallback(() => {
        console.log('collections changes')
        return API.getCollections(r => {
            setCollections(r)
            // return r
        }).then(j => {

            // POSSIBLE BOTTLENECK
            let newAssets = []

            Object.keys(j).forEach(l => {
                API.getAssets(l,
                    (m) => {
                        let filts = Object.values([...(Object.values(m).map(v => ({ ...Object.values(v) })))][0]).map(v => ({ ...v, collection: l, visible: true }))
                        // console.log('filts', filts)
                        newAssets = [...newAssets, ...filts]
                        // console.log('newAssets', newAssets)
                        setAssets(newAssets)
                    })
            })
            // setTimeout(() => console.log('done fetching collections and assets', assets), 5000)
        })
    }, [])

    useEffect(() => {
        // console.log('collections visibility changes', [...(Object.values(collections).map(x => x.visible))])
        const assets_list = assets?.map(a => ({ ...a, visible: collections[a.collection].visible }))
        // console.log(assets_list)
        // console.log(assets_list)
        setAssets(assets_list)

    }, [...(Object.values(collections).map(x => x.visible))])

    // useEffect(() => console.log(assets), [assets])
    // useEffect(() => console.log(assets), [assets])

    useEffect(() => {
        if (page == "platform") {
            // setMapLight('dusk')
            // setTimeout(() => {
            // setMapLight('dusk')
            mapRef.current?.flyTo({ ...INITIAL_VIEW_STATE2, center: [INITIAL_VIEW_STATE2.longitude, INITIAL_VIEW_STATE2.latitude], duration: 6000 })
            mapRef.current?.setConfigProperty('basemap', 'showPlaceLabels', true);
            mapRef.current?.setConfigProperty('basemap', 'showRoadLabels', false);
            mapRef.current?.setConfigProperty('basemap', 'showPointOfInterestLabels', false);
            mapRef.current?.setConfigProperty('basemap', 'showTransitLabels', false);

            // }, 200);
            // setTimeout(() => setMapLight('day'), 3000);
        }

        if (page == "home") {
            setTimeout(() => mapRef.current?.easeTo({ center: [INITIAL_VIEW_STATE3.longitude, INITIAL_VIEW_STATE3.latitude], zoom: INITIAL_VIEW_STATE3.zoom + 0.4, bearing: INITIAL_VIEW_STATE3.bearing - 20, duration: 60000 * 2 }), 800)
        }

        const loadGLBModel = async () => {
            // setScenegraphModel(await load('../../models/cube.glb', GLBLoader))
            // setScenegraphModel(await parse(CUBEGLB, GLBLoader))
        }

        loadGLBModel()
    }, [page])



    const assetFiltered = useMemo(() => {
        let showAlways = Object.values(canvasModels)
            .map(x => [...Object.keys(x.inputs), ...Object.keys(x.outputs)]).flat()
            .map(x => x.split('.')[1])

        console.log(showAlways, assets)

        return assets.filter(x => x.visible || showAlways.includes(x.asset_id))
    }, [assets, collections])

    // useEffect(() => {
    //     if (init === false) {
    //         // loadInfoData("RTEM")
    //         loadInfoData("SCOOT")
    //         loadInfoData("AIRVIRO")
    //         refreshCollections().finally(() => setInit(true))
    //     }
    // }, [init])

    const sphereMesh = new SphereGeometry({
        radius: 20,
        nlat: 10,
        nlong: 10,
    });

    const icosphereMesh = new IcoSphereGeometry({});
    const cubeMesh = new CubeGeometry({})

    const [credits, setCredits] = useState();

    const textLayerProps = {
        getPixelOffset: [0, 25],
        getColor: light === 'dusk' || light === 'night' ? [230, 230, 230, 160] : [20, 20, 20, 240],
        getBackgroundColor: light === 'dusk' || light === 'night' ? [230, 230, 230, 160] : [20, 20, 20, 240],
        getSize: 12,
        getAngle: 0,
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'center',
        background: false,
        getBackgroundColor: [58, 60, 63, 100],
        outlineColor: light === 'dusk' || light === 'night' ? [250, 250, 250, 220] : [0, 0, 0, 0],
        outlineWidth: light === 'dusk' || light === 'night' ? 1 : 0,
        fontSettings: { sdf: true, radius: 12, cutoff: 0.18, smoothing: 0.4 },
        pickable: true
    }

    const textHighlightLayerProps = {
        getPixelOffset: [0, 25],
        getColor: light === 'dusk' || light === 'night' ? [230, 230, 230, 160] : [20, 20, 20, 240],
        getBackgroundColor: light === 'dusk' || light === 'night' ? [20, 20, 20, 240] : [230, 230, 230, 240],
        getSize: 13,
        getAngle: 0,
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'center',
        background: true,
        // getBackgroundColor: [58, 60, 63, 100],
        outlineColor: light === 'dusk' || light === 'night' ? [250, 250, 250, 220] : [0, 0, 0, 0],
        outlineWidth: light === 'dusk' || light === 'night' ? 1 : 0,
        fontSettings: { sdf: true, radius: 12, cutoff: 0.18, smoothing: 0.4 },
        pickable: true,
        backgroundPadding: [3, 3]
    }

    const NexusMeshLayer = new SimpleMeshLayer({
        id: 'nexus-layer',
        visible: true,
        data: NexusMeshes.coords,
        // data: Object.values(canvasModels).map(v => ({ coordinates: v?.coordinates, name: v.name, id: v.id })),
        // data: NexusMeshes.coords,
        // data: Object.values(canvasModels).map(v => ({ coordinates: v?.coordinates, name: v.name, id: v.id })),
        // texture: 'texture.png',
        // wireframe:true,
        mesh: icosphereMesh,
        loaders: [OBJLoader],
        material: NODE_MATERIAL,
        // mesh: new CubeGeometry(),
        getPosition: d => [d.coordinates[0], d.coordinates[1], deckprops.layers.bPht ? 300 : 90],
        getColor: d => [...(light === 'dusk' || light === 'night' ? NEXUSCOLORLIGHT : NEXUSCOLORDARK)],
        getOrientation: d => [0, 0, 0], // middle heading value
        wireframe: false,
        // sizeScale: 1 + (20 - viewState.zoom) * 3,
        sizeScale: 1 + (20 - viewState.zoom) * 7,
        // getScale: d => [1 + 0.2 * d.modelSize, 1 + 0.2 * d.modelSize, 1 + 0.2 * d.modelSize],
        pickable: true,
        autoHighlight: true,
        opacity: 1.0,
        // updateTriggers: {
        //     data: API2.currentModelStore()
        // },
        // onClick: (info, event) => {
        //     // console.log(info, event)
        //     currentModel.setCurrentModel(canvasModels[info.object.id])
        //     return mapRef.current?.flyTo({
        //         center: [...info.object.coordinates, 200],
        //         essential: true,
        //         duration: 2000,
        //         zoom: 16,
        //         pitch: 30,
        //         // ...value
        //         // bearing: viewState.zoom > 12 ? 0 : null

        //     })
        // },
        onClick: (info, event) => {
            currentModel.setCurrentModel(canvasModels[info.object.id])
            setFocusNodeFly({
                // collection,
                name: [info.object].name,

                node: {
                    ...info.object,
                    meta: {
                        algorithm: canvasModels[info.object.id]?.algorithm.algo,
                        outputs: Object.keys(canvasModels[info.object.id]?.outputs).length,
                        inputs: Object.keys(canvasModels[info.object.id]?.inputs).length,
                        last_Accuracy: canvasModels[info.object.id]?.trainingData.lastAccuracy,
                        status: currentModel?.modelBinary ? "TRAINED" : 'UNTRAINED',
                    },
                    actions: {
                        analytics: () => {
                            console.log('analystics clicked')
                            setCanvasMode('analytics')
                            setFocusNodeFly(null)
                        }
                    },
                },
                longitude: info.object.coordinates[0],
                latitude: info.object.coordinates[1],
                altitude: deckprops.layers.bPht ? 400 : 0,
                hosted: true,
                zoom: 16,
                pitch: 30,
            })
        }
    })

    const NexusMeshWireframeLayer = new SimpleMeshLayer({
        id: 'nexus-layer',
        visible: true,
        data: NexusMeshes.coords,
        // data: Object.values(canvasModels).map(v => ({ coordinates: v?.coordinates, name: v.name, id: v.id })),
        // data: NexusMeshes.coords,
        // data: Object.values(canvasModels).map(v => ({ coordinates: v?.coordinates, name: v.name, id: v.id })),
        // texture: 'texture.png',
        // wireframe:true,
        mesh: icosphereMesh,
        loaders: [OBJLoader],
        // mesh: new CubeGeometry(),
        getPosition: d => [d.coordinates[0], d.coordinates[1], deckprops.layers.bPht ? 300 : 90],
        getColor: d => [30, 30, 30, focusNode?.name === d ? 255 : ASSETOPACITY],
        getOrientation: d => [0, 0, 0], // middle heading value
        wireframe: true,
        sizeScale: 1 + (20 - viewState.zoom) * 7,
        // getScale: d => [1 + 0.2 * d.modelSize, 1 + 0.2 * d.modelSize, 1 + 0.2 * d.modelSize],
        pickable: false,
        opacity: 0.1,

    })


    const NexusInputLinkLayer = new ArcLayer({
        id: 'nexus-ilink-layer',
        data: NexusMeshes.inputs,
        // data: Object.keys(currentModel.inputs),
        getTargetPosition: k => [k[0].node.coordinates.long, k[0].node.coordinates.lat, deckprops.layers.bPht ? 210 : 0],
        getSourcePosition: k => [...k[1], deckprops.layers.bPht ? 290 : 80],
        getSourceColor: k => light === 'dusk' || light === 'night' ? [240, 240, 240, k[2]] : [80, 80, 80, 80],
        getTargetColor: k => light === 'dusk' || light === 'night' ? [240, 240, 240, k[2]] : [80, 80, 80, 80],
        getHeight: k => 0.05,
        getWidth: 2,
        pickable: false,
        greatCircle: false,
        numSegments: 20,
        widthMinPixels: 1,
        // getTilt:2
    });

    const NexusOutputLinkLayer = new ArcLayer({
        id: 'nexus-olink-layer',
        data: NexusMeshes.outputs,
        getTargetPosition: k => [k[0].node.coordinates.long, k[0].node.coordinates.lat, deckprops.layers.bPht ? 210 : 0],
        getSourcePosition: k => [...k[1], deckprops.layers.bPht ? 290 : 80],
        getSourceColor: k => [...(light === 'dusk' || light === 'night' ? NEXUSCOLORLIGHT : NEXUSCOLORDARK), k[2]],
        getTargetColor: k => [...(light === 'dusk' || light === 'night' ? NEXUSCOLORLIGHT : NEXUSCOLORDARK), k[2]],
        getHeight: k => 0.15,
        getWidth: 2,
        pickable: false,
        greatCircle: false,
        numSegments: 20,
        widthMinPixels: 1
        // getTilt:-2
    });

    const NexusTextLayer = new TextLayer({
        id: 'nexus-text-layer',
        visible: deckprops.layers.labels || viewState.zoom <= 100 ? true : false,
        data: NexusMeshes.coords,
        getPosition: d => [d.coordinates[0], d.coordinates[1], deckprops.layers.bPht ? 290 : 80],
        getText: d => 'NEXUS_AI\n' + d.name,
        ...textLayerProps,
        getColor: d => [textLayerProps.getColor[0], textLayerProps.getColor[0], textLayerProps.getColor[0], Math.min(d.opacity * 1.4, 255)],
        getPixelOffset: [0, 30],
        getSize: 14,
        // background: true,
        // backgroundPadding: [10, 5],
        // ...textHighlightLayerProps
    });


    const MerlinWardMeshLayer = new SimpleMeshLayer({
        id: 'merlin-layer',
        visible: true,
        data: [merlinWard],
        // texture: 'texture.png',
        mesh: icosphereMesh,
        loaders: [OBJLoader],
        material: NODE_MATERIAL,
        // mesh: new CubeGeometry(),
        getPosition: d => [d.coordinates[0], d.coordinates[1], 130],
        getColor: d => [65, 105, 225, 255],
        getOrientation: d => [0, 0, 0], // middle heading value
        wireframe: false,
        sizeScale: 1 + (20 - viewState.zoom) * 10,
        // getScale: d => [1 + 0.2 * d.modelSize, 1 + 0.2 * d.modelSize, 1 + 0.2 * d.modelSize],
        pickable: true,
        autoHighlight: true,
        opacity: 1.0,
        // updateTriggers: {
        //     data: API2.currentModelStore()
        // },
        // onClick: (info, event) => mapRef.current?.flyTo({
        //     center: [...currentModel.coordinates, 200],
        //     essential: true,
        //     duration: 2000,
        //     zoom: 16,
        //     pitch: 30,
        //     // ...value
        //     // bearing: viewState.zoom > 12 ? 0 : null
        // })
    })

    const MerlinWardTextLayer = new TextLayer({
        id: 'merlin-ward-text-layer',
        visible: true,
        data: [merlinWard],
        getPosition: d => [d.coordinates[0], d.coordinates[1], 120],
        getText: d => "Merlin's Ward",
        ...textLayerProps,
        getPixelOffset: [0, 30],
        getSize: 14,
        // background: true,
        // backgroundPadding: [10, 5]
    });


    // const AssetAnimatedMeshLayer = (collection, settings) => new ScenegraphLayer({
    //     id: `${collection}-animated-layer`,
    //     visible: collections[collection].visible && page !== 'home' ? true : false,
    //     data: Object.keys(assets[collection]),
    //     // texture: 'texture.png',
    //     // mesh: deckprops.layers.cubes ? icosphereMesh : parse(COIL, OBJLoader),
    //     scenegraph: scenegraphModel, // 'https://raw.githubusercontent.com/Najiy/diatomic/refs/heads/master/src/models/cube.glb?token=GHSAT0AAAAAACXJVEVVTWKKMQDUL3TN6ICYZXRZMLQ', //scenegraphModel, // 'https://raw.githubusercontent.com/KhronosGroup/glTF-Sample-Models/master/2.0/BoxAnimated/glTF-Binary/BoxAnimated.glb', //scenegraphModel,
    //     // mesh: new CubeGeometry(),
    //     getPosition: d => [assets[collection][d]?.coordinates?.long, assets[collection][d]?.coordinates?.lat, deckprops.layers.bPht ? 210 : 0],
    //     // getColor: d => [...settings.nodecolor, focusNode?.name === d ? 255 : ASSETOPACITY],
    //     getOrientation: d => [0, 0, 90], // middle heading value
    //     // wireframe: false,
    //     sizeScale: 10, // (1 + (20 - viewState.zoom) * 3) * (deckprops.layers.cubes ? 1 : 0.01),
    //     _animations: {
    //         '*': { speed: 2 }
    //     },
    //     _lighting: 'pbr',
    //     pickable: true,
    //     autoHighlight: true,
    //     opacity: 0.6,
    //     // updateTriggers: {
    //     //     data: assets
    //     // },
    //     onClick: (info, event) => {
    //         console.log(assets, info.object)
    //         // Ensure this is for the correct collection
    //         if (collection === 'aq_airviro') {
    //             console.log('aq_airviro node clicked');
    //         } else if (collection === 'aq_airly') {
    //             console.log('aq_airly node clicked');
    //         }
    //         setFocusNodeFly({
    //             collection,
    //             name: [info.object][0],
    //             node: assets[collection][[info.object][0]],
    //             longitude: assets[collection][[info.object][0]]?.coordinates?.long,
    //             latitude: assets[collection][[info.object][0]]?.coordinates?.lat,
    //             altitude: deckprops.layers.bPht ? 400 : 0,
    //             hosted: true,
    //             zoom: viewState.zoom > 17 ? viewState.zoom : 17,
    //             pitch: 45,
    //         })
    //     },
    //     ...settings
    // })

    const AssetCompositeLayer = new AssetsCompositeLayer({
        data: assetFiltered,
        elevation: deckprops.layers.bPht ? 210 : 0,
        sizeScale: (1 + (20 - viewState.zoom) * 3) * (deckprops.layers.cubes ? 1 : 0.01),
        darkMode: light === 'dusk' || light === 'night' ? true : false,
        labels: page !== 'home' && (deckprops.layers.labels || viewState.zoom > 14) ? true : false,
        collectionLabel: viewState.zoom >= 16,
        colors: {
            'TRAFFIC_RTEM': COLORS.RTEM,
            'AQ_AIRVIRO': COLORS.AIRVIRO,
            'AQ_AIRLY': COLORS.AIRVIRO,
            'WIREFRAME': [30, 30, 30]
        },
        darkColors: {
            'TRAFFIC_RTEM': COLORS.RTEM,
            'AQ_AIRVIRO': COLORS.AIRVIRO,
            'AQ_AIRLY': COLORS.AIRVIRO,
            'WIREFRAME': [120, 120, 120]
        },
        focusNode: focusNode,
        onClick: (object) => {
            console.log('clicked focus node', object)
            setFocusNodeFly({
                // collection,
                name: object.asset_id,
                node: object,
                longitude: object?.coordinates?.long,
                latitude: object?.coordinates?.lat,
                altitude: deckprops.layers.bPht ? 400 : 0,
                hosted: true,
                zoom: viewState.zoom > 17 ? viewState.zoom : 17,
                pitch: 45,
            })
        }
    })



    const AssetMeshLayer = new SimpleMeshLayer({
        id: `asset-layer`,
        // visible: collections[collection].visible && page !== 'home' ? true : false,
        data: assets,
        // texture: 'texture.png',
        mesh: deckprops.layers.cubes ? icosphereMesh : parse(COIL, OBJLoader),
        loaders: [OBJLoader],
        material: NODE_MATERIAL,
        // mesh: new CubeGeometry(),
        getPosition: d => [d?.coordinates?.long, d?.coordinates?.lat, deckprops.layers.bPht ? 210 : 0],
        getColor: d => {
            let color = null

            if (d.collection.toUpperCase().includes('TRAFFIC_'))
                color = COLORS.RTEM
            if (d.collection.toUpperCase().includes('AQ_'))
                color = COLORS.AIRVIRO

            return [...color, focusNode?.name === d ? 255 : ASSETOPACITY]
        },
        getOrientation: d => [0, 0, 90], // middle heading value
        wireframe: false,
        sizeScale: (1 + (20 - viewState.zoom) * 3) * (deckprops.layers.cubes ? 1 : 0.01),
        pickable: true,
        autoHighlight: true,
        opacity: 0.6,
        updateTriggers: {
            sizeScale: [viewState.zoom],
            getColor: [focusNode],
            getPosition: [deckprops.layers.bPht],
            mesh: [deckprops.layers.cubes]
        },

        onClick: (info, event) => {
            setFocusNodeFly({
                // collection,
                name: [info.object][0],
                node: info.object,
                longitude: info.object?.coordinates?.long,
                latitude: info.object?.coordinates?.lat,
                altitude: deckprops.layers.bPht ? 400 : 0,
                hosted: true,
                zoom: viewState.zoom > 17 ? viewState.zoom : 17,
                pitch: 45,
            })
        }
    })

    const AssetWireframeMeshLayer = new SimpleMeshLayer({
        id: `asset-wireframe-layer`,
        // visible: collections[collection].visible && page !== 'home' ? true : false,
        data: assets,
        // texture: 'texture.png',
        mesh: deckprops.layers.cubes ? icosphereMesh : parse(COIL, OBJLoader),
        loaders: [OBJLoader],
        // mesh: new CubeGeometry(),
        getPosition: d => [d?.coordinates?.long, d?.coordinates?.lat, deckprops.layers.bPht ? 210 : 0],
        getColor: d => [30, 30, 30, focusNode?.name === d ? 150 : ASSETOPACITY],
        getOrientation: d => [0, 0, 90], // middle heading value
        wireframe: true,
        sizeScale: (1 + (20 - viewState.zoom) * 3) * (deckprops.layers.cubes ? 1 : 0.01),
        opacity: 0.1
    })

    const AssetTextLayer = new TextLayer({
        id: 'asset-text-layer',
        visible: page !== 'home' && (deckprops.layers.labels || viewState.zoom > 14) ? true : false,
        data: assets,
        getPosition: d => [d?.coordinates?.long, d?.coordinates?.lat, deckprops.layers.bPht ? 210 : 0],
        getText: d => (viewState.zoom >= 16 ? d.collection + '\n' + d.asset_id : d.asset_id).toUpperCase(),
        updateTriggers: {
            getText: [viewState.zoom]
        },
        ...textLayerProps,
    });


    const scootlayer = new SimpleMeshLayer({
        id: 'scoot-layer',
        visible: datalist["SCOOT"].active && datastates["SCOOT"] && page !== 'home' ? true : false,
        data: Object.keys(datastates["SCOOT"].info),
        // texture: 'texture.png',
        mesh: deckprops.layers.cubes ? new IcoSphereGeometry() : parse(COIL, OBJLoader),
        material: NODE_MATERIAL,
        // mesh: parse(COIL, OBJLoader),
        loaders: [OBJLoader],
        // mesh: new CubeGeometry(),
        getPosition: d => [datastates["SCOOT"].info[d].Coords.longitude, datastates["SCOOT"].info[d].Coords.latitude, deckprops.layers.bPht ? 210 : 0],
        getColor: d => [...COLORS.SCOOT, ASSETOPACITY / 4],
        getOrientation: d => [0, datastates["SCOOT"].info[d].Head, 90],
        wireframe: false,
        // sizeScale: viewState.zoom * 0.55 * 0.01,
        // sizeScale: viewState.zoom * 0.55 * 2.5,
        sizeScale: (1 + (20 - viewState.zoom) * 3) * (deckprops.layers.cubes ? 1 : 0.01),
        pickable: true,
        autoHighlight: true,
        // opacity: 0.7,
        // opacity: deckprops.layers.cubes ? 0.7 : 0.6,
        onClick: (info, event) => {
            setFocusNodeFly({
                node: datastates["SCOOT"].info[info.object].SCN, longitude: datastates["SCOOT"].info[info.object].Coords.longitude,
                latitude: datastates["SCOOT"].info[info.object].Coords.latitude,
                altitude: deckprops.layers.bPht ? 400 : 0
            });
        }
    });

    const goog3dtiles = new Tile3DLayer({
        id: 'google-3d-tiles',
        // data: TILESET_URL,
        data: "https://tile.googleapis.com/v1/3dtiles/root.json?key=" + process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        visible: deckprops.layers.bPht,
        onTilesetLoad: tileset3d => {
            tileset3d.options.onTraversalComplete = selectedTiles => {
                const uniqueCredits = new Set();
                selectedTiles.forEach(tile => {
                    const { copyright } = tile.content.gltf.asset;
                    copyright.split(';').forEach(uniqueCredits.add, uniqueCredits);
                });
                setCredits([...uniqueCredits].join('; '));
                return selectedTiles;
            };
        },
        loadOptions: {
            fetch: { headers: { 'X-GOOG-API-KEY': GOOGLE_MAPS_API_KEY } }
        },
        operation: 'terrain+draw'
    })


    const textLayerSCOOT = new TextLayer({
        id: 'scoot-text-layer',
        visible: datalist["SCOOT"].active && page !== 'home' && (deckprops.layers.labels || viewState.zoom > 14) ? true : false,
        data: Object.keys(datastates["SCOOT"].info),
        getPosition: d => [datastates["SCOOT"].info[d].Coords.longitude, datastates["SCOOT"].info[d].Coords.latitude, deckprops.layers.bPht ? 210 : 0],
        getText: d => d,
        ...textLayerProps

    });


    // const geojson = (name, data) => new GeoJsonLayer({
    //     id: name + '-geo-layer',
    //     data: data,

    //     stroked: false,
    //     filled: true,
    //     pointType: 'circle+text',
    //     pickable: true,

    //     getFillColor: [160, 160, 180, 200],
    //     getLineColor: (f) => {
    //         const hex = f.properties.color;
    //         // convert to RGB
    //         return hex ? hex.match(/[0-9a-f]{2}/g).map(x => parseInt(x, 16)) : [0, 0, 0];
    //     },
    //     getText: (f) => f.properties.name,
    //     getLineWidth: 20,
    //     getPointRadius: 4,
    //     getTextSize: 12
    // });

    // const WardsLayer = (name) => new GeoJsonLayer({
    //     id: name + '-geo-layer',
    //     data: Wards,

    //     stroked: false,
    //     filled: true,
    //     pointType: 'circle+text',
    //     pickable: true,

    //     getFillColor: (f) => [Math.random() * 255, Math.random() * 255, Math.random() * 255, 80],
    //     getLineColor: (f) => {
    //         const hex = f.properties.color;
    //         // convert to RGB
    //         return [Math.random() * 255, Math.random() * 255, Math.random() * 255];
    //     },
    //     getText: (f) => JSON.stringify(f.properties),
    //     getLineWidth: 20,
    //     getPointRadius: 4,
    //     getTextSize: 12,
    //     onClick: (event) => { console.log(event.object.properties.wd19nm); return true; }
    // });



    const hexRadius = (zoom) => {
        switch (zoom) {
            case 0: return 700;
            case 1: return 3000;
            case 2: return 15000;
        }
    }

    const hexlayer = new HexagonLayer({
        id: 'HexagonLayer',
        data: assetFiltered,
        extruded: false,
        colorRange: light === 'dusk' || light === 'night' ? [COLORS.NEXUS3] : [COLORS.NEXUS3],
        getPosition: d => [d?.coordinates?.long, d?.coordinates?.lat],
        getColorWeight: d => 1,
        getElevationWeight: d => 1,
        elevationScale: 1,
        radius: hexRadius(zoomScope),
        pickable: false,
        opacity: Math.min(54688.752 * Math.exp(-0.8 * (viewState.zoom + 7)), 0.3),//54688.752 * Math.exp(Math.E, -0.928 * viewState.zoom)
        //−0.928⋅x 0.1 * Math.pow(2, 1-(viewState.zoom/20)) // (1 - (viewState.zoom / 20)) * 0.8,
        updateTriggers: {
            getPosition: [viewState.zoom],
            data: [viewState.zoom]
        }
    });

    // [[17, 0], [13.5, 0.2], [12, 0.8]]

    // new HexagonLayer({
    //     id: layername + '-hex-layer',
    //     // data: Object.values(data || {}),
    //     data: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/sf-bike-parking.json',

    //     /* props from HexagonLayer class */

    //     // colorAggregation: 'SUM',
    //     // colorDomain: null,
    //     // colorRange: [[255, 255, 178], [254, 217, 118], [254, 178, 76], [253, 141, 60], [240, 59, 32], [189, 0, 38]],
    //     // colorScaleType: 'quantize',
    //     // coverage: 1,
    //     // elevationAggregation: 'SUM',
    //     // elevationDomain: null,
    //     // elevationLowerPercentile: 0,
    //     // elevationRange: [0, 1000],
    //     // elevationScale: 4,
    //     // elevationScaleType: 'linear',
    //     // elevationUpperPercentile: 100,
    //     // extruded: false,
    //     // getColorValue: null,
    //     // getColorWeight: 1,
    //     // getElevationValue: null,
    //     // getElevationWeight: 1,
    //     // getPosition: d => d.COORDINATES,
    //     // hexagonAggregator: null,
    //     // lowerPercentile: 0,
    //     // material: true,
    //     // onSetColorDomain: null,
    //     // onSetElevationDomain: null,
    //     // radius: 200,
    //     // upperPercentile: 100,

    //     /* props inherited from Layer class */

    //     extruded: true,
    //     getPosition: d => {
    //         // alert(JSON.stringify([d.coordinates.long, d.coordinates.lat], null, 2))
    //         return [d.coordinates.long, d.coordinates.lat]

    //         // alert(JSON.stringify([d.coordinates.lat, d.coordinates.long], null, 2))
    //         // return [d.coordinates.lat, d.coordinates.long]
    //     },
    //     getColorWeight: d => 4,
    //     getElevationWeight: d => 4,
    //     elevationScale: 4,
    //     radius: 50,

    //     // autoHighlight: false,
    //     // coordinateOrigin: [0, 0, 0],
    //     // coordinateSystem: COORDINATE_SYSTEM.LNGLAT,
    //     // highlightColor: [0, 0, 128, 128],
    //     // modelMatrix: null,
    //     // opacity: 0.8,
    //     pickable: true,
    //     // visible: true,
    //     // wrapLongitude: false,
    // });





    const [draggableStates, setDraggableStates] = useState({
        activeDrags: 0,
        deltaPosition: {
            x: 0, y: 0
        },
        controlledPosition: {
            x: -400, y: 200
        }
    })

    const onStart = () => {
        setDraggableStates({ activeDrags: ++draggableStates.activeDrags });
    };

    const onStop = () => {
        setDraggableStates({ activeDrags: --draggableStates.activeDrags });
    };

    const dragHandlers = { onStart, onStop };

    const setMapLight = (light) => {
        const mode = (light) => {
            switch (light) {
                case 'dawn':
                    return {
                        "range": [0.8, 8],
                        "color": "#dc9f9f",
                        "horizon-blend": 0.15,
                        // "high-color": "#8ec7e8",
                        "high-color": "#245bde",
                        "space-color": "#000000",
                        "star-intensity": 0.25
                    }
                case 'day':
                    return {
                        "star-intensity": 0.05
                    }
                case 'dusk':
                    return {
                        "range": [0.8, 8],
                        "color": "#dc9f9f",
                        "horizon-blend": 0.2,
                        "high-color": "#245bde",
                        "space-color": "#000000",
                        "star-intensity": 0.45
                    }
                case 'night':
                    return {
                        "range": [0.8, 8],
                        "color": "#001d3b",
                        "horizon-blend": 0.03,
                        "high-color": "#245bde",
                        "space-color": "#000000",
                        "star-intensity": 0.65
                    }
                default:
                    return {
                    }
            }
        }
        setLight(light)
        setFog(mode(light))
        if (light !== mapRef.current?.getConfigProperty('basemap', 'lightPreset')) {
            mapRef.current?.setConfigProperty('basemap', 'lightPreset', light)
            mapRef.current?.getMap().setFog(mode(light))
        }
    }

    const debounceSetMapLight = debounce(200, setMapLight)


    const onMapLoad = useCallback(() => {
        mapRef.current.once('load', () => {
            // console.log('move map', mapRef)
            debounceSetMapLight('night');
            mapRef.current?.setConfigProperty('basemap', 'showPlaceLabels', false);
            mapRef.current?.setConfigProperty('basemap', 'showRoadLabels', false);
            mapRef.current?.setConfigProperty('basemap', 'showPointOfInterestLabels', false);
            mapRef.current?.setConfigProperty('basemap', 'showTransitLabels', false);
        })

        mapRef.current.once('load', () => {
            if (init === false) {
                // loadInfoData("RTEM")
                // loadInfoData("SCOOT")
                // loadInfoData("AIRVIRO")
                refreshCollections().finally(() =>
                    setTimeout(() => setInit(true), 600))
            }
        })

    }, [])


    const setFocusNodeFly = (value) => {
        value && setFocusNode(value)
        // console.log(value)
        value && mapRef.current?.easeTo({
            center: [value.longitude, value.latitude, deckprops.layers.bPht ? 200 : 0],
            essential: true,
            duration: 2000,
            zoom: 16,
            pitch: 35,
            ...value
            // ...value
            // bearing: viewState.zoom > 12 ? 0 : null
        })
    }

    const [fog, setFog] = useState({
        "range": [0.8, 8],
        "color": "#dc9f9f",
        "horizon-blend": 0.2,
        "high-color": "#245bde",
        "space-color": "#000000",
        "star-intensity": 0.45
    })


    const [canvasRef, canvasVisible] = useElementOnScreen()
    const [importFile, setImportFile] = useState(true)
    const [hexCoverage, setHexCoverage] = useState(true)

    // const [canvasState, setCanvasState] = useState('')


    let isHovering = false;
    let lightTransitEffects = true;

    return <div onClick={() => setCtxMenuPos([0, 0])}>

        {/* USER LOGO */}
        <div style={{ display: page === 'platform' && init ? 'block' : 'none' }}>

            <DTooltip title="Login Account">
                <IconButton style={{
                    ...verticalCenter,
                    ...backgroundStyle,
                    border: 'solid',
                    // color: lightColor.backgroundColor,
                    borderRadius: '50%',
                    borderWidth: 'medium',
                    // marginRight: '0px',
                    top: '5px',
                    height: '75px',
                    width: '75px',
                    position: 'absolute',
                    right: '45px',
                    transform: '',
                    background: 'rgba(97,97,97,0.98)',
                    zIndex: 50,
                    // color: 'rgb(255 255 255 / 98%)',
                    color: colourAccent
                }} onClick={() => { }} >
                    {/* <FullscreenIcon /> */}
                    <Diversity2Icon style={{ color: lightColor.backgroundColor, transform: 'scale(1.4)' }} />
                </IconButton>
            </DTooltip>

            {/* TOPBAR */}
            <div style={{ ...backgroundStyle, width: '100vw', height: '40px', zIndex: 40 }}>

                {/* <DTooltip title="fullscreen">
                    <IconButton style={{ ...verticalCenter, color: lightColor.backgroundColor, borderRadius: '1px', float: 'right', marginRight: '5px', marginLeft: '5px' }} onClick={() => fullscreenToggle()} >
                        <FullscreenIcon />
                    </IconButton>
                </DTooltip> */}


                <DTooltip title="Settings">
                    <IconButton
                        style={{
                            ...verticalCenter, color: lightColor.backgroundColor, borderRadius: '1px', float: 'right', marginLeft: '5px', marginRight: '5px'
                        }}
                        onClick={() => {
                            deckprops.update({ controls: !deckprops.controls })
                        }}
                    >
                        <SettingsIcon />
                    </IconButton>
                </DTooltip>

                <IconButton style={{
                    ...verticalCenter, color: 'rgba(255,255,255,0.8)', borderRadius: '1px',
                    float: 'right', marginLeft: '5px', marginRight: '85px', fontSize: 14
                }}  >
                    {/* {JSON.stringify(Object.keys(assets["traffic_rtem"]), null, 2)} */}
                    COMMUNITY USER
                </IconButton>



                {LEGACY ? <DTooltip title="legacy Demo">
                    <IconButton style={{ ...verticalCenter, color: lightColor.backgroundColor, borderRadius: '1px', float: 'right', marginLeft: '5px' }} onClick={() => setPage('legacy')} >
                        <BlurCircular />
                    </IconButton>
                </DTooltip> : null}

                <DTooltip title="Homepage">
                    <IconButton style={{
                        ...verticalCenter, color: lightColor.backgroundColor, borderRadius: '1px', float: 'left', paddingLeft: '25px', paddingRight: '25px',
                    }} onClick={() => {
                        setPage('home')
                        setWindowOpen(null)
                        setMapLight('night');
                        mapRef.current?.setConfigProperty('basemap', 'showPlaceLabels', false);
                        mapRef.current?.setConfigProperty('basemap', 'showRoadLabels', false);
                        mapRef.current?.setConfigProperty('basemap', 'showPointOfInterestLabels', false);
                        mapRef.current?.setConfigProperty('basemap', 'showTransitLabels', false);
                        // mapRef.current?.flyTo({ ...INITIAL_VIEW_STATE, center: [INITIAL_VIEW_STATE.longitude, INITIAL_VIEW_STATE.latitude] , duration:0})
                        mapRef.current?.easeTo({ ...INITIAL_VIEW_STATE_HOME, center: [INITIAL_VIEW_STATE_HOME.longitude, INITIAL_VIEW_STATE_HOME.latitude] })
                    }} >
                        {/* <HomeIcon /> */}
                        DIATOMIC
                    </IconButton>
                </DTooltip>


                <div syle={{
                    width: '100vw',
                    textAlign: 'center',
                    top: '16px',
                    fontWeight: 'bold',
                    fontSize: 'large'
                }}>{barMessage}</div>

                {/* <DTooltip title="Datasets and Collections"> */}
                    <IconButton style={{ fontSize: '14px', ...verticalCenter, color: windowOpen === 'datasets' ? colourAccent : lightColor.backgroundColor, borderRadius: '1px', float: 'left' }} onClick={() => setWindowOpen('datasets')}>
                        {/* <DatasetIcon /> */}
                        Datasets
                    </IconButton>
                {/* </DTooltip> */}

                {/* <DTooltip title="APIs">
                    <IconButton style={{ fontSize: '14px', ...verticalCenter, color: windowOpen === "apis" ? colourAccent : lightColor.backgroundColor, borderRadius: '1px', float: 'left' }} onClick={() => setWindowOpen('apis')}>
                        <ApiIcon />
                        API Services
                    </IconButton>
                </DTooltip> */}

                {/* <DTooltip title="Case Studies"> */}
                    <IconButton style={{ fontSize: '14px', ...verticalCenter, color: windowOpen === "cases" ? colourAccent : lightColor.backgroundColor, borderRadius: '1px', float: 'left' }} onClick={() => setWindowOpen('cases')}>
                        {/* <InsightsIcon /> */}
                        Case Studies
                        {/* {JSON.stringify(NexusMeshCoordinates)} */}
                    </IconButton>
                {/* </DTooltip> */}

                {/* <DTooltip title="Roadmap"> */}
                    <IconButton style={{ fontSize: '14px', ...verticalCenter, color: windowOpen === "roadmap" ? colourAccent : lightColor.backgroundColor, borderRadius: '1px', float: 'right' }} onClick={() => setWindowOpen('roadmap')}>
                        {/* <InsightsIcon /> */}
                        Roadmap
                    </IconButton>
                {/* </DTooltip> */}

                {/* <DTooltip title="Contribute"> */}
                    <IconButton style={{ fontSize: '14px', ...verticalCenter, color: windowOpen === "contribute" ? colourAccent : lightColor.backgroundColor, borderRadius: '1px', float: 'right' }} onClick={() => setWindowOpen('contribute')}>
                        {/* <InsightsIcon /> */}
                        Contribute
                    </IconButton>
                {/* </DTooltip> */}

                {/* <DTooltip title="Insights">
                    <IconButton style={{ fontSize: '14px', ...verticalCenter, color: windowOpen === "insights" ? colourAccent : lightColor.backgroundColor, borderRadius: '1px', float: 'left' }} onClick={() => setWindowOpen('insights')}>
                        <InsightsIcon />
                        Insights
                    </IconButton>
                </DTooltip> */}

            </div>

            {/* SETTINGS POPUP */}
            {!windowOpen && <div style={{ ...backgroundStyle, borderRadius: '10px', right: '0px', top: '70px', width: '250px', zIndex: 400, display: 'inline-grid' }}>
                {/* <div style={{ fontSize: '2.5vh', placeSelf:'center'}}>Layers</div> */}


                <div style={{ display: deckprops.controls ? 'inline-grid' : 'none' }}>

                    {/* <hr /> */}
                    {/* <div style={{ padding: '10px', textAlign: 'center' }}>SETTINGS</div> */}

                    <IconButton style={{ color: deckprops.layers.bPht ? colourAccent : 'white', ...layerItemStyle }}
                        onClick={() => { deckprops.toggleProps('bPht') }}>
                        Photoreals
                    </IconButton>

                    {/* <hr /> */}

                    {/* <IconButton style={{ color: deckprops.layers.ostraff ? colourAccent : 'white', ...layerItemStyle }}
                            onClick={() => { deckprops.toggleProps('ostraff') }}>
                            Traffic
                        </IconButton> */}

                    <IconButton style={{ color: deckprops.layers.labels ? colourAccent : 'white', ...layerItemStyle }}
                        onClick={() => { deckprops.toggleProps('labels') }}>
                        Labels
                    </IconButton>

                    {/* <IconButton style={{ color: deckprops.layers.cubes ? colourAccent : 'white', ...layerItemStyle }}
                        onClick={() => { deckprops.toggleProps('cubes') }}>
                        Simplify
                    </IconButton> */}

                    {/* <IconButton style={{ color: interleaved ? colourAccent : 'white', ...layerItemStyle }}
                        onClick={() => {
                            let newconfig = !interleaved
                            setInterleaved(newconfig)
                        }}>
                        Interleave {JSON.stringify(hexCoverage)}
                    </IconButton> */}
                </div>
            </div>}

            {false && !windowOpen && <div style={{ ...backgroundStyle, borderBottomRightRadius: '10px', left: '0px', top: '50px', height: '40px', zIndex: 4 }}>

                <DTooltip title="Build ML Models">
                    <IconButton style={{
                        ...verticalCenter, color: canvasMode === 'canvas' ? colourAccent : lightColor.backgroundColor, borderRadius: '1px', float: 'right', marginRight: '5px', fontSize: '12px', width: '100px'
                    }} onClick={() => {
                        setCanvasMode('canvas')
                        setFocusNodeFly(null)
                        mapRef.current?.setConfigProperty('basemap', 'showPlaceLabels', false);
                        mapRef.current?.setConfigProperty('basemap', 'showRoadLabels', false);
                        mapRef.current?.setConfigProperty('basemap', 'showPointOfInterestLabels', false);
                        mapRef.current?.setConfigProperty('basemap', 'showTransitLabels', false);
                    }} >
                        {/* AI */}
                        {/* <CycloneIcon /> */}
                        Build Models
                    </IconButton>
                </DTooltip>

                <DTooltip title="Data Analysis">
                    <IconButton style={{
                        ...verticalCenter, color: canvasMode === 'analytics' || canvasMode === 'analysis' ? colourAccent : lightColor.backgroundColor, borderRadius: '1px', float: 'right', marginRight: '5px', fontSize: '12px', width: '100px'
                    }} onClick={() => {
                        setCanvasMode('analytics')
                        setFocusNodeFly(null)
                    }} >
                        Data Analysis
                    </IconButton>
                </DTooltip>

                <DTooltip title="Explore City View">
                    <IconButton style={{
                        ...verticalCenter, color: !canvasMode ? colourAccent : lightColor.backgroundColor, borderRadius: '1px', float: 'right', marginRight: '5px', fontSize: '12px', width: '100px'
                    }} onClick={() => {
                        setCanvasMode(false)
                        setTimeout(() => {
                            mapRef.current?.setConfigProperty('basemap', 'showPlaceLabels', true);
                            mapRef.current?.setConfigProperty('basemap', 'showRoadLabels', false);
                            mapRef.current?.setConfigProperty('basemap', 'showPointOfInterestLabels', false);
                            mapRef.current?.setConfigProperty('basemap', 'showTransitLabels', false);
                        }, 6000)
                    }} >
                        {/* <ViewInAr /> */}
                        Explore City
                    </IconButton>
                </DTooltip>
            </div>}

            {!windowOpen && currentModel?.name && <div style={{ ...backgroundStyle, borderBottomRightRadius: borderRadius, left: '0px', top: '40px', height: '30px', zIndex: 4, alignContent: 'center', paddingRight: '5px' }}>
                <div style={{ marginLeft: '20px', display: 'inline-flex', paddingTop: '6px' }}>Model: {currentModel?.name}</div>
                <div style={{ marginLeft: '20px', display: 'inline-flex' }}>Outputs: {Object.keys(currentModel?.outputs).length} </div>
                <div style={{ marginLeft: '20px', display: 'inline-flex' }}>Algorithm: {currentModel?.algorithm?.algo || 'N/A'} </div>
                <div style={{ marginLeft: '20px', display: 'inline-flex' }}>Accuracy: {currentModel?.trainingData?.lastAccuracy || 'N/A'}% </div>
                <div style={{ marginLeft: '20px', display: 'inline-flex' }}>Status: {currentModel?.modelBinary ? "TRAINED" : 'UNTRAINED'} </div>
                <div style={{ marginLeft: '20px', display: 'inline-flex' }}>Inputs: {Object.keys(currentModel?.inputs).length} </div>
                {/* <DTooltip title="Data Analysis"> */}
                    <IconButton style={{
                        ...verticalCenter, color: colourAccent, borderRadius: '1px', float: 'right', marginLeft: '5px', marginRight: '5px', fontSize: '12px', width: '100px'
                    }} onClick={() => {
                        setCanvasMode('analytics')
                        setFocusNodeFly(null)
                    }} >
                        Model Analysis
                    </IconButton>
                {/* </DTooltip> */}
                {/* <DTooltip title="Data Analysis"> */}
                    <IconButton style={{
                        ...verticalCenter, color: colourAccent, borderRadius: '1px', float: 'right', marginLeft: '10px', marginRight: '5px', fontSize: '12px', width: '100px'
                    }} onClick={() => {
                        setCanvasMode('canvas')
                        setFocusNodeFly(null)
                    }} >
                        Configure Model
                    </IconButton>
                {/* </DTooltip> */}
            </div>}

            {/* SUB TOPBAR */}
            {!windowOpen && <div style={{ ...backgroundStyle, borderBottomLeftRadius: borderRadius, right: '0px', top: '40px', height: '32px', zIndex: 4 }}>

                <DTooltip title="City View">
                    <IconButton style={{
                        ...verticalCenter, color: !canvasMode ? colourAccent : lightColor.backgroundColor, borderRadius: '1px', float: 'right', marginLeft: '5px', marginRight: '5px'
                    }} onClick={() => {
                        setCanvasMode(false)
                        setTimeout(() => {
                            mapRef.current?.setConfigProperty('basemap', 'showPlaceLabels', true);
                            mapRef.current?.setConfigProperty('basemap', 'showRoadLabels', false);
                            mapRef.current?.setConfigProperty('basemap', 'showPointOfInterestLabels', false);
                            mapRef.current?.setConfigProperty('basemap', 'showTransitLabels', false);
                        }, 6000)
                    }} >
                        <ViewInAr />
                    </IconButton>
                </DTooltip>

                <DTooltip title="Build ML Models">
                    <IconButton style={{
                        ...verticalCenter, color: canvasMode === 'canvas' ? colourAccent : lightColor.backgroundColor, borderRadius: '1px', float: 'right', marginLeft: '5px', marginRight: '85px'
                    }} onClick={() => {
                        setCanvasMode('canvas')
                        setFocusNodeFly(null)
                        mapRef.current?.setConfigProperty('basemap', 'showPlaceLabels', false);
                        mapRef.current?.setConfigProperty('basemap', 'showRoadLabels', false);
                        mapRef.current?.setConfigProperty('basemap', 'showPointOfInterestLabels', false);
                        mapRef.current?.setConfigProperty('basemap', 'showTransitLabels', false);
                    }} >
                        AI
                    </IconButton>
                </DTooltip>


                <div style={{
                    color: lightColor.backgroundColor,
                    borderRadius: '1px',
                    float: 'right',
                    marginLeft: '10px',
                    marginRight: '5px',
                    borderLeft: `1px solid white`,
                    height: '100%',
                    opacity: 0.3,
                }} />


                <DTooltip title="Night">
                    <IconButton style={{
                        ...verticalCenter, color: lightColor.backgroundColor, borderRadius: '1px', float: 'right', marginLeft: '5px',
                        color: light === 'night' ? colourAccent : colourAccentDisabled// animation: canvasMode?'glow2 1s infinite alternate': null
                    }}
                        onClick={() => {
                            setUserSetMapLight('night')
                            debounceSetMapLight('night');
                        }}>
                        <Brightness1Icon />
                    </IconButton>
                </DTooltip>

                <DTooltip title="Dusk">
                    <IconButton style={{
                        ...verticalCenter, color: lightColor.backgroundColor, borderRadius: '1px', float: 'right', marginLeft: '5px',
                        color: light === 'dusk' ? colourAccent : colourAccentDisabled // animation: canvasMode?'glow2 1s infinite alternate': null
                    }}
                        onClick={() => {
                            setUserSetMapLight('dusk')
                            debounceSetMapLight('dusk');
                        }}
                    >
                        <Brightness3Icon />
                    </IconButton>
                </DTooltip>

                <IconButton style={{
                    ...verticalCenter, color: 'rgba(255,255,255,0.8)', borderRadius: '1px', float: 'right', marginLeft: '5px', fontSize: 14,
                    width: '80px'
                }}  >
                    {/* {JSON.stringify(Object.keys(assets["traffic_rtem"]), null, 2)} */}
                    {date.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' })}
                </IconButton>

                <DTooltip title="Day">
                    <IconButton style={{
                        ...verticalCenter, color: lightColor.backgroundColor, borderRadius: '1px', float: 'right', marginLeft: '5px',
                        color: light === 'day' ? colourAccent : colourAccentDisabled// animation: canvasMode?'glow2 1s infinite alternate': null
                    }}
                        onClick={() => {
                            setUserSetMapLight('day')
                            debounceSetMapLight('day');
                        }}
                    >
                        <Brightness7Icon />
                    </IconButton>
                </DTooltip>

                <DTooltip title="Dawn">
                    <IconButton style={{
                        ...verticalCenter, color: lightColor.backgroundColor, borderRadius: '1px', float: 'right', marginLeft: '5px',
                        color: light === 'dawn' ? colourAccent : colourAccentDisabled// animation: canvasMode?'glow2 1s infinite alternate': null
                    }}
                        onClick={() => {
                            // setLight(dawn)
                            setUserSetMapLight('dawn')
                            debounceSetMapLight('dawn');
                        }}
                    >
                        <Brightness5Icon />
                    </IconButton>
                </DTooltip>

            </div>}

            {/* SIDE BAR */}
            {!windowOpen && <div style={{ ...backgroundStyle, position: 'absolute', zIndex: 3, left: 'calc(100vw - 38px)', top: 'calc(70vh - 55px)', borderBottomLeftRadius: '5px', borderTopLeftRadius: '5px', padding: '4px' }}>

                <DTooltip title="Points of Interests" placement="left">
                    <IconButton style={{
                        ...verticalCenter, color: lightColor.backgroundColor, borderRadius: '1px', transform: ''
                        // animation: canvasMode?'glow2 1s infinite alternate': null
                    }}
                        onClick={() => {
                            mapRef.current?.setConfigProperty('basemap', 'showPlaceLabels', true);
                            mapRef.current?.setConfigProperty('basemap', 'showRoadLabels', true);
                            mapRef.current?.setConfigProperty('basemap', 'showPointOfInterestLabels', true);
                            mapRef.current?.setConfigProperty('basemap', 'showTransitLabels', true);
                        }}
                        onMouseLeave={() => {
                            setTimeout(() => {
                                mapRef.current?.setConfigProperty('basemap', 'showPlaceLabels', true);
                                mapRef.current?.setConfigProperty('basemap', 'showRoadLabels', false);
                                mapRef.current?.setConfigProperty('basemap', 'showPointOfInterestLabels', false);
                                mapRef.current?.setConfigProperty('basemap', 'showTransitLabels', false);
                            }, (8000));
                        }}
                    >
                        <PlaceIcon />
                    </IconButton>
                </DTooltip>

                <DTooltip title="Hex Coverage" placement="left">
                    <IconButton style={{
                        ...verticalCenter, color: lightColor.backgroundColor, borderRadius: '1px', transform: ''
                        // animation: canvasMode?'glow2 1s infinite alternate': null
                    }}
                        onClick={() => { setHexCoverage(!hexCoverage) }}
                    >
                        <HexagonIcon />
                    </IconButton>
                </DTooltip>

                <DTooltip title="Import File" placement="left">
                    <IconButton
                        style={{
                            ...verticalCenter, color: lightColor.backgroundColor, borderRadius: '1px', transform: ''
                        }} onClick={() => {
                            setWindowOpen('import')
                        }} >
                        <FileUploadIcon />
                    </IconButton>
                </DTooltip>

                {urlParams?.merlin && <DTooltip title="Merlin Assist" placement="left">
                    <IconButton
                        style={{
                            ...verticalCenter, color: lightColor.backgroundColor, borderRadius: '1px', transform: ''
                        }} onClick={() => {
                            setMerlinAssist()
                        }} >
                        M
                    </IconButton>
                </DTooltip>}

                {urlParams?.merlin && <DTooltip title="Merlin Assist" placement="left">
                    <IconButton
                        style={{
                            ...verticalCenter, color: lightColor.backgroundColor, borderRadius: '1px', transform: ''
                        }} onClick={() => {
                            setMerlinAssist()
                        }} >
                        M
                    </IconButton>
                </DTooltip>}


                <DTooltip title="Google Maps Photorealistic" placement="left">
                    <IconButton style={{
                        ...verticalCenter, color: lightColor.backgroundColor, borderRadius: '1px', transform: ''
                    }}
                        onClick={() => { deckprops.toggleProps('bPht') }}>
                        P
                    </IconButton>
                </DTooltip>

                <DTooltip title="Asset Labels" placement="left">
                    <IconButton style={{
                        ...verticalCenter, color: lightColor.backgroundColor, borderRadius: '1px', transform: ''
                    }}
                        onClick={() => { deckprops.toggleProps('labels') }}>
                        L
                    </IconButton>
                </DTooltip>


            </div>}

            {/* SIDE BAR */}
            {!windowOpen && <div style={{ ...backgroundStyle, position: 'absolute', zIndex: 3, top: '20vh', borderBottomRightRadius: '5px', borderTopRightRadius: '5px', boxShadow: '1px 1px 6px black' }}>
                <div style={{ height: '20vh', width: '4px', backgroundColor: zoomScope === 0 ? 'darkcyan' : 'transparent', borderTopRightRadius: '2px' }} />
                <div style={{ height: '20vh', width: '4px', backgroundColor: zoomScope === 1 ? 'darkcyan' : 'transparent', }} />
                <div style={{ height: '20vh', width: '4px', backgroundColor: zoomScope === 2 ? 'darkcyan' : 'transparent', borderBottomRightRadius: '2px' }} />
            </div>}



            <Transitioner open={canvasMode === 'infer' && page === 'platform' && trainingStage === null} springConfig={{ mass: 5, tension: 2000, friction: 200 }}>
                {/* <Draggable {...dragHandlers} positionOffset={{ x: 'calc(0vw)', y: 'calc(100vh - 100px)' }}> */}
                {/* <div  > */}
                <InferenceView style={{ zIndex: 4, position: 'absolute', top: '50px' }} />
                {/* </div> */}
                {/* </Draggable> */}
            </Transitioner>

            <Transitioner open={canvasMode === 'canvas' && page === 'platform' && trainingStage === null} springConfig={{ mass: 5, tension: 2000, friction: 200 }} >
                <Draggable {...dragHandlers} positionOffset={{ x: 'calc(100vw - 630px)', y: '100px' }}>
                    <div  >
                        <CanvasView style={{ zIndex: 4 }} focusCb={(coordinates) =>
                            mapRef.current?.flyTo({
                                center: [...coordinates, 200],
                                essential: true,
                                duration: 2000,
                                zoom: 16,
                                pitch: 30,
                                // bearing: 0
                                // ...value
                                // bearing: viewState.zoom > 12 ? 0 : null
                            })} />
                    </div>
                </Draggable>
            </Transitioner>

            <Transitioner open={focusNode} springConfig={{ mass: 5, tension: 2000, friction: 200 }}>
                <Draggable {...dragHandlers} positionOffset={{ x: 'calc(10px)', y: '100px' }}>
                    <div  >
                        <AssetInfo style={{ zIndex: 5, width: 380 }} {...focusNode} canvasMode={canvasMode} focusCb={(n) => mapRef.current?.easeTo(n)} />
                    </div>
                </Draggable>
            </Transitioner>

            <Transitioner open={canvasMode === 'analytics' || canvasMode === 'analysis'} springConfig={{ mass: 5, tension: 2000, friction: 200 }}>
                <Draggable {...dragHandlers} positionOffset={{ x: 'calc((100vw - 1200px) / 2)', y: '100px' }}>
                    <div  >
                        <Analytics style={{ zIndex: 5, width: 600 }} {...focusNode} canvasMode={canvasMode} focusCb={(n) => mapRef.current?.easeTo(n)} />
                    </div>
                </Draggable>
            </Transitioner>

            <Transitioner open={!canvasMode && urlParams?.merlin} springConfig={{ mass: 5, tension: 2000, friction: 200 }}>
                <Draggable {...dragHandlers} positionOffset={{ x: 'calc(100vw - 580px)', y: '100px' }}>
                    <div  >
                        <MerlinInsights />
                    </div>
                </Draggable>
            </Transitioner>

            <Transitioner open={merlinAssist} springConfig={{ mass: 5, tension: 2000, friction: 200 }}>
                <Draggable {...dragHandlers} positionOffset={{ x: 'calc(10px)', y: '60px' }}>
                    <div  >
                        <Merlin style={{
                            zIndex: 5, position: 'absolute',
                            borderRadius: '8px',
                            maxWidth: '400px',
                            maxHeight: 'min(calc(100vh - 167px), 800px)',
                            minWidth: '330px',
                            padding: '15px',
                            overflowY: 'auto'
                        }}
                            callbacks={{
                                GOTO: (coordinates) => {
                                    setMerlinWard({ coordinates })
                                    mapRef.current?.flyTo({
                                        center: [...coordinates, 200],
                                        essential: true,
                                        duration: 2000,
                                        zoom: 12,
                                        pitch: 30,
                                        bearing: 0
                                        // ...value
                                        // bearing: viewState.zoom > 12 ? 0 : null
                                    })
                                },
                                CLEARVIEW: () => {
                                    console.log('CLEARVIEW')
                                    // let newCollections = collections
                                    // Object.keys(collections).forEach((v, i) => newCollections[v] = { ...collections[v], visibility: false })
                                    Object.keys(collections).forEach((k, i) => updateLayersVisibility(k, false))
                                    Object.keys(collections).forEach((k, i) => setCollectionVisibility(k, false))
                                    // setCollections(newCollections)
                                },
                                LIGHTPRESET: (lightmode) => {
                                    // setUserSetMapLight(lightmode.toLowerCase())
                                    debounceSetMapLight(lightmode.toLowerCase());
                                }
                            }} />
                    </div>
                </Draggable>
            </Transitioner>


            {/* WINDOW HANDLES */}
            {/* <div style={{ ...backgroundStyle, zIndex: 4, width: '100vw', left: '0vw', height: 'calc(100vh - 50px)', top: '50px' }}> */}
            <Transvoider open={windowOpen !== null} springConfig={{ mass: 5, tension: 2000, friction: 200 }} >
                <div style={{ ...backgroundStyle, zIndex: 10, width: '100vw', top: '0px', left: '0vw', height: '100vh', padding: '0px', pointerEvents: !windowOpen ? 'none' : 'auto' }}>
                    <Window show={windowOpen === 'datasets'} title={'Datasets'} children={<Datasets />} />
                    <Window show={windowOpen === 'apis'} title={'API Services'} children={<APIs />}
                        description={"These are our collection of integrated API services. They are made available for you on our AI canvas so you can make your own machine learning models."} />
                    <Window show={windowOpen === 'insights'} title={'Insights'} children={<Insights />}
                        description={"Sift through our collection of published analytics canvases and dashboards."} />
                    <Window show={windowOpen === 'cases'} title={'Case Studies'} children={<Cases />}
                        description={"These are showcasings of what our platform can achieve using our digital services."} />
                    <Window show={windowOpen === 'import'} title={'File Import'} children={<FileImport close={() => setWindowOpen(null)} />} />
                    <Window show={windowOpen === 'contribute'} title={'Contribute'} children={<Contribute close={() => setWindowOpen(null)} />}
                        overflowVert={true}
                        description={"Empower Birmingham's journey towards Smart City excellence by contributing your data and ideas."} />
                    <Window show={windowOpen === 'roadmap'} title={'Roadmap'} children={<Roadmap close={() => setWindowOpen(null)} />}
                        overflowVert={true}
                        description={"This roadmap charts our inspiring journey toward achieving Smart City status for Birmingham, where innovation, collaboration, and community spirit propel us towards a brighter, more connected future."}
                    />
                </div>
            </Transvoider>

            {/* LEFT COLLAPSE */}
            {!canvasMode === 'canvas' && windowOpen === null && page === 'platform' && <div style={{ ...backgroundStyle, position: 'fixed', width: '180px', display: 'inline-grid', top: '50px', zIndex: 1, borderBottomRightRadius: '10px', left: '0px' }}>
                {deckprops.views && <>
                    <hr />
                    <IconButton style={{ color: layer === 'pred' ? colourAccent : 'grey', ...layerItemStyle }}
                        disabled={true}
                        onClick={() => { setLayer('pred') }}>
                        Predictive
                    </IconButton>
                    <IconButton style={{ color: layer === 'info' ? colourAccent : 'grey', ...layerItemStyle }}
                        disabled={true}
                        onClick={() => { setLayer('info') }}>
                        Information
                    </IconButton>
                    <IconButton style={{ color: layer === 'data' ? colourAccent : 'grey', ...layerItemStyle }}
                        onClick={() => { setLayer('data') }}>
                        Data
                    </IconButton>
                </>}
            </div>}
        </div>

        <div
            style={{ height: '100vh', width: '100vw', position: 'absolute', top: '0px' }}
            onContextMenu={e => {
                e.preventDefault();
                setFocusNodeFly(null);
            }}
        >
            {ENABLE_MAP && <Map
                id="map"
                key={mapKey}
                ref={mapRef}
                onLoad={onMapLoad}

                mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
                antialias={page === 'home' ? true : false}
                initialViewState={INITIAL_VIEW_STATE_HOME}
                maxPitch={85}
                onError={(e) => setMapKey(`${Math.random()}`)}

                fog={fog}

                // maxBounds={[[-2.3798858331250257, 52.234649826904416], [-1.4582597902333225, 52.692993319132896]]}
                // mapStyle={canvasMode === 'canvas'? "https://basemaps.cartocdn.com/gl/positron-gl-style/style.json" : "mapbox://styles/mapbox/standard"}
                mapStyle={"mapbox://styles/mapbox/standard"}
                // mapStyle="mapbox://styles/mapbox/streets-v9"
                // mapStyle="mapbox://styles/mapbox/light-v9"

                styleDiffing
                onMove={e => {
                    //onMoveViewState(e)
                    if (parseInt(e.viewState.zoom) !== parseInt(viewState.zoom)) {
                        debounceSetViewState(e.viewState)
                        !merlinAssist && e.viewState.zoom < 13 && light !== 'day' && debounceSetMapLight(page === 'day')
                        !merlinAssist && e.viewState.zoom > 12 && page !== 'home' && light !== userSetMapLight && debounceSetMapLight(userSetMapLight)
                    }

                    if (e.viewState.zoom <= 8)
                        debounceSetZoomScope(2)
                    else if (e.viewState.zoom <= 11)
                        debounceSetZoomScope(1)
                    else
                        debounceSetZoomScope(0)

                    // console.log(e.viewState.zoom)
                }}

                onClick={(e) => {
                    setFocusNodeFly(null)
                    setFocusNode(null)
                    canvasMode === "analytics" && setCanvasMode(false)
                }}
                attributionControl={true}>
                {interleaved ? <DeckGLOverlayInterleaved
                    id='dgloverlay-interleaved'
                    controller={true}
                    effects={[
                        // postProcessEffect,
                        lightingEffect
                    ]}
                    interleaved={true}
                    layers={[
                        deckprops.layers.bPht && goog3dtiles,
                        // deckprops.layers.ostraff && trafficLayer,
                        // buildings,
                        // Object.keys(geojsonImports).length > 0 && Object.keys(geojsonImports).map(k => geojson(k, geojsonImports[k])),
                        // WardsLayer('Wards'),
                        // geojson('wards', Wards),
                        // rtemlayer,

                        // assets && AssetMeshLayer,
                        // assets && AssetWireframeMeshLayer,
                        // assets && AssetTextLayer,

                        assets && AssetCompositeLayer,


                        // scootlayer,
                        // textLayerSCOOT,

                        hexCoverage && hexlayer,

                        currentModel?.inputs && Object.keys(currentModel.inputs).length > 0 && NexusInputLinkLayer,
                        currentModel?.outputs && Object.keys(currentModel.outputs).length > 0 && NexusOutputLinkLayer,
                        Object.keys(API2.aiworkflow().canvasModels).length > 0 && NexusMeshLayer,
                        Object.keys(API2.aiworkflow().canvasModels).length > 0 && NexusMeshWireframeLayer,
                        Object.keys(API2.aiworkflow().canvasModels).length > 0 && NexusTextLayer,

                        merlinAssist && merlinWard && MerlinWardMeshLayer,
                        merlinAssist && merlinWard && MerlinWardTextLayer,
                    ]}

                    onHover={({ object }) => (isHovering = Boolean(object))}
                    getCursor={({ isDragging }) => (isDragging ? 'grabbing' : (isHovering ? 'pointer' : 'grab'))}
                /> : <DeckGLOverlay
                    id='dgloverlay'
                    controller={true}
                    effects={[
                        postProcessEffect,
                        lightingEffect
                    ]}
                    interleaved={false}
                    layers={[
                        deckprops.layers.bPht && goog3dtiles,
                        // deckprops.layers.ostraff && trafficLayer,
                        // buildings,
                        // Object.keys(geojsonImports).length > 0 && Object.keys(geojsonImports).map(k => geojson(k, geojsonImports[k])),
                        // WardsLayer('Wards'),
                        // geojson('wards', Wards),
                        // rtemlayer,

                        // assets && AssetMeshLayer,
                        // assets && AssetWireframeMeshLayer,
                        // assets && AssetTextLayer,



                        // scootlayer,
                        // textLayerSCOOT,


                        assets && AssetCompositeLayer,
                        hexCoverage && hexlayer,

                        currentModel?.inputs && Object.keys(currentModel.inputs).length > 0 && NexusInputLinkLayer,
                        currentModel?.outputs && Object.keys(currentModel.outputs).length > 0 && NexusOutputLinkLayer,
                        Object.keys(API2.aiworkflow().canvasModels).length > 0 && NexusMeshLayer,
                        Object.keys(API2.aiworkflow().canvasModels).length > 0 && NexusMeshWireframeLayer,
                        Object.keys(API2.aiworkflow().canvasModels).length > 0 && NexusTextLayer,

                        merlinAssist && merlinWard && MerlinWardMeshLayer,
                        merlinAssist && merlinWard && MerlinWardTextLayer,
                    ]}

                    onHover={({ object }) => (isHovering = Boolean(object))}
                    getCursor={({ isDragging }) => (isDragging ? 'grabbing' : (isHovering ? 'pointer' : 'grab'))}
                />}
                {/* <rtemlayer /> */}
                {/* <NavigationControl style={{ display: page === 'platform' ? 'block' : 'none', position: 'relative', top: '61vh', marginRight: '18px', zIndex: 40 }} /> */}
            </Map>}
        </div>

    </div >
}

export default Platform;
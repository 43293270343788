import React, { useEffect, useState, useRef, useCallback } from 'react';
import { TextField } from '@mui/material';
import { headStyle, cardStyle, hrStyle, buttonStyle, headStyleInverted, backgroundStyle, lightColor, verticalCenter } from '../../css/styling';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import * as API2 from '../states';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import * as API from '../apis/APIWeb';
import settingInscimg from '../../images/sitesettings.png';
// import Highlight from 'react-highlight'
// import '../../../node_modules/highlight.js/styles/atelier-plateau-dark.css';
import { Trail } from '@react-spring/web';
import { Transvoider } from '../dElements/Animations';

const card = (v) => <>
    <div>
        <Typography variant="h5" gutterBottom >
            {v.info.label}
            <div style={{ float: 'right' }}>
                {v.disabled ? "DISABLED" : <IconButton size='xlarge' >
                    {v.active ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                </IconButton>}
            </div>
        </Typography>
        <Typography style={{ padding: '5px' }} sx={{ fontSize: 14 }} component="div">
            {v.info.description}
        </Typography>
        <Typography style={{ padding: '5px' }} variant="h7" color="text.secondary" component="div">
            {v.layers.join(', ')}
        </Typography>
        <Typography style={{ padding: '5px' }} variant="h7" color="text.secondary" component="div">
            {v.info.category}
        </Typography>
    </div>
</>

const cardHosted = (v) => <>
    <div>
        <Typography variant="h5" gutterBottom >
            {v.name.toUpperCase()}
            <div style={{ float: 'right' }}>
                <IconButton size='xlarge' >
                    {v.visible ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                </IconButton>
            </div>
        </Typography>
        {v?.meta?.description && <Typography style={{ padding: '5px' }} sx={{ fontSize: 14 }} component="div">
            {v.meta.description}
        </Typography>}
        {v?.meta?.tags && <Typography style={{ display: v?.meta?.tags ? 'block' : 'none', padding: '5px' }} variant="h7" color="text.secondary" component="div">
            {v.meta.tags}
        </Typography>}
    </div>
</>

const any = (arr, fn = Boolean) => arr.some(fn);

function Datasets() {
    const [filter, setFilter] = useState("");
    // const datalist = API2.localDataStore((state) => state.datalist)
    const state = API2.apiDataStore()
    const toggleActive = API2.apiDataStore((state) => state.toggleActive)
    const { collections, setCollections, setCollectionVisibility, colourAccent } = API2.globalStore();

    const [sector, setSector] = useState('all')
    const [scope, setScope] = useState('all')
    const [type, setType] = useState('all')

    const sectorStyle = (highlight) => ({ width: 'calc(100vw / 13)', color: sector === highlight ? colourAccent : 'grey', borderRadius: '3px', marginBottom: '20px' })
    const scopeStyle = (highlight) => ({ width: '200px', color: scope === highlight ? colourAccent : 'grey', borderRadius: '3px', paddingTop: '2vh', paddingBottom: '2vh' })
    const typeStyle = (highlight) => ({ width: '200px', color: type === highlight ? colourAccent : 'grey', borderRadius: '3px', paddingTop: '2vh', paddingBottom: '2vh' })

    return <>

        <Box >
            {/* <IconButton disabled style={{ ...iconStyles, width: '300px', color: colourAccent }}>
                Sector
            </IconButton> */}
            <IconButton disabled style={sectorStyle('')}></IconButton>
            <IconButton style={sectorStyle('all')} onClick={() => setSector('all')}>All</IconButton>
            <IconButton style={sectorStyle('traffic')} onClick={() => setSector('traffic')}>Traffic</IconButton>
            <IconButton style={sectorStyle('aquality')} onClick={() => setSector('aquality')}>Air Quality</IconButton>
            <IconButton style={sectorStyle('energy')} onClick={() => setSector('energy')}>Energy</IconButton>
            <IconButton style={sectorStyle('telecomms')} onClick={() => setSector('telecomms')}>Telecomms</IconButton>
            <IconButton style={sectorStyle('social')} onClick={() => setSector('social')}> Social</IconButton>
            <IconButton style={sectorStyle('other')} onClick={() => setSector('other')}>Other</IconButton>
            <IconButton style={sectorStyle('imports')} onClick={() => setSector('imports')}>Imports</IconButton>
        </Box >
        <Box style={{ display: 'grid', position: 'fixed', paddingTop: '5vh' }}>
            <IconButton disabled style={scopeStyle('')} onClick={() => setScope('')}></IconButton>
            <IconButton style={scopeStyle('national')} onClick={() => setScope('national')}>National</IconButton>
            <IconButton style={scopeStyle('regional')} onClick={() => setScope('regional')}>Regional</IconButton>
            <IconButton style={scopeStyle('all')} onClick={() => setScope('all')}>All</IconButton>
            <IconButton disabled style={scopeStyle('')} onClick={() => setScope('')}></IconButton>
            <IconButton style={typeStyle('smart')} onClick={() => setType('smart')}>Smart Data</IconButton>
            <IconButton style={typeStyle('temporal')} onClick={() => setType('temporal')}>Timeseries</IconButton>
            <IconButton style={typeStyle('geospatial')} onClick={() => setType('geospatial')}>Geospatial</IconButton>
            <IconButton style={typeStyle('all')} onClick={() => setType('all')}>All</IconButton>
        </Box >

        <Box style={{ display: 'grid', position: 'fixed', right: '10px', paddingTop: '30px' }}>

            <Transvoider open={true} behaviour={{ y: 0, from: { opacity: 0, scale: 0.5 }, to: { opacity: 1, x: 0, scale: 0.9 } }}>

                {(any(Object.values(collections).map(c => c.visible), x => x === true) ||
                    any(Object.values(state.datalist).map(c => c.active), x => x === true)) &&
                    < div style={{ margin: 5, paddingRight: '10px' }}>On Map</div>}

                {Object.keys(collections).map((c, i) => {
                    if (!collections[c].visible)
                        return null
                    // if (filter === "" || collection...cardStyle,s[c][i].meta.tags.toLowerCase().includes(filter.toLowerCase()))
                    return <div key={`${c}-hosted`} className='buttonexotic' style={{ ...cardStyle, margin: 0, marginRight: '10px', width: '180px' }}
                        onClick={() => setCollectionVisibility(c)}
                        variant="outlined"  >{c.toUpperCase()}</div>
                })}

                {Object.keys(state.datalist).map((c, i) => {
                    if (!state.datalist[c].active)
                        return null
                    // if (filter === "" || collections[c][i].meta.tags.toLowerCase().includes(filter.toLowerCase()))
                    return <div key={`${c}-hosted`} className='buttonexotic' style={{ ...cardStyle, margin: 0, marginRight: '10px', width: '180px' }}
                        onClick={() => toggleActive(c)}
                        variant="outlined"  >{c.toUpperCase()}</div>
                })}
            </Transvoider>
        </Box >

        <Box sx={{ textAlign: '-webkit-center', width: 'calc(100vw - 400px)', paddingLeft: '0px' }}>
            {/* <input className='tfieldStyle' style={{...cardStyle}} onChange={(e) => { setFilter(e.target.value) }} /> */}
            {/* <TextField id="filled-basic" label="Filled" variant="filled" /> */}

            <div > FILTER
                <input className='tfieldStyle' style={{ marginLeft: '15px', marginBottom: '10px' }}
                    id="standard-basic"
                    label="Keyword Filter"
                    variant="standard"
                    onChange={(e) => { setFilter(e.target.value) }} />
            </div>

            <div style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 280px)', maxWidth: 800, textAlign: 'right' }}>
                <Transvoider open={true} behaviour={{ x: 0, y: 0, from: { opacity: 0 }, to: { opacity: 1 } }}>

                    {/* HOSTED COLLECTIONS */}
                    {/* {collections.length !== 0 && <div style={{ paddingRight: '40px' }}>HOSTED</div>} */}
                    {Object.keys(collections).map((c, i) => {
                        // if (filter === "" || collections[c][i].meta.tags.toLowerCase().includes(filter.toLowerCase()))
                        return <div key={`${c}-hosted`} className='buttonexotic' style={{ ...cardStyle, marginBottom: '10px', maxWidth: 800, minWidth: 275, }}
                            onClick={() => setCollectionVisibility(c)}
                            variant="outlined"  >{cardHosted(collections[c])}</div>
                    })}

                    {/* SAMPLE COLLECTIONS */}
                    {state.datalist !== 0 && <div style={{ paddingRight: '40px' }}>SAMPLES</div>}
                    {Object.keys(state.datalist).map(id => {
                        if (filter === "" || state.datalist[id].info.category.toLowerCase().includes(filter.toLowerCase()))
                            return <div key={id} className='buttonexotic' style={{ ...cardStyle, marginBottom: '10px', maxWidth: 800, minWidth: 275, }}
                                onClick={() => state.datalist[id].disabled ? null : toggleActive(id)}
                                variant="outlined"  >{card(state.datalist[id])}</div>
                    })}

                </Transvoider>
            </div>

            {/* DEBUG MODE */}
            {filter === 'debug' && <div style={{ maxHeight: '65vh', textAlign: 'left', maxWidth: '650px', overflowY: 'auto' }}>
                {JSON.stringify(['connect', process.env.REACT_APP_DEV_API])}
                <br />
                <br />
                <div> Registered Collections: &nbsp;<span style={{ color: colourAccent }}> {Object.keys(collections).length}</span>
                    &nbsp;&nbsp;&nbsp;<span style={{ cursor: 'pointer', color: 'orange' }} onClick={() =>
                        API.getCollections(r => setCollections(r), e => alert(JSON.stringify(r, null, 2)))}> [ refresh ]</span>
                </div>

                {Object.keys(collections).length === 0 ? <>
                    <br />
                    Our secure HTTPS site (diatomic.uk) can't load unsecure HTTP resources.
                    <br />
                    A workaround is to allow insecure content for this site in your browser.
                    <br />
                    <br />
                    <img src={settingInscimg} alt="" />
                </> :
                    <>
                        <br />
                        {Object.keys(collections).map((v, i) => <span> &nbsp;&nbsp;&gt; &nbsp;&nbsp;<span style={{ color: colourAccent }}>{collections[v].name}</span>
                            <span style={{ color: 'silver' }}>
                                &nbsp;&nbsp;&nbsp;( {Object.keys(collections[v].dataTypes).filter(k => collections[v].dataTypes[k]).join(', ')} )</span>
                            <span style={{ color: 'orange' }}>
                                &nbsp;&nbsp;&nbsp;{collections[v].links.map(l => <span onClick={() => window.open(l.href, "_blank")} style={{ cursor: 'pointer' }}> [ {l.rel} ] &nbsp;</span>)}</span>
                        </span>)}
                        <br />
                        <br />
                        {/* <hr /> */}
                        {/* <div style={{ borderRadius: '10px' }}> */}
                        {/* <Highlight className='codecontainer' language="json"> */}
                        {/* {"code snippet to be highlighted"} */}
                        {/* {`function foo() { return 'bar' }`} */}
                        {JSON.stringify(collections, null, 2)}
                        {/* </Highlight> */}
                        {/* </div> */}
                    </>}

            </div>}

            {/* CONNECT MODE */}
            {filter.split(' ').filter(x => x !== ' ' && x !== '')[0] === 'connect' && filter.split(' ').filter(x => x !== ' ' && x !== '').length === 2 && <div style={{ maxHeight: '65vh', textAlign: 'left', maxWidth: '650px', overflowY: 'auto' }}>
                {/* <hr /> */}

                {JSON.stringify(filter.split(' ').filter(x => x !== ' ' && x !== ''))}
                {/* connect http://diatomic.ddns.net:9580/frontapi/v1/ */}
                <br />
                <br />
                <div> Registered Collections: &nbsp;<span style={{ color: colourAccent }}> {collections?.length}</span>
                    &nbsp;&nbsp;&nbsp;<span style={{ cursor: 'pointer', color: 'orange' }} onClick={() =>
                        API.getCollections(r => setCollections(r),
                            e => alert(JSON.stringify(r, null, 2)),
                            (filter.split(' ').filter(x => x !== ' ' && x !== '')[1] + '/')
                                .replace('//', '_@_@_@_').replaceAll('//', '/').replace('_@_@_@_', '//'))}> [ connect/refresh ]</span>
                </div>

                {Object.keys(collections).length === 0 && filter.includes('http://') ? <>
                    <br />
                    Our secure HTTPS site (diatomic.uk) can't load unsecure HTTP resources.
                    <br />
                    A workaround is to allow insecure content for this site in your browser.
                    <br />
                    <br />
                    <img src={settingInscimg} alt="" />
                </> :
                    <>
                        <br />
                        {collections?.map((v, i) => <span> &nbsp;&nbsp;&gt; &nbsp;&nbsp;<span style={{ color: colourAccent }}>{v.name}</span>
                            <span style={{ color: 'silver' }}>
                                &nbsp;&nbsp;&nbsp;( {Object.keys(v.dataTypes).filter(k => v.dataTypes[k]).join(', ')} )</span>
                            <span style={{ color: 'orange' }}>
                                &nbsp;&nbsp;&nbsp;{v.links.map(l => <span onClick={() => window.open(l.href, "_blank")} style={{ cursor: 'pointer' }}> [ {l.rel} ] &nbsp;</span>)}</span>
                        </span>)}
                        <br />
                        <br />
                        {/* <hr /> */}
                        {/* <div style={{ borderRadius: '10px' }}> */}
                        <Highlight className='codecontainer' language="json">
                            {/* {"code snippet to be highlighted"} */}
                            {/* {`function foo() { return 'bar' }`} */}
                            {JSON.stringify(collections, null, 2)}
                        </Highlight>
                        {/* </div> */}
                    </>}

            </div>}

        </Box >

        {/* <Box style={{ display: 'grid', position: 'fixed' }}>
            <IconButton style={scopeStyle}>National</IconButton>
            <IconButton style={scopeStyle}>Regional</IconButton>
            <IconButton style={scopeStyle}>Nodal</IconButton>
        </Box > */}

        {/* <code>{JSON.stringify(state.datastates)}</code> */}

    </>
}

export default Datasets;
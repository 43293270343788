import { create } from "zustand";
import * as API from "./apis/APIS";
import { get } from "immutable";
import { ConstantColorFactor } from "three";
import {
  CANVAS_STRUCTURE,
  MLMODEL_STRUCTURE,
  ACCOUNT_GLOBALS_STRUCTURE
} from "./datastructures/UserStructures";
import * as tf from '@tensorflow/tfjs';

export const DUMMYDATA = (name, length) => {
  return {
    name,
    data: Array.from({ length }, () => Math.floor(Math.random() * 100)),
  };
};

const DATALIST = {
  SCOOT: {
    active: true,
    disabled: false,
    layers: ["Metadata", "Timeseries"],
    info: {
      label: "Vehicle Speed Flow (Legacy)",
      category: "Traffic Sensor",
      description:
        "SCOOT Inductive Loops - road embedded sensors measuring vehicle speed and flow. 5 minute intervals.",
    },
    calls: { info: (callback) => API.SCOOTINFO(callback) },
  },
  AIRVIRO: {
    active: false,
    disabled: true,
    layers: ["Metadata", "Timeseries"],
    info: {
      label: "AirViro",
      category: "Air Quality Sensor",
      description:
        "AirViro Sensors - sensor stations measuring pollutants such as NO2, NOX, SO2, O2 and stuffs. Every second, probably.",
    },
    calls: { info: (callback) => API.AIRVIROIMPORT(callback) },
  },
  AIRLY: {
    active: false,
    disabled: true,
    layers: ["Metadata", "Timeseries"],
    info: {
      label: "Airly",
      category: "Air Quality Sensor",
      description:
        "Airly Sensors - sensor stations measuring pollutants such as NO2, NOX, SO2, O2 and stuffs. Every second, probably.",
    },
    calls: { info: (callback) => null },
  },
  RTEM: {
    active: false,
    disabled: true,
    layers: ["Metadata", "Live", "Timeseries"],
    info: {
      label: "Vehicle Counters",
      category: "Traffic Sensor",
      description:
        "RTEM Inductive Loops - road embedded sensors counting vehicles of categories HGV, LGV, cars, motorcycles, buses and stuffs. 5 minute intervals.",
    },
    calls: { info: (callback) => API.RTEMINFO(callback) },
  },
};

const DATASTATES = {
  RTEM: {
    info: {},
  },
  SCOOT: {
    info: {},
  },
  AIRVIRO: {
    info: {},
  },
  AIRLY: {
    info: {},
  },
};



export const apiDataStore = create((set, get) => ({
  datalist: DATALIST,
  datastates: DATASTATES,
  toggleActive: (key, value = null) => {
    const datalist = get().datalist;
    value = value === null ? !datalist[key].active : value;
    datalist[key].active = value;
    set((state) => ({ datalist: state.datalist }));
    if (value === true) get().loadInfoData(key);
  },
  loadInfoData: (key) => {
    const calls = get().datalist[key].calls;
    const datastates = get().datastates;
    calls.info((response) => {
      datastates[key] = { info: {} };
      datastates[key].info = response;
      set((state) => ({ datastates: state.datastates }));
    });
  },
}));

export const ctxMenuStore = create((set) => ({
  ctxMenuPos: [0, 0],
  setCtxMenuPos: (xy) => {
    set((state) => ({ ctxMenuPos: xy }));
  },
}));

export const deckPropsStore = create((set, get) => ({
  layers: {
    b3D: true,
    bPht: false,
    ostraff: false,
    labels: false,
    cubes: true,
  },
  views: false,
  controls: false,
  lens: false,
  settings: false,
  update: (value) => {
    set((state) => ({ ...value }));
  },
  toggleLens: (value = null) => {
    set((state) => ({ lens: value ? value : !state.lens }));
  },
  toggleViews: (value = null) => {
    set((state) => ({ views: value ? value : !state.views }));
  },
  toggleControls: (value = null) => {
    set((state) => ({ controls: value ? value : !state.controls }));
  },
  toggleProps: (key, value = null) => {
    const layers = get().layers;
    value = value === null ? !layers[key] : value;
    layers[key] = value;
    set((state) => ({ layers: state.layers }));
  },
}));

// export const platformStore = create((set, get) => ({

// }))

export const aiworkflow = create((set, get) => ({
  canvasMode: null,
  focusNode: null,
  modelProgress: -1,
  // models: {},
  canvasModels: {},
  timerange: [],
  interval: null,
  // trainingData: null,
  trainingStage: null,
  // trainingProgress:(tProgress) => set({tProgress}),
  setModelProgress: (value) => set({ modelProgress: value }),
  setCanvasMode: (value) => set({ canvasMode: value }),
  setFocusNode: (value) => set({ focusNode: value }),
  // setNextCanvasState: () => set((state) => ({canvasState: get().canvasState +1 })),
  // setCanvasState: (value) => set({ canvasState: value }),
  setTrainingStage: (value) => set({ trainingStage: value }),
  saveModel: async (modelToSave) => {
    async function toCanvasModelBlob(model) {
      const saveResults = await model.modelBinary.save(tf.io.withSaveHandler(async modelArtifacts => {

        console.log(modelArtifacts)

        let canvasmdl = { ...model, modelBinary: modelArtifacts }

        // Serialize the model topology and weight specs as a JSON string
        const jsonModel = JSON.stringify(canvasmdl);
        const jsonBuffer = new TextEncoder().encode(jsonModel);

        // Convert the length of the JSON string into a Uint32Array
        const jsonLengthBuffer = new Uint32Array([jsonBuffer.length]).buffer;

        // Create a Blob with the JSON length, the JSON data, and the binary weight data
        const modelBlob = new Blob([jsonLengthBuffer, jsonBuffer, modelArtifacts.weightData], { type: 'application/octet-binary' });
        return modelBlob;
      }));

      return saveResults;
    }

    // const model = await tf.loadLayersModel('path/to/your/model');

    var canvasMdl = null;

    if (typeof modelToSave === 'string') {
      canvasMdl = get().canvasModels[modelToSave];
    }
    else {
      canvasMdl = modelToSave;
    }

    console.log(canvasMdl)
    const modelBlob = await toCanvasModelBlob(canvasMdl);

    // Now you can download the blob or use it in your application
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = window.URL.createObjectURL(modelBlob);
    a.download = `${canvasMdl.name}.canvmdl`;
    a.click();
    window.URL.revokeObjectURL(a.href);
  },
  loadModel: async (file) => {
    await tf.ready()
    async function loadModelFromBlob(blob) {
      const arrayBuffer = await blob.arrayBuffer();

      // Assuming the first part is JSON and the second part is binary weight data
      const jsonLength = new Uint32Array(arrayBuffer.slice(0, 4))[0]; // The length of the JSON part
      const rawCanvasModel = new TextDecoder().decode(arrayBuffer.slice(4, 4 + jsonLength));
      var canvasModel = JSON.parse(rawCanvasModel)
      const weightsBlob = new Blob([arrayBuffer.slice(4 + jsonLength)], { type: 'application/octet-binary' });

      const modelArtifacts = {
        modelTopology: canvasModel.modelBinary.modelTopology,
        weightSpecs: canvasModel.modelBinary.weightSpecs,
        weightData: await weightsBlob.arrayBuffer(),
      };

      console.log(canvasModel, modelArtifacts)

      canvasModel.modelBinary = await tf.loadLayersModel(tf.io.fromMemory(modelArtifacts));

      return canvasModel
    }

    // Example usage: Assuming 'modelBlob' is the Blob of the saved model
    const canvasModel = await loadModelFromBlob(file);

    // REPOPULATE missing objects
    if (Object.keys(canvasModel.compile).length === 0)
      canvasModel.compile = MLMODEL_STRUCTURE.compile

    if (!canvasModel.epochs)
      canvasModel.epochs = MLMODEL_STRUCTURE.epochs



    var canvasModels = get().canvasModels;
    canvasModels[`${canvasModel.id}`] = canvasModel
    set({ canvasModels });
    console.log('aiworkflow new model', canvasModel.name)

    // Now you can use 'model' and 'canvasModel' in your application
  },

  // downloadCanvas: async (filename = 'diatomic_canvas') => {
  //   var canvasModels = get().canvasModels;
  //   const splitter = "###SPLITTER###"

  //   async function saveCanvasStructureToBlob(canvasStructure) {
  //     const parts = [];

  //     // Serialize the canvas metadata (excluding models for now)
  //     const metadataWithoutModels = { ...canvasStructure };

  //     for (const modelID in metadataWithoutModels) {
  //       // Serialize the model
  //       const model = metadataWithoutModels[modelID].modelBinary;
  //       await model.save(tf.io.withSaveHandler(async (artifacts) => {

  //         const weightData = artifacts.weightData

  //         // Replace the model in the metadata with a placeholder
  //         metadataWithoutModels[modelID].modelBinary = {
  //           modelTopology: artifacts.modelTopology,
  //           weightSpecs: artifacts.weightSpecs,
  //           weightData: `MODEL_${modelID}_WEIGHTSDATA`
  //         };

  //         console.log(metadataWithoutModels[modelID].modelBinary)

  //         // Add serialized model data to parts
  //         parts.push(`MODEL_${modelID}_WEIGHTSDATA_START${splitter}`);
  //         parts.push(weightData, splitter, `MODEL_${modelID}_WEIGHTSDATA_END`, splitter);

  //       }))
  //     }

  //     // Add the canvas metadata at the beginning
  //     parts.unshift('CANVAS_METADATA', splitter, JSON.stringify(metadataWithoutModels), splitter);

  //     // Combine everything into a single Blob
  //     const combinedBlob = new Blob(parts, { type: 'application/octet-binary' });

  //     return combinedBlob;

  //   }

  //   const blob = await saveCanvasStructureToBlob(canvasModels);

  //   // Trigger download
  //   const downloadUrl = URL.createObjectURL(blob);
  //   const link = document.createElement('a');
  //   link.href = downloadUrl;
  //   link.download = `${filename}.canv`;
  //   link.click();
  // },

  // loadCanvas: async (file) => {
  //   const splitter = "###SPLITTER###"

  //   async function loadBlobAsText(blob) {
  //     return new Promise((resolve, reject) => {
  //       const reader = new FileReader();
  //       reader.onload = () => resolve(reader.result);
  //       reader.onerror = reject;
  //       reader.readAsText(blob);
  //     });
  //   }

  //   async function loadBlobAsArrayBuffer(blob) {
  //     return new Promise((resolve, reject) => {
  //       const reader = new FileReader();
  //       reader.onload = () => resolve(reader.result);
  //       reader.onerror = reject;
  //       reader.readAsArrayBuffer(blob);
  //     });
  //   }

  //   async function textToBlob(text, mimeType = 'application/octet-stream') {
  //     return new Blob([text], { type: mimeType });
  //   }

  //   async function alignBuffer(misalignedBuffer, padding = 0) {
  //     // Example of adjusting a misaligned ArrayBuffer
  //     // const misalignedBuffer = await weightsBlob.arrayBuffer()  // Not a multiple of 4
  //     const alignedSize = Math.floor(misalignedBuffer.byteLength / 4) * 4 + 4 * padding;  // Largest multiple of 4

  //     // Creating a new ArrayBuffer that is aligned
  //     const alignedBuffer = misalignedBuffer.slice(0, alignedSize);
  //     const floatArray = new Float32Array(alignedBuffer);
  //     // const floatArray = new ArrayBuffer(alignedBuffer.byteLength);

  //     return floatArray.buffer;

  //   }

  //   const arrayBuffer = await file.arrayBuffer();
  //   const fileContent = await loadBlobAsText(file);

  //   // Split the content into parts
  //   const parts = fileContent.split(splitter).filter((el) => { return el !== '' });
  //   const canvasModels = JSON.parse(parts[1]);

  //   console.log('parts', parts)


  //   let weightsDict = {}

  //   for (var i = 2; i < parts.length; i += 3) {
  //     let modelID = parts[i].split('_')[1]
  //     weightsDict[modelID] = await textToBlob(parts[i + 1])
  //   }

  //   for (const modelId in canvasModels) {

  //     console.log(weightsDict[modelId])

  //     let start = `MODEL_${modelId}_WEIGHTSDATA_START${splitter}`
  //     let end = `MODEL_${modelId}_WEIGHTSDATA_END${splitter}`

  //     // // const weightDataIndex = fileContent.indexOf();
  //     // console.log(start, end)
  //     // console.log('index of start and end', fileContent.indexOf(start), fileContent.indexOf(end))
  //     const weightDataBlob = arrayBuffer.slice(
  //       fileContent.indexOf(start) + start.length,
  //       fileContent.indexOf(end)
  //     );

  //     const alignedBuffer = await alignBuffer(weightDataBlob, 104)
  //     const weightsBlob = new Blob([alignedBuffer], { type: 'application/octet-binary' });
  //     // const weightData = await loadBlobAsArrayBuffer(weightsDict[modelId]);

  //     // Reconstruct the model from its components
  //     const modelArtifacts = {
  //       ...canvasModels[modelId].modelBinary,
  //       weightData: await weightsBlob.arrayBuffer(), //weightData  // weightData
  //     };

  //     console.log(modelArtifacts)

  //     // Load the model using TensorFlow.js
  //     const model = await tf.loadLayersModel(tf.io.fromMemory(modelArtifacts));

  //     // Restore the model into the canvas metadata
  //     canvasModels[modelId].modelBinary = model;
  //   }

  //   // Assuming you have a way to set the canvasModels in your application state:

  //   set({ canvasModels: canvasModels });
  //   return canvasModels;
  // },

  // loadCanvasOLd: async (file) => {
  //   async function loadBlobAsText(blob) {
  //     return new Promise((resolve, reject) => {
  //       const reader = new FileReader();
  //       reader.onload = () => resolve(reader.result);
  //       reader.onerror = reject;
  //       reader.readAsText(blob);
  //     });
  //   }

  //   async function loadBlobAsArrayBuffer(blob) {
  //     return new Promise((resolve, reject) => {
  //       const reader = new FileReader();
  //       reader.onload = () => resolve(reader.result);
  //       reader.onerror = reject;
  //       reader.readAsArrayBuffer(blob);
  //     });
  //   }

  //   const fileContent = await loadBlobAsText(file);

  //   // Split the content into parts
  //   const parts = fileContent.split('\n');
  //   const metadataIndex = parts.indexOf('CANVAS_METADATA');
  //   const canvasMetadata = JSON.parse(parts[metadataIndex + 1]);

  //   for (const modelName in canvasMetadata) {
  //     const topologyIndex = parts.indexOf(`MODEL_${modelName}_TOPOLOGY`);
  //     const weightSpecsIndex = parts.indexOf(`MODEL_${modelName}_WEIGHTSPECS`);
  //     const weightDataIndex = parts.indexOf(`MODEL_${modelName}_WEIGHTSDATA`);

  //     const modelTopology = JSON.parse(parts[topologyIndex + 1]);
  //     const weightSpecs = JSON.parse(parts[weightSpecsIndex + 1]);

  //     // To handle the binary weight data, it's important to get the correct slice of the file.
  //     // We'll assume that the binary data starts immediately after the weightData label.
  //     const weightDataBlob = file.slice(
  //       fileContent.indexOf(parts[weightDataIndex + 1]) + parts[weightDataIndex + 1].length + 1
  //     );

  //     const weightData = await loadBlobAsArrayBuffer(weightDataBlob);

  //     // Reconstruct the model from its components
  //     const modelArtifacts = {
  //       modelTopology: modelTopology,
  //       weightSpecs: weightSpecs,
  //       weightData: weightData
  //     };

  //     // Load the model using TensorFlow.js
  //     const model = await tf.loadLayersModel(tf.io.fromMemory(modelArtifacts));

  //     // Restore the model into the canvas metadata
  //     canvasMetadata[modelName].modelBinary = model;
  //   }

  //   // Assuming you have a way to set the canvasModels in your application state:

  //   set({ canvasModels: canvasMetadata });
  //   return canvasMetadata;
  // },

  // loadCanvas2: async (blob) => {
  //   async function loadCanvasStructureFromBlob(blob) {
  //     const reader = new FileReader();

  //     // Read the entire Blob as text
  //     const text = await new Promise((resolve, reject) => {
  //       reader.onload = () => resolve(reader.result);
  //       reader.onerror = () => reject(reader.error);
  //       reader.readAsText(blob);
  //     });

  //     const parts = text.split('\n');

  //     // Ensure proper header
  //     if (!parts[0].startsWith("CANVAS_METADATA")) {
  //       throw new Error("Invalid file format");
  //     }

  //     // Parsing the JSON right after the metadata identifier
  //     const canvasMetadata = JSON.parse(parts[1]);
  //     let index = 2; // Start processing models from the next line after the JSON metadata

  //     while (index < parts.length && parts[index].trim() !== "") {
  //       if (parts[index].startsWith('MODEL_')) {
  //         const modelName = parts[index].split('_')[1]; // Assume MODEL_modelName_TOPOLOGY

  //         const modelTopology = JSON.parse(parts[index + 1]);
  //         const weightSpecs = JSON.parse(parts[index + 3]);

  //         // Finding the weight data's start index in the Blob
  //         let weightDataStart = text.indexOf(parts[index + 5], index);
  //         let weightDataEnd = text.indexOf('MODEL_', weightDataStart + 1);
  //         weightDataEnd = weightDataEnd === -1 ? blob.size : weightDataEnd; // If no more models, go till the end of blob

  //         const weightDataBlob = blob.slice(weightDataStart, weightDataEnd);
  //         const arrayBuffer = await weightDataBlob.arrayBuffer();

  //         const model = await tf.loadLayersModel(tf.io.fromMemory(
  //           modelTopology,
  //           weightSpecs,
  //           new Uint8Array(arrayBuffer)
  //         ));

  //         // Assign model to the right place in metadata
  //         canvasMetadata[modelName].modelBinary = model;
  //         index += 6; // Move index past the model data
  //       } else {
  //         index++;
  //       }
  //     }

  //     return canvasMetadata;
  //   }

  //   //   async function loadCanvasStructureFromBlob(blob) {
  //   //     const reader = new FileReader();

  //   //     // Read the entire Blob as text to determine the boundaries of the weight data
  //   //     const text = await new Promise((resolve, reject) => {
  //   //         reader.onloadend = () => resolve(reader.result);
  //   //         reader.onerror = reject;
  //   //         reader.readAsText(blob);
  //   //     });

  //   //     const parts = text.split('\n');
  //   //     const canvasMetadata = JSON.parse(parts[1].trim());

  //   //     for (const modelName in canvasMetadata) {
  //   //         const topologyKey = `MODEL_${modelName}_TOPOLOGY\n`;
  //   //         const weightSpecsKey = `MODEL_${modelName}_WEIGHTSPECS\n`;
  //   //         const weightDataKey = `MODEL_${modelName}_WEIGHTSDATA\n`;

  //   //         const modelTopology = JSON.parse(parts[parts.indexOf(topologyKey) + 1].trim());
  //   //         const weightSpecs = JSON.parse(parts[parts.indexOf(weightSpecsKey) + 1].trim());

  //   //         const weightDataStart = text.indexOf(weightDataKey) + weightDataKey.length;
  //   //         let weightDataEnd = text.indexOf('MODEL_', weightDataStart + 1);
  //   //         weightDataEnd = weightDataEnd === -1 ? blob.size : weightDataEnd;

  //   //         // Ensure the slice is aligned on a 4-byte boundary
  //   //         const alignedStart = weightDataStart + (4 - (weightDataStart % 4)) % 4;
  //   //         const alignedEnd = weightDataEnd - (weightDataEnd % 4);

  //   //         const weightDataBlob = blob.slice(alignedStart, alignedEnd);
  //   //         const arrayBuffer = await weightDataBlob.arrayBuffer();

  //   //         // Ensure the buffer is aligned to avoid RangeError
  //   //         const alignedBuffer = arrayBuffer.byteLength % 4 === 0 ? arrayBuffer : new ArrayBuffer(Math.ceil(arrayBuffer.byteLength / 4) * 4);
  //   //         new Uint8Array(alignedBuffer).set(new Uint8Array(arrayBuffer));

  //   //         const model = await tf.loadLayersModel(tf.io.fromMemory(
  //   //             modelTopology,
  //   //             weightSpecs,
  //   //             new Uint8Array(alignedBuffer)
  //   //         ));

  //   //         canvasMetadata[modelName].modelBinary = model;
  //   //     }

  //   //     return canvasMetadata;
  //   // }

  //   // async function loadCanvasStructureFromBlob(blob) {
  //   //   const text = await blob.text();
  //   //   const parts = text.split('\n');

  //   //   // Find the index of each section
  //   //   const metadataIndex = parts.indexOf('CANVAS_METADATA');

  //   //   // Extract the metadata
  //   //   const metadataWithoutModels = JSON.parse(parts[metadataIndex + 1]);

  //   //   // Iterate over each model in the metadata to restore them
  //   //   for (const modelName in metadataWithoutModels) {
  //   //     const topologyIndex = parts.indexOf(`MODEL_${modelName}_TOPOLOGY`);
  //   //     const weightSpecsIndex = parts.indexOf(`MODEL_${modelName}_WEIGHTSPECS`);
  //   //     const weightDataIndex = parts.indexOf(`MODEL_${modelName}_WEIGHTSDATA`);

  //   //     // Parse the model components
  //   //     const modelTopology = JSON.parse(parts[topologyIndex + 1]);
  //   //     const weightSpecs = JSON.parse(parts[weightSpecsIndex + 1]);
  //   //     const weightData = new Uint8Array(
  //   //       parts.slice(weightDataIndex + 1, parts.length - 1).join('\n')
  //   //     ).buffer;

  //   //     // Create the model artifacts object
  //   //     const modelArtifacts = {
  //   //       modelTopology,
  //   //       weightSpecs,
  //   //       weightData
  //   //     };

  //   //     // Load the model using TensorFlow.js
  //   //     const model = await tf.loadLayersModel(tf.io.fromMemory(modelArtifacts));

  //   //     // Restore the model into the metadata
  //   //     metadataWithoutModels[modelName].modelBinary = model;
  //   //   }
  //   //   return metadataWithoutModels;
  //   // }



  //   // Usage example
  //   const loadedCanvasStructure = await loadCanvasStructureFromBlob(blob);

  //   set({ canvasModels: loadedCanvasStructure });
  //   return loadedCanvasStructure
  // },


  // loadCanvas3: async (file) => {
  //   async function loadBlobAsText(blob) {
  //     return new Promise((resolve, reject) => {
  //       const reader = new FileReader();
  //       reader.onload = () => resolve(reader.result);
  //       reader.onerror = reject;
  //       reader.readAsText(blob);
  //     });
  //   }

  //   async function loadBlobAsArrayBuffer(blob) {
  //     return new Promise((resolve, reject) => {
  //       const reader = new FileReader();
  //       reader.onload = () => resolve(reader.result);
  //       reader.onerror = reject;
  //       reader.readAsArrayBuffer(blob);
  //     });
  //   }

  //   const fileContent = await loadBlobAsText(file);

  //   // Split the content into parts
  //   const parts = fileContent.split('\n');
  //   const metadataIndex = parts.indexOf('CANVAS_METADATA');
  //   const canvasMetadata = JSON.parse(parts[metadataIndex + 1]);

  //   let currentIndex = metadataIndex + 2; // Start after the metadata
  //   while (currentIndex < parts.length) {
  //     if (parts[currentIndex].startsWith('MODEL_')) {
  //       const modelName = parts[currentIndex].split('_')[1]; // Assuming MODEL_modelName_TOPOLOGY

  //       const modelTopology = JSON.parse(parts[currentIndex + 1]);
  //       const weightSpecs = JSON.parse(parts[currentIndex + 3]);

  //       const weightDataLabel = `MODEL_${modelName}_WEIGHTSDATA`;
  //       const weightDataIndex = parts.indexOf(weightDataLabel, currentIndex + 5);

  //       // Slice the correct portion of the blob for weight data
  //       const weightDataBlobStart = fileContent.indexOf(parts[weightDataIndex + 1], currentIndex + 5);
  //       let weightDataBlobEnd = fileContent.indexOf('MODEL_', weightDataBlobStart + 1);
  //       weightDataBlobEnd = weightDataBlobEnd === -1 ? file.size : weightDataBlobEnd;

  //       const weightDataBlob = file.slice(weightDataBlobStart, weightDataBlobEnd);
  //       const weightDataArrayBuffer = await loadBlobAsArrayBuffer(weightDataBlob);

  //       const modelArtifacts = {
  //         modelTopology: modelTopology,
  //         weightSpecs: weightSpecs,
  //         weightData: new Uint8Array(weightDataArrayBuffer)
  //       };

  //       const model = await tf.loadLayersModel(tf.io.fromMemory(modelArtifacts));

  //       // Assign the loaded model to the correct position in canvasMetadata
  //       canvasMetadata[modelName].modelBinary = model;

  //       currentIndex = weightDataIndex + 2; // Move past this model's data
  //     } else {
  //       currentIndex++;
  //     }
  //   }

  //   // Assuming you have a way to set the canvasModels in your application state:
  //   set({ canvasModels: canvasMetadata });
  //   return canvasMetadata;
  // },

  // Usage example:
  // const loadedCanvasStructure = await loadCanvas3(uploadedFile);




  // updateTrainingData: (data, model) => {
  //     var trainingData = { ...get().trainingData };
  //     trainingData[model] = data
  //     set({ trainingData })
  // },
  // removeModel: (name) => {
  //     const newmodels = get().models
  //     delete newmodels[name]
  //     set({ models: newmodels })
  // },
  // saveModel: (model) => {
  //     const newmodels = get().models
  //     newmodels[model.name] = model
  //     set({ models: newmodels })
  //     // get().getSize()
  // },
  // downloadModel: (modelId) => {
  //   const handleSaveJson = (jsonObject, filename) => {

  //     const jsonString = JSON.stringify(jsonObject, null, 2);
  //     const blob = new Blob([jsonString], { type: "application/json" });
  //     const url = URL.createObjectURL(blob);

  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.download = filename;
  //     document.body.appendChild(link);

  //     link.click();
  //     document.body.removeChild(link);
  //     URL.revokeObjectURL(url);
  //   };
  //   const clone = canvasModels[modelId];
  //   clone.modelBinary = currentModel.name
  //   handleSaveJson(clone, `${currentModel.name}.tf.canv`)
  //   const d = async () => await currentModel.modelBinary.save(`downloads://${currentModel.name}.tf`)
  //   d()
  // },
  updateModel: (model, props) => {
    if (model.name !== "") {
      var canvasModels = get().canvasModels;
      canvasModels[`${model.id}`] = { ...model, ...props };
      set({ canvasModels });
    } else {
      console.log("failed to save, model with empty name")
      // alert("failed to save, model with empty name");
    }
  },
  removeModel: (modelId) => {
    var canvasModels = get().canvasModels;
    delete canvasModels[modelId];
    set({ canvasModels });
  },
}));

export const currentModelStore = create((set, get) => ({
  ...MLMODEL_STRUCTURE,
  // modelSize: 1,
  // inputs: [],
  // outputs:[],
  // tProgress: 0,

  modelHash: () => {
    let modelHash = ""
  },
  setEpochs: (epochs) => set({ epochs: epochs }),
  setDrops: (drops) => set({ trainingData: { ...(get().trainingData), drops: drops } }),
  setEmbeddings: (embeddings) => set({ trainingData: { ...(get().trainingData), embeddings: embeddings } }),
  setLayers: (value) => set({ layers: value }),
  setCompileOptions: (value) => set({ compile: value }),
  setTrainingDataEmbeddings: (embed) =>
    set({ algorithm: { ...get().algorithm, embeddings: embed } }),
  saveModelBinary: (modelBin) => set({ modelBinary: modelBin }),
  // saveModelBinary: (modelBin) => set({ modelBinary: new TextEncoder().encode(JSON.stringify(modelBin)) }),
  // setMlConfig: (value) => set({ mlConfig: value }),
  clearModel: (newprops) =>
    set({ ...MLMODEL_STRUCTURE, inputs: {}, outputs: {}, ...newprops, created: new Date().toLocaleString() }),
  // newModel:()=>{get().clearModel()},
  addFeature: (space, feature) => {
    set({ [space]: { ...get()[space], [feature.name]: feature } });
    get().getCoordinates();
    get().saveModelBinary(null)
    // get().getSize()
  },
  updateLastTrainedOn: (datetime) => set({ lastTrainedOn: datetime }),
  setCompileOptions: () => set({ compile: {} }),
  setModelInterval: (value) => set({ modelInterval: value }),
  setAlgorithm: (value) =>
    set({ algorithm: { ...get().algorithm, algo: value } }),
  setAlgorithmConfig: (value) =>
    set({ algorithm: { ...get().algorithm, config: value } }),
  removeFeature: (space, feature) => {
    const newset = get()[space];
    delete newset[feature.name];
    set({ [space]: newset });
    get().getCoordinates();
    get().saveModelBinary(null)
    // get().getSize()
  },
  updateAccuracy: (value) =>
    set({ trainingData: { ...get().trainingData, lastAccuracy: value } }),
  setDataStart: (value) =>
    set({ trainingData: { ...get().trainingData, dataStart: value } }),
  setDataEnd: (value) =>
    set({ trainingData: { ...get().trainingData, dataEnd: value } }),
  getCoordinates: (recalculate = true) => {
    if (recalculate) {
      const outps = get().outputs;

      let latitude = 0
      let longitude = 0
      let count = 0

      // const outputPos = Object.keys(outps).reduce(
      //   (newObj, key, i) => {
      //     newObj["latitude"] = newObj["latitude"] + outps[key].latitude;
      //     newObj["longitude"] = newObj["longitude"] + outps[key].longitude;
      //     newObj["count"] = newObj["count"] + 1;
      //     return newObj;
      //   },
      //   { latitude: 0, longitude: 0, count: 0 }
      // );

      // outputPos["latitude"] = outputPos["latitude"] / outputPos["count"];
      // outputPos["longitude"] = outputPos["longitude"] / outputPos["count"];
      // // console.log(outputPos)

      // const inps = get().inputs;
      // const inputPos = Object.keys(inps).reduce(
      //   (newObj, key, i) => {
      //     newObj["latitude"] = newObj["latitude"] + inps[key].latitude;
      //     newObj["longitude"] = newObj["longitude"] + inps[key].longitude;
      //     newObj["count"] = newObj["count"] + 1;
      //     return newObj;
      //   },
      //   { latitude: 0, longitude: 0, count: 0 }
      // );
      // inputPos["latitude"] = inputPos["latitude"] / inputPos["count"];
      // inputPos["longitude"] = inputPos["longitude"] / inputPos["count"];
      // // console.log(inputPos)

      // const res = [
      //   (outputPos["longitude"] + inputPos["longitude"]) / 2,
      //   (outputPos["latitude"] + inputPos["latitude"]) / 2,
      // ];

      Object.values(get().inputs).forEach((v) => {
        latitude += v["latitude"]
        longitude += v["longitude"]
        count++
      })

      Object.values(get().outputs).forEach((v) => {
        latitude += v["latitude"]
        longitude += v["longitude"]
        count++
      })

      const res = count === 0 ? MLMODEL_STRUCTURE.coordinates : [
        longitude / count,
        latitude / count,
      ];

      set({ coordinates: res });

      return res;
    }
    else {
      return get().coordinates;
    }
  },
  setCurrentModel: (model) => {
    set({ ...model });
    return get();
  },
}));

export const globalStore = create((set, get) => ({
  ...ACCOUNT_GLOBALS_STRUCTURE,
  zoomScope: 0,
  progress: -1,
  setZoomScope: (value) => set({ zoomScope: value }),
  setProgress: (value) => set({ progress: value }),
  setPredictionResults: (result) => set({ predictions: result }),
  setMerlinConvo: (state) => set({ merlinConvo: state }),
  addGeojsonImports: (name, data) =>
    set({ geojsonImports: { ...get().geojsonImports, [name]: data } }),
  addCsvImports: (name, data) =>
    set({ csvImports: { ...get().csvImports, [name]: data } }),
  setUrlParams: (value) => set({ urlParams: value }),
  setMerlinWard: (value) => set({ merlinWard: value }),
  updateLayers: (name, layer) => {
    if (layer) {
      const layers = get().layers;
      layers[name] = layer;
      set({ layers: layers });
    } else {
      const nestlayers = get().layers;
      delete nestlayers[name];
      set({ layers: nestlayers });
    }
  },
  updateLayersVisibility: (name, bool) => {
    const layers = get().layersVisibility;
    layers[name] = bool ? bool : !layers[name];
    set({ layersVisibility: layers });
  },
  setShowMeta: (value) => set({ showMeta: value ? value : !get().showMeta }),
  setMerlinAssist: (value) =>
    set({ merlinAssist: value ? value : !get().merlinAssist }),
  setPage: (value = null) => {
    set((state) => ({ page: value ? value : "home" }));
  },

  setLight: (value) => set({ light: value }),

  setAssets: (assets) => set({ assets: assets }),

  setCollections: (collections) => set({ collections: collections }),
  setCollectionVisibility: (coll, value) => {
    let colley = get().collections[coll];
    // console.log('before visibility', colley)
    // colley = { ...colley, visible: value? value:!colley.visible }
    colley.visible = value ? value : !colley.visible;
    // console.log('after visibility', colley)
    const newCllections = { ...get().collections, [coll]: colley };
    // console.log('new visibility', newCllections)
    set({ ...newCllections });
  },

  setInit: (value) => set({ init: value }),
  setAppLaunchable: (value) => set({ appLaunchable: value }),
  setWindowDimensions: (value) => set({ windowDimensions: value }),
  setWindowOpen: (value) => set({ windowOpen: value }),

  // setTrainingData: (value, model) => set({ trainingData }),
  // updateState: (state) => set({ state })
}));

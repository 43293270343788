
// import './App.css';

import { useState, useRef, useEffect } from 'react';
// import { useEffect, useEffect } from 'react';
import { useSpring, useSpringValue, animated, to } from '@react-spring/web';
import { Card, Box, Avatar, Stack, Typography, TextField, Divider, Chip, Switch, Button, IconButton, colors } from '@mui/material';
import { buttonStyle, headStyle, hrStyle } from './css/styling';
import SquareIcon from '@mui/icons-material/Square';
import * as LNSNport from './components/apis/LNSN.json';
import Home from './components/pages/Home';
import Platform from './components/pages/Platform';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
// import * as API2 from '../../data/API2';
import Tooltip from '@mui/material/Tooltip';
import { backgroundStyle } from './css/styling';
import { verticalCenter } from './css/styling';
import { lightColor } from './css/styling';
import Diversity2Icon from '@mui/icons-material/Diversity2';

import * as APIS from './components/apis/APIS';
import * as API2 from './components/states';
import RTEMcoords from './components/apis/RTEM_Coords.json';
// import AIRVIROdata from './data/Airviro_Coords.json';
import { render } from '@testing-library/react';
// import {SplashScreen} from './SplashScreen';
import SplashScreen from './SplashScreen';
import { Opacity } from '@mui/icons-material';

import { Transitioner } from './components/dElements/Animations';
import FileImport from './components/FileImport';
import { isObjectEmpty } from '@luma.gl/core';


const ENABLE_SPLASHSCREEN = true


const consoleWarn = console.warn;
const SUPPRESSED_WARNINGS = ['There is a chart instance already initialized'];

console.warn = function filterWarnings(msg, ...args) {
    if (!SUPPRESSED_WARNINGS.some((entry) => msg.includes(entry))) {
        consoleWarn(msg, ...args);
    }
};


function App() {
  const handle = useFullScreenHandle();
  // const [page, setPage] = useState('home')
  const setPage = API2.globalStore().setPage;
  const page = API2.globalStore().page;
  const [splashScreen, setSplashScreen] = useState(true)



  const [splashSprings, splashSapi] = useSpring(() => ({
    // from: { opacity: 1 },
    to: { opacity: splashScreen ? 1 : 0 }
  }), [splashScreen])

  const [homeSprings, homeSapi] = useSpring(() => ({
    // from: { y: page !== 'home' ? 0 : 700 },
    // to: { y: page === 'home' ? 0 : 700 },
    // from: { opacity: page !== 'home' ? 1 : 0 },
    to: { opacity: page === 'home' ? 1 : 0 },
    // delay: 5000
    config: { duration: 1000 }
  }), [page])

  const [platformSprings, platformSapi] = useSpring(() => ({
    // from: { y: page !== 'home' ? 0 : 700 },
    // to: { y: page === 'home' ? 0 : 700 },
    // from: { opacity: page !== 'platform' ? 1 : 0 },
    to: { opacity: page === 'platform' ? 1 : 0 },
    // delay: 5000
  }), [page])



  const setWindowDimensions = API2.globalStore().setWindowDimensions;
  const windowDimensions = API2.globalStore().windowDimensions;
  const init = API2.globalStore().init;
  const { urlParams, setUrlParams, setWindowOpen, windowOpen } = API2.globalStore();


  useEffect(() => {
    const urlSearchString = window.location.search;
    const thisParams = {}
    const params = new URLSearchParams(urlSearchString)
    params.forEach((v, k) => thisParams[k] = params.get(k));
    setUrlParams(thisParams)

    if (thisParams["page"]) {
      switch (thisParams["page"]) {
        case "platform":
          setPage('platform')
          break;
        case "collections":
        case "archive":
        case "archives":
        case "datasets":
          setWindowOpen("datasets")
          setPage('platform')
          break;
        case "services":
        case "apis":
        case "api":
          setWindowOpen("apis")
          setPage('platform')
          break;
        case "case studies":
        case "use case":
        case "cases":
          setWindowOpen('cases')
          setPage('platform')
          break;
        case "contribute":
          setWindowOpen('contribute')
          setPage('platform')
          break;
        case "roadmap":
          setWindowOpen('roadmap')
          setPage('platform')
          break;
      }
    }
  }, []);

  useEffect(() => {
    function handleResize() {
      const { innerWidth: width, innerHeight: height } = window;
      setWindowDimensions({ width, height });
    }

    handleResize();

    // if (splashScreen) {
    //   setTimeout(() => setSplashScreen(false), 6000)
    // }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  // const setCtxMenuPos = API2.ctxMenuStore().setCtxMenuPos;


  return <FullScreen
    style={{ overflowY: 'hidden', position: 'fixed', height: '100vh', width: '100vw' }}
    handle={handle}>

    <div style={{ background: '#383a3d', position: 'fixed', height: '100vh', width: '100vw', zIndex: -10 }} />



    {/* <animated.div style={{ ...splashSprings }}> */}

    <Transitioner open={!init && ENABLE_SPLASHSCREEN && !urlParams?.nosplash}>
      <SplashScreen />
    </Transitioner>
    
    {/* </animated.div> */}
    {/* <FileImport></FileImport> */}
    {/* <animated.div style={{ ...homeSprings, overflowY: 'hidden', zIndex: 10, position: 'fixed' }}> */}

    <Transitioner open={init}>
      {page === 'home' && <Home setPage={(pg) => {
        setPage(pg)
        // homeSapi.start({ reverse: true });
        // setTimeout(() => { setPage(pg) }, 200)
      }} />}
    </Transitioner>

    {/* </animated.div> */}

    {/* <animated.div style={{...platformSprings}}> */}
    <Platform
      setPage={(value) => {
        // homeSapi.start({ reverse: true })
        setPage(value)
      }}
      page={page}
      fullscreenToggle={() => {
        if (handle.active)
          handle.exit()
        else
          handle.enter()
      }}
    />
    {/* </animated.div> */}

    {/* {page === 'legacy' ? <App2 /> : null} */}

    {/* {page === 'platform' ? <App2
      closefn={() => {
        homeSapi.start({ reverse: true })
        setPage('home')
      }}

      fullscreenToggle={() => {
        if (handle.active)
          handle.exit()
        else
          handle.enter()
      }}
    /> : null} */}

  </FullScreen>
}

export default App;